import React, { useState } from 'react';
//import {BsCaretDown} from 'react-icons/bs'
import arrowdown from './assets/arrowdown.svg';
import './faq.css';
export function Faq(props) {
  const obj = props;

  const { id, title, info } = obj;
  console.log('yes');
  const [showInfo, setShowInfo] = useState(false);
  function showInfoBtn() {
    setShowInfo(!showInfo);
  }

  return (
    <section key={id} className="faq">
      <div className="title-button-container flex justify-between gap-5 items-center w-full cursor-pointer"
        onClick={showInfoBtn}
      >
        <h2 className="text-lg md:text-xl pb-3">{title}</h2>
        <img
          className={`${showInfo ? 'rotate-180' : ''} h-6 w-6`}
          src={arrowdown}
        />
      </div>
      {showInfo && <p className="text-[18px] py-[15px] leading-7">{info}</p>}
    </section>
  );
}
