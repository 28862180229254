import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FooterTwo from '../../components/FooterTwo';
import Navbar from '../../components/Navbar/Navbar';
import Back from '../../assets/back-arrow.png';
import axios from 'axios';
const FEEDBACK_URL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/feedback/`;

const AuthIssue = () => {
  const [isHelpful, setIsHelpful] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isHelpful === null || feedback === '') {
      alert('All fields are required');
      setIsSubmitting(false);
      return;
    }
    
    setIsSubmitting(true);
    const data = {
      is_helpfull: isHelpful,
      feedback,
    };

    try {
      const response = await axios.post(FEEDBACK_URL, data);
      console.log(response);
      alert(response.data);
      setIsSubmitting(false);
      setIsHelpful(null);
      setFeedback('');
    } catch (err) {
      console.log(err);
      alert(err.message);
      setIsHelpful(null);
      setFeedback('');
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section style={{ minHeight: '75vh' }} className="help-center-subpage">
        <div className=" container mx-auto font-avenir px-5 py-24">
          <div className="my-3">
            <Link
              to="/help-center"
              className="flex items-center gap-3 no-underline font-semibold"
            >
              <span>
                <img src={Back} alt="" width="11" />
              </span>
              <span className="text-[#464646] text-lg">Help Center</span>
            </Link>
          </div>

          <div className="mt-12 w-full md:w-4/5 lg:w-3/5">
            <h2 className="text-4xl md:text-5xl text-[#464646] mb-5">
              Unable to Login/SignUp
            </h2>

            <div className="text-lg text-[#464646] mb-12">
              <p className="mb-3">
                Failing login can have several causes. Maybe you have a typo,
                changed your password recently or another problem is causing the
                login to fail.
              </p>

              <div className="mb-3">
                <p className="font-bold">Typing errors</p>
                <p>You have a typo or some keys of your keyboard are broken.</p>
              </div>

              <div className="mb-3">
                <p className="font-bold">Typo?</p>
                <ul className="px-3">
                  {[
                    'Enter your password in a field where it is displayed in clear text. If your password is correct,',
                    'Copy it into the Password field.',
                    'Correct any typing errors.',
                    'Check your login details.',
                  ].map((_, i) => (
                    <li key={i} className="">
                      {_}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-3">
                <p className="font-bold">Caps Lock is active</p>
                <p>
                  The caps lock (the key above the shift key that causes all
                  typed letters to be in the CAPITAL case) is active. Your
                  password is case-sensitive.
                </p>
                <ul className="px-3">
                  {[
                    'Deactivate Caps Lock.',
                    'Correct any typing errors.',
                    'Check your login details.',
                  ].map((_, i) => (
                    <li key={i} className="">
                      {_}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-3">
                <p className="font-bold">
                  The &quot;Password&quot; field is prefilled with the old
                  password
                </p>
                <p>
                  You changed your password recently. The incorrect password is
                  still saved in your browser.
                </p>
                <ul className="px-3">
                  {[
                    'Delete the saved password in your Browser settings',
                    'Follow the instructions on the site Delete Saved Passwords',
                    'Incorrect email address and/or password',
                    'You are entering an incorrect email address and/or password.',
                  ].map((_, i) => (
                    <li key={i} className="">
                      {_}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="mb-3">
                <p className="font-bold">Try other login data</p>
                <p>Try logging in with another email and/or password.</p>
              </div>

              <div>
                <p className="">
                  Still having issues? Contact us @{' '}
                  <a
                    href="mailto:Contact.loveme@gmail.com"
                    className="text-main-1"
                  >
                    Contact.loveme@gmail.com
                  </a>
                </p>
              </div>
            </div>

            <div>
              <form onSubmit={handleSubmit}>
                <div className="mb-8">
                  <label
                    htmlFor=""
                    className="text-lg text-[#464646] font-bold"
                  >
                    Was this article helpful?
                  </label>
                  <div>
                    <input
                      type="radio"
                      className="mr-2"
                      id="yes"
                      name="helpful"
                      value="yes"
                      onClick={(e) => setIsHelpful(e.target.value)}
                    />
                    <label className="text-base text-[#464646]" htmlFor="yes">
                      Yes
                    </label>
                    <br />
                    <input
                      type="radio"
                      className="mr-2"
                      id="no"
                      name="helpful"
                      value="no"
                      onClick={(e) => setIsHelpful(e.target.value)}
                    />
                    <label className="text-base text-[#464646]" htmlFor="no">
                      No
                    </label>
                    <br />
                  </div>
                </div>

                <div className="mb-5">
                  <label
                    htmlFor=""
                    className="text-lg text-[#464646] font-bold"
                  >
                    Anything else we can do to improve our site?
                  </label>
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="7"
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    className="block w-full p-3 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                  ></textarea>
                </div>

                <div>
                  <button
                    className="bg-main-1 py-3 px-4 border border-main-1 text-[#fff] rounded-xl font-medium text-sm"
                    style={{ position: 'relative' }}
                  >
                    Submit Feedback
                    {isSubmitting ? (
                      <div className="loader-box">
                        <span className="loader mx-auto"></span>
                      </div>
                    ) : null}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <FooterTwo />
    </div>
  );
};

export default AuthIssue;
