import React from 'react';
import {Link} from 'react-router-dom'
import '../styles/Errorpage.css'

function ErrorPage() {
  return (
   <div>
    <div className="pgnot-container">
      <img className='pgnot-img' src='pagenotfound.svg'/>
      <div className='pgnot-text'>
        <p className='pgnot-leading'>Page not found</p>
        <Link to='/'><button className='pgnot-button'>Go back home</button></Link>
      </div>
    </div>
   </div>
  );
}

export default ErrorPage;
