import { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { PersonalInfo } from '../components/Profile/PersonalInfo';
import { SecurityInfo } from '../components/Profile/Security';
import { LinkedAccounts } from '../components/Profile/LinkedAccounts';
import Navbar from '../components/Navbar/Navbar';
import useDropoff from '../hooks/useDropoff';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../styles/Profile.css';
// import { useAuthContext } from '../hooks/useAuthContext';
import axios from 'axios';
import useChatContext from '../hooks/useChatContext';


export default function ProfilePage() {
  // const { user } = useAuthContext();
  const context =useChatContext()
  const {dataCollectorHandler}=context
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('');
  // const [modal, setModal] = useState('');
  // const [imageSrc, setImageSrc] = useState('');
  //  const [fileImg, setFileImg] = useState(null);
  const [modal, setModal] = useState(false);
  const [image, setImage] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [prevImage, setPrevImage] = useState(null);
  //  const [saveImage] = useState(false)
  const imageRef = useRef();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
 
  const token = localStorage.getItem('token');
  // const successs = () => {
  //   toast.success('Saved!', {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //     });
  // }
  const errors =()=> {
    toast.error('Error Occured', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  // console.log(fileImg)
 
  function getUserImage() {
    axios
      .get(`user/me/get-user`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        setImageUrl(res.data.image);
        // setFirstName(res.data.firstname);
        // setLastName(res.data.lastname);
        // setEmail(res.data.email);
      })
      .catch((err) => {
        errors()
        console.log(err);
      });
  }
  useEffect(() => {
    getUserImage();
  }, []);

  // useEffect(()=>{
  //   axios.get('/user/me/get-user', {
  //     headers:{
  //       Authorization: `Bearer ${localStorage.getItem('token')}`
  //     }
  //   }).then(res=>{
  //     console.log(res.data.image)
  //     if(res.data.image !== null){
  //       setImageSrc(res.data.image)
  //     }
  //   })
  // },[])
  function handleModal(e) {
    e.preventDefault()
    setLoading(false);
    setSuccess(false);
    setError(false);
    setPrevImage(null);
    setModal(prev => !prev);
  }

  const ResetProfileData = () => {
    // console.log('from reset')
    axios
      .get(`user/me/get-user`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        dataCollectorHandler(res.data.firstname,true)
        setFirstName(res.data.firstname);
        setLastName(res.data.lastname);
        setEmail(res.data.email);
        setPlan(res.data.plan_type);
      })
      .catch((err) => {
        errors()
        console.log(err);
      });
    //  localStorage.getItem('user')
    //  console.log(JSON.parse(localStorage.getItem('user')))
    //  const userInfo = JSON.parse(localStorage.getItem('user'))
    //  setFirstName(userInfo.first_name);
    //  setLastName(userInfo.last_name);
    //  setEmail(userInfo.email);
  };
  useEffect(() => {
    ResetProfileData();
  }, []);

  function handleImage(e) {
    if (e.target.files && e.target.files[0]) {
      setPrevImage(URL.createObjectURL(e.target.files[0]));
    }
  //  console.log(e.target.files[0], '$$$$');
    setImage(e.target.files[0]);
    // console.log(fileImg)
    // e.preventDefault();
    // imageRef.current.src = URL.createObjectURL(e.target.files[0])
    // imageRef.current.width= 170;
    // imageRef.current.height= 170;
    // setSaveImage(true)
    // setImage(e.target.files[0])
  }
  // function cancelPhoto() {
  //   setModal(false)
  // }
  // function RemovePhoto(e){
  //     e.preventDefault()
  //     setPrevImage(null)
  //     setImageUrl(null)
  //     setImage(null)
  // }

  function handleSubmitImage(e) {
    e.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append('image', image);
    axios
      .post('/user/me/upload-image', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Credentials': true,
        },
      })
      .then((res) => {
      //  console.log(res);
        setImageUrl(res.data.profile_image);
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
       // console.log(err);
        setError(true);
      });
    getUserImage();
    // axios({
    //   url:'/user/me/upload-image',
    //   method: 'POST',
    //   headers:{
    //     Authorization: `Bearer ${token}`,
    //     // 'Access-Control-Allow-Credentials': true,
    //     'Access-Control-Allow-Origin': '*',
    //     // 'Content-type': 'multipart/form-data',
    //   }
    // }).then(res => {
    //   console.log(res);
    // })
  }
  //  e.preventDefault();
  // //  console.log(image)
  //  let fd = new FormData();
  //   // fd.append('image', image)
  //   console.log(fd)
  //  axios.post('/user/me/upload-image',fd,{
  //   headers:{
  //     'Authorization': `Bearer ${token}`,
  //     'Access-Control-Allow-Credentials': true,
  //     'Access-Control-Allow-Origin': '*',
  //     'Content-type': 'multipart/form-data',

  //   }
  //  }).then((res)=>{
  //   console.log(res)
  //   if(res.status === 200){
  //     axios.get('/user/me/get-user', {
  //       headers:{
  //         Authorization: `Bearer ${localStorage.getItem('token')}`
  //       }
  //     }).then(res=>{
  //       if(res.data.image !== null){
  //         setImageSrc(res.data.image)
  //         setSaveImage(false)
  //       }
  //     })
  //   }
  //  }).catch(err=>console.log(err))
 
  function handleModedrop(){
    setModal(false)
  }
  const ref = useDropoff(handleModedrop);
  // const ref2 = useDropoff(handleModedrop);
  console.log(ref)
  return (
    <>
      <Navbar />
      <div className="w-[min(100%,780px)] mx-auto py-20">
        <div className="flex flex-col items-center">
          <div className="relative w-[170px] h-[170px]">
            <img
              src={imageUrl === null ? './profile image.jpg': `${process.env.REACT_APP_API_BASE_URL}${imageUrl}`}
              ref={imageRef}
              alt="dp"
              className="w-full object-cover rounded-full border-2 border-solid border-gray-800 -z-50  cursor-pointer  h-full  block"
            />
            <div>
            <button className="profile-photo_camera" onClick={handleModal}>
              <img src="/Camera.svg" />
            </button>
           
            {modal && (
              <div className="profile-photo-modal" onClick={()=>{
                setModal(false)
              }}>
                <form className="profile-photo-form" onClick={(e)=>{
                  e.stopPropagation()
                }}>
                  
                  {/* <label htsmlFor='change-image'> <img src="/Camera.svg"  alt="change profile image"/></label> */}
                 <div className="profile-form-container">
                  <label htmlFor="profile-photo" className="photo-form-label">
                    Choose File
                    <input
                      type="file"
                      id="profile-photo"
                      className="photo-form-input"
                      onChange={handleImage}
                    />
                  </label>
                  <img src={prevImage || `${process.env.REACT_APP_API_BASE_URL}${imageUrl ===null? "/static/d73c80cab9ec98c5.jpg" : imageUrl}`} className="preview-photo"/>
                  </div>
                  <p className='mt-8 text-center text-[#344054]'>JPG, JPEG or PNG. Max size of 800kb</p>
                  
                  <button
                    className="profile-photo-save"
                    onClick={handleSubmitImage}
                  >
                    Upload Image
                  </button>
                  
                  
                  <div className="profile-photo-state">
                    {loading && <p className="profile-load">Loading...</p>}
                    {success && <p className="profile-success">Success!</p>}
                    {error && <p className="profile-err">Error</p>}
                  </div>
                  {/* <input className={` ${saveImage? 'block': 'hidden'} bg-main-1 border-none cursor-pointer main text-white rounded-md absolute bottom-[-30px] -left-4 py-1 px-2`} type={'submit'} onClick={(e)=> handleSubmitImage(e)}/> */}
                </form>
              </div>
            )} 
            </div>
          </div>

          <div className="text-center mt-6">
            <h1 className="text-2xl font-black">{`${firstName} ${lastName}`}</h1>
            <p className="">{email}</p>

          </div>
        </div>

        <div className="flex flex-col gap-14 mt-20 px-4 md:px-0">
          <PersonalInfo resetData={ResetProfileData} />
          <SecurityInfo />
          <LinkedAccounts />
        </div>
        <div className="mt-[58px] mb-[100px] flex justify-between items-center px-4 md:px-0 flex-wrap gap-[10px]">
          <p className="md:text-lg font-semibold text-[#464646] font-avenir">
            Your subscription:{' '}
            <span className="text-[#D2120F]">
              {plan ? plan : 'No subscription'}
            </span>
          </p>
          <Link to="/pricing">
            <button className="border-[1px] border-[transparent] outline-0 py-[12px] text-white transition-all px-[20px] duration-[0.1s] hover:text-[#d2120f] md:text-base hover:border-[1px] hover:border-[#d2120f] bg-[#d2120f] hover:bg-white rounded-[8px]">
              Change Plan
            </button>
          </Link>
        </div>
      </div>
      <ToastContainer/>
    </>
  );
}
