import React, { useState } from 'react';
import axios from 'axios';
import style from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";

const axiosSchedule = (str) => {
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('letterId')
  const options = {
    url: `${process.env.REACT_APP_API_BASE_URL}/schedule-letter/${id}`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*'
    },
    data: str,
  };
  axios(options)
    .then((res) => {
      const data = res.data
      return data
    })
    .catch((err) => {
      console.error(err);
    });
}

const setReceiverEmail = (str) => {
  const token = localStorage.getItem('token');
  const id = localStorage.getItem('letterId')
  const options = {
    url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/receiver/${id}/`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Access-Control-Allow-Origin': '*'
    },
    data: {
      "email": str
    },
  };
  axios(options)
    .then((res) => {
      const data = res.data
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
}

const Modal = (props) => {
  const id = localStorage.getItem('letterId');
  const [state, setState] = useState({
    date: '',
    email: '',
    scheduled: false
  });

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  };

  const obj = props;
  const { setIsOpen } = obj

  const handleClick = () => {
    if (state.date.length > 1) {
      axiosSchedule(state.date)
      setReceiverEmail(state.email)
      setState({
        ...state,
        scheduled: true
      })
      setTimeout(() => {
        setIsOpen(false);
      }, 1500)
    }
  };

  return (
    <>
      <div className={style.darkBG}>
        <div className={style.centered}>
          <div className={style.modal}>
            <div className={style.header}>
              <h2>Schedule</h2>
              <RiCloseLine style={{ width: '30px', height: '30px' }} className={style.closeBtn} onClick={() => setIsOpen(false)} />
            </div>
            <p>Please provide a preferred date and time for your message to be delivered </p>
            <form>
              {
                state.scheduled ? <div className={style.ScheduleContainer}>
                <p className={style.ScheduledText}>Letter scheduled!</p>
                </div> :

                  <>
                    <label htmlFor='date'>Date</label>
                    <input type="datetime-local" name="date" id="name" onChange={handleChange} placeholder='Select a date and time' required />
                    <label htmlFor='email'>Email</label>
                    <input type='email' name="email" id='email' onChange={handleChange} placeholder="Enter receiver's email" required />
                    <input type="button" value="Schedule" id={id} onClick={handleClick} />
                  </>
              }
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Modal;