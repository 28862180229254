import React, { useEffect } from 'react';
// import { BsArrowRight } from 'react-icons/bs';
import Navbar from '../../components/Navbar/Navbar';
import FooterTwo from '../../components/FooterTwo';
import { FormLanding } from './FormLanding';
import '../../styles/LandingPage.css';
// import ChatBotPopup from '../../components/ChatBotPopup/ChatBotPopup';
import { useModal } from '../../hooks/useModal';
import { SubscribeButton } from '../../components/subscribebtn';
import SuccessPopUp from '../../components/successpopup';
import ErrorPopUp from '../../components/ErrorPopUp';

function LandingPage() {
  // chat bot
  const {hideHandler, showHandler } = useModal(false);
  const [position, changePosition] = React.useState(0);
  const [constPosition, changeConstPosition] = React.useState(0);

  console.log(position, constPosition);

  useEffect(() => {
    function handleScroll() {
      if (position > 300) {
        changeConstPosition(300);
      }
      changePosition(window.pageYOffset);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [position]);

  useEffect(() => {
    const user = window.localStorage.getItem('user');
    const parsedData = JSON.parse(user);
    if (parsedData?.last_name && constPosition === 300) {
      showHandler();
    } else {
      hideHandler();
    }
  }, [constPosition]);

  // subscribe popup
  const [email, setEmail] = React.useState('');
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState('');
  const [errrormsg, setErrorMsg] = React.useState('');

  // console.log(error, success, errrormsg, successMsg);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (error) {
        setError(false);
        setErrorMsg('');
      }
      if (success) {
        setSuccess(false);
        setSuccessMsg('');
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [error, success]);

  return (
    <div className="relative wrapper">
      {/* {userStatus && <ChatBotPopup onHide={hideHandler} />} */}
      <Navbar />
      <SuccessPopUp success={success} successMsg={successMsg} />
      <ErrorPopUp error={error} message={errrormsg} />
      <section className="section-one flex flex-col md:flex-row p-4 md:p-8 md:text-start md:mx-auto  md:justify-between">
        <div className="hero-content md:max-w-lg mx-auto md:mx-0 p-6 flex flex-col text-left items-center md:gap-5 md:mt-14 md:pt-40">
          <h1 className="text-3xl font-mt-4 md:mt-0  text-[#B86B00] md:text-5xl">
            Everyone deserves to be happy and feel loved.
          </h1>
          <p className="leading-5  text-lg md:text-xl ">
            Make your loved ones feel special with our AI generated letters.
          </p>
          <span className="heart x1"></span>
          <span className="heart x2"></span>
          <span className="heart x3"></span>
          <span className="heart x4"></span>
          <span className="heart x5"></span>
          <span className="altheart x6"></span>
        </div>
        <div className="form_div w-4/5 md:pt-20 mx-auto md:w-3/5 md:mr-2 md:ml-8">
          <FormLanding />
          <span className="heart x1"></span>
          <span className="heart x2"></span>
          <span className="heart x3"></span>
          <span className="heart x4"></span>
          <span className="heart x5"></span>
          <span className="altheart x6"></span>
        </div>
      </section>

      <img
        src="/dottedlove.svg"
        alt=""
        className="dotted_love hidden md:block"
      />

      <section className="section-two flex flex-col justify-center items-center text-center p-10 gap-6 md:gap-10 md:mx-auto">
        <h1 className="font-bold text-xl leading-8 md:text-3xl">
          Generate a love note in seconds
        </h1>
        <p className="para1 text-base md:text-lg w-4/6 md:w-8/12">
          Experience the full power of an AI love note generator that delivers
          <span className="text-main-1"> premium</span> and {''}
          <span className="text-main-1">romantic</span> results in seconds.
        </p>
        <div>
          <p className=" text-3xl md:text-4xl text-main-1 font-semibold mb-2 whitespace-nowrap">
            2,000,000 +
          </p>
          <span className="text-sm md:text-base whitespace-nowrap">
            People choose loveMe
          </span>
        </div>
      </section>

      <section className=" section-three flex flex-col justify-center items-center text-center p-12 gap-4 md:gap-10 md:p-20 md:mx-auto">
        <h1 className="font-bold text-[1.4rem] md:leading-5 md:text-3xl md:mt-4 whitespace-nowrap">
          Yes! It’s that easy
        </h1>
        <p className="text-[0.875rem] md:leading-8 md:text-lg w-11/12  md:w-8/12">
          We take the stress out of your love life using our proprietary AI
          technology, which takes important personal messages and keywords to
          generate more personal letters that convey your desired emotions.
        </p>
      </section>

      <section className="section-four">
        <div className="how_to flex flex-col justify-center items-center gap-4 text-center p-8 md:py-10 md:mx-auto md:mt-16">
          <h2 className="text-[1.4rem] md:leading-7 md:text-3xl whitespace-nowrap">
            How it works
          </h2>
          <p className="text-center md:w-full leading-5 text-base lg:text-lg">
            Here is how to use LoveMe to generate love letters
          </p>
        </div>

        <div className="flex flex-col md:flex-row gap-8 md:mb-20">
          <div className="how_itworks bg-[#fff1f0] p-5 rounded-lg md:w-2/3 text-center">
            <p className="text-xl font-extralight">Step 1</p>
            <p className="text-xl font-bold">Take A Short Quiz</p>
            <img src="/how-itworks1.svg" alt="" />
            <p className="text-sm sm:text-base font-normal">
              Answer a few questions about your special someone and your
              relationship. We will generate dozens of messages based on your
              input.
            </p>
          </div>
          <div className="how_itworks bg-[#fff1f0] p-5 rounded-lg md:w-2/3 text-center">
            <p className="text-xl font-thin">Step 2</p>
            <p className="text-xl font-bold">Generate Letter</p>
            <img src="/how-itworks2.svg" alt="" />
            <p className="text-sm sm:text-base font-normal">
              Using our powerful AI, generate your letter and say those words
              you feel and want to be heard.
            </p>
          </div>
          <div className="how_itworks bg-[#fff1f0] p-5 rounded-lg md:w-2/3 text-center">
            <p className="text-xl font-light">Step 3</p>
            <p className="text-xl font-bold">Share or Download</p>
            <img src="/how-itworks3.svg" alt="" />
            <p className="text-sm sm:text-base font-normal">
              Feel free to share directly, download or go classic by writing it
              out yourself, making it even more special for that special
              someone.
            </p>
          </div>
        </div>
        <span className="heart x1"></span>
        <span className="heart x2"></span>
        <span className="heart x3"></span>
        <span className="heart x4"></span>
        <span className="heart x5"></span>
        <span className="altheart x6"></span>
      </section>

      <section className="section-five">
        <h1 className="font-bold text-[1.4rem] md:leading-7 md:text-3xl text-center mx-auto mt-6 mb-10">
          What People Are Saying
        </h1>
        <div className="testimonials">
          <div className=" testimonial-content">
            <img src="/mainquote.png" alt="" />
            <p className="">
              “LoveMe has been the defining moment for my relationship. I always
              like to share with my partener how much they mean to me. With
              loveMe it’s now easier”.
            </p>
            <span className="font-bold">- Favour M.</span>
          </div>
          <div className="testimonial-content mb-6">
            <img src="/mainquote.png" alt="" />
            <p>
              “With LoveMe creating a romantic love is super fast. I could never
              have asked for somthing better”
            </p>
            <span className="font-bold">- Patrick A.</span>
          </div>
          <div className="testimonial-content mb-6">
            <img src="/mainquote.png" alt="" />
            <p>
              “Creating a love letter has always been a hassel to me. Writing
              one isn’t a chore anymore. It is so easy that it only takes three
              steps “
            </p>
            <span className="font-bold">- Josh K.</span>
          </div>
        </div>
        <span className="heart x1"></span>
        <span className="heart x2"></span>
        <span className="heart x3"></span>
        <span className="heart x4"></span>
        <span className="heart x5"></span>
        <span className="altheart x6"></span>
      </section>

      <section className="section-6 flex flex-col items-center justify-center gap-9 text-center p-16 relative ">
        <h2 className=" text-2xl md:text-4xl text-main-7 mt-8 w-screen">
          Join our waitlist
        </h2>
        <p className="font-medium  w-screen p-2 text-lg md:text-xl">
          Be the first to know when we launch & also get exclusive offers
        </p>
        <div className=" flex flex-col gap-5 md:items-center md:justify-center md:gap-8 relative">
          <label
            htmlFor="email"
            className="flex justify-center items-center relative rounded-lg"
          >
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              className="border border-gray-200 p-3 w-72 sm:w-96 rounded-lg"
              type="email"
              name="email"
              id="email"
              placeholder="Enter email address"
            />
          </label>
          <SubscribeButton
            email={email}
            setSuccessMsg={setSuccessMsg}
            setSuccess={setSuccess}
            setError={setError}
            setErrorMsg={setErrorMsg}
            setEmail={setEmail}
          />
          {/* <div onClick={subscribeUser}>
            <Link
              to="/"
              className="subscribe_link w-40 sm:w-52 mx-auto p-1 sm:p-2 bg-main-1 no-underline
            rounded-lg text-white text-base sm:text-xl"
            >
              Subscribe
            </Link>
          </div> */}
        </div>

        <img
          src="/dottedlove-half.svg"
          alt=""
          className="hidden md:block absolute top-0 left-0 w-2/6"
        />
      </section>
      <FooterTwo />
    </div>
  );
}

export default LandingPage;
