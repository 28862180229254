import React, { useState } from 'react';
// import Footer from '../../components/Footer';
import { AiOutlineSearch } from 'react-icons/ai';
import Navbar from '../../components/Navbar/Navbar';
import FooterTwo from '../../components/FooterTwo';
import Opening from '../../assets/career.png'

const CareerPage = () => {
  const [search, setSearch] = useState('');

  return (
    <>
      <Navbar />
      <div className="max-w-6xl m-auto p-5 mt-[56px] md:mt-[80px]">
        <div className="text-center mb-10">
          <h1 className="text-[#04172A] md:text-[54px] text-3xl">Join Our Team</h1>
          <p className="text-lg md:text-2xl my-3">Explore a career at LoveME</p>
        </div>
        <section className="relative w-full flex flex-row gap-5 justify-between">
          <input
            type="text"
            className="border border[#d0d5dd] outline-none h-[50px] w-4/5 pl-10 rounded-lg"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search positions"
          />
          <AiOutlineSearch className="absolute top-3 left-2 w-7 h-7 text-[#b4b9bf]" />
          <button
            type="submit"
            className="bg-main-1 border-none text-white flex justify-center items-center px-6 md:px-10 rounded-lg"
          >
            Search
          </button>
        </section>

        <section className="max-w-3xl m-auto p-5">
        <div className="md:mt-16 flex flex-co justify-center item-center">
          <img src={Opening} alt="No current opening on career page" className=" h-[350px] w-[350px] md:h-auto md:w-auto object-contain" />
        </div>
          <p className="text-lg md:text-[30px] text-[#475467] text-center md:my-10 leading-tight">We don&apos;t have any opening at the moment but you can keep checking as we are currently growing</p>
        </section>
      </div>
      <FooterTwo />
    </>
  );
};

export default CareerPage;
