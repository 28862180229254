import React, { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

export const LetterContext = createContext();

export const LetterContextProvider = (prop) => {
  const [viewletter, setLetter] = useState([]);
  const [id, setId] = useState();
  const [isLoading, setIsLoading] = useState();


    const location = useLocation()
   const token = localStorage.getItem('token')
    if(location.pathname !== "/previewletter") location.state=null

    
    useEffect(() => {
        //console.log("working")
           
            setIsLoading(true)
            axios.get('letter/',{
              headers: {
                Authorization: `Bearer ${token}`,
                access_token: token,
              }
            }
            )
                .then((res) => {
                    setIsLoading(false);
                  //  console.log(res)
                 // console.log('id', id)
                   const letter = res.data.find((letter) => letter.id === id);
                    setLetter(letter);
                  //  console.log(letter)

                })
                .catch(err => console.error(err));
    },[id])


  return (
    <LetterContext.Provider
      value={{ viewletter, setLetter, id, setId, isLoading, setIsLoading }}
    >
      {' '}
      {prop.children}{' '}
    </LetterContext.Provider>
  );
};
