import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './interceptors/axios';
import { AuthContextProvider } from './context/AuthContext';
import { LetterContextProvider } from './context/lettercontext';
import PartnerDetailsFormContextContainer from './context/PartnerDetailsFormContext';
import { ChatContextProvider } from './context/ChatContext';
import { AdminAuthContextProvider } from './context/AdminAuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <BrowserRouter>
        <React.StrictMode>
          <AuthContextProvider>
            <LetterContextProvider>
              <PartnerDetailsFormContextContainer>
                <ChatContextProvider>
                  <AdminAuthContextProvider>
                    <App />
                  </AdminAuthContextProvider>{' '}
                </ChatContextProvider>{' '}
                {}{' '}
              </PartnerDetailsFormContextContainer>{' '}
            </LetterContextProvider>{' '}
          </AuthContextProvider>{' '}
        </React.StrictMode>{' '}
      </BrowserRouter>
      
);
