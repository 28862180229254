import React from 'react'
import PropTypes from 'prop-types';

export const AdminTotalSend = ({ image, title, number , borderLeft, color ,/*setErrorMsg, setSuccessMsg,  setError, setSuccess*/}) => {

    const style={borderLeft:`${borderLeft} solid ${color}`}

    const pstyle = { color: color}

    return (
        <div style={style} className="flex gap-[29px] p-[15px] bg-[rgba(245,245,245,0.8)] items-center rounded-[10px] justify-between md:justify-start ">
            <div className='flex flex-col gap-[10px] w-[139px]'>
                <h4 className='font-avenir text-[18px] font-[500] text-[#263238]'>{title}</h4>
                <p style={pstyle} className="nunito font-extralight font-avenir text-[42px]">{number}</p>
            </div>
            <img src={image} alt="image" className="w-[42px] h-[42px]"   />
        </div>
    )

}

AdminTotalSend.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    number: PropTypes.string,
    borderLeft: PropTypes.string,
    color: PropTypes.string,
}
