import React from "react"
import PropTypes from "prop-types"
import "../styles/Pagination.css"

export default function Pagination({postsPerPage, totalPosts, paginate}){
    function setActive(e, numbers) {
            const list = document.querySelectorAll('.page-link')
            for (let i=0; i < list.length; i++) {
                list[i].classList.remove('page-link-active')
            }
            e.target.classList.add('page-link-active')
            paginate(numbers)
    }
    const pageNumbers = []
    for (let i=1; i <= Math.ceil(totalPosts / postsPerPage); i++){
        pageNumbers.push(i)
    }
    return (
        <nav className="pagination--container">
            <ul className="pagination">
                {pageNumbers.map(numbers => (
                    <li key={numbers} className="pag-page-item">
                        <button onClick={(e)=>setActive(e, numbers)} className="page-link">
                            {numbers}
                        </button>
                    </li>
                ))}

            </ul>
        </nav>

    )
}

Pagination.propTypes = {
    postsPerPage: PropTypes.number,
    totalPosts: PropTypes.number,
    paginate: PropTypes.func
}