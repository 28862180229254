import { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const defaultState = {
  partnersName: '',
  yourName: '',
  relationship: '',
  feeling: '',
};

export const PartnerDetailsFormContext = createContext(defaultState);

function PartnerDetailsFormContextContainer(props) {
  const [formData, setFormData] = useState(defaultState);
  function handleSetFormData(e) {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  }
  const providerValue = { formData, handleSetFormData };
  return (
    <PartnerDetailsFormContext.Provider value={providerValue}>
      {props.children}
    </PartnerDetailsFormContext.Provider>
  );
}

PartnerDetailsFormContextContainer.propTypes = {
  children: PropTypes.element,
};
export default PartnerDetailsFormContextContainer;
