import { useState } from 'react';
/*  Please only pass bool conditions as arguements to the hooks*/
export const useModal = (status) => {
  const [userStatus, setmodalState] = useState(status);
  const showHandler = () => {
    setmodalState(true);
  };
  const hideHandler = () => {
    setmodalState(false);
  };
  return {
    userStatus,
    showHandler,
    hideHandler,
  };
};
