import React from 'react';
import PriceComponent from './PriceComponent';
import styles from './css/Prices.module.css';
import FooterTwo from './FooterTwo';
import Navbar from './Navbar/Navbar';

const allPrices = [
  {
    id: 'price_1MAWYkGYYMC7FKsIKjAWAuvF',
    heading: 'Sweet Love',
    price: '$3',
    duration: '/month',
    desc: [
      'Love notes curated for your loved one, and scheduled to be sent out in the morning',
      'You get to customize 10 love notes of your choice',
    ],
  },
  {
    id:  'price_1MAWaQGYYMC7FKsIArbh4GrR',
    heading: 'Advanced',
    price: '$7',
    duration: '/quarter',
    desc: [
      'Love notes curated for your loved one, and scheduled to be sent out in the morning and night',
      'Love note to be delivered to your lover at night. (2 times a week).',
      'You get to customize 20 love notes of your choice.',
    ],
  },
  {
    id:  'price_1MAWbDGYYMC7FKsI06VUwZZ0',
    heading: 'Pro Gratifying',
    price: '$25',
    duration: '/annually',
    desc: [
      'Love notes curated for your loved one, and scheduled to be sent out in the morning and night',
      'Unlimited access to customize love notes.',
      'Special love notes for your loved ones birthday.',
    ],
  },
];

const Prices = () => {
  const prices = allPrices;
  return (
    <>
      <Navbar />
      <main className={styles.Prices}>
        <div className={styles.TitleContainer}>
          <h1>Pricing</h1>
          <p className={styles.Subscription}>
            Our subscription plans are scheduled monthly
          </p>
        </div>
        <section className={styles.PricesContainer}>
          {prices.map(({ heading, price, duration, desc, id }, i) => (
            <PriceComponent
              i={i}
              key={id}
              heading={heading}
              price={price}
              duration={duration}
              desc={desc}
              id={id}
            />
          ))}
        </section>
      </main>
      <FooterTwo />
    </>
  );
};

export default Prices;
export { allPrices };
