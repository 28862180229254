import LetterPage from '../../components/LetterPage';

export default function PreviewLetter() {
  return (
    <LetterPage
      editFeat={true}
      getLetterPage={true}
      scheduleLetterPage={false}
      completedLetterPage={false}
    />
  );
}
