import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/reset/password_reset`;

const AdminCreateNewPassword = () => {
  // Grab token
  const [token, setToken] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('savedToken'));
    if (token) {
      setToken(token);
    }
  }, []);

  // Update password
  const [password, setPassword] = useState('');
  const newPassword = useRef(null);
  const confirmPassword = useRef(null);
  const submitStatus = useRef(null);
  const [alertMsg, setAlertMsg] = useState('');

  const handleChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
//     console.log(token);
      if (newPassword.current.value !== confirmPassword.current.value) {
        alert('passwords do not match');
        return;
      }
    
    try {
      const resp = await axios.post(url, { password, token });
      submitStatus.current.style.visibility = 'visible';
      setTimeout(() => {
        submitStatus.current.style.visibility = 'hidden';
      }, 3000);
//       console.log(resp);
      if (resp.status === 200) {
        e.target.reset();
        submitStatus.current.classList.add('success');
        submitStatus.current.classList.remove('danger');
        setAlertMsg('Password reset successful');
      }
      if (resp.response.status > 200) {
        submitStatus.current.classList.remove('success');
        submitStatus.current.classList.add('danger');
        setAlertMsg('An error occurred please restart the process');
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <section className="px-[2rem] md:px-[7rem] pb-16 md:pb-20 text-[#383838] my-[2rem] mx-[auto] lg:flex flex-row justify-between ">
        <article className="  md:px-8  md:pt-[4rem] lg:w-[60%]">
          {/* logo */}
          <div className=" flex justify-center items-center gap-x-1 pb-12 pt-4 md:justify-start">
            <img
              src="/images/logos_vivaldi-icon.svg"
              alt="logo_vivaldi"
              className=" w-[1.7rem]"
            />
            <h3 className=" font-[600] text-[#464646] text-[1.7rem] ">
              LoveMe
            </h3>
          </div>
          {/* content */}
          <div className=" mb-8">
            {/* header */}
            <h2 className=" mb-4 text-[1.2rem] md:text-[1.9rem] font-[500] text-[#04172A] ">
              Create a new password
            </h2>
            <p className=" text-[0.75rem] md:text-[1.125rem] leading-[2.5rem]">
              Your new password must be different from anyone you’ve used
              previously. Make it unique and something you can easily remember!
            </p>
          </div>
          {/* form */}
          <form onSubmit={handleSubmit}>
            {/* create new password */}
            <div className=" mb-12">
              <label
                htmlFor="password"
                className=" block  mb-4 md:text-[1.1rem] "
              >
                New Password
              </label>
              <input
                ref={newPassword}
                id="password"
                type="password"
                placeholder="********"
                minLength={8}
                required
                className=" border border-solid border-[#D0D5DD] bg-[#F9FAFB] rounded-[8px]  pl-4 py-3 w-[100%] "
              />
              <span className=" text-[0.8rem] md:text-[1.1rem] mt-4 inline-block">
                NOTE: Password must be at least 8 characters
              </span>
            </div>

            {/* confirm passowrd */}
            <div className=" mb-12">
              <label
                htmlFor="confirm_password"
                className=" block  mb-4 md:text-[1.1rem]"
              >
                Confirm Password
              </label>
              <input
                ref={confirmPassword}
                id="confirm_password"
                type="password"
                placeholder="********"
                minLength={8}
                required
                onChange={handleChange}
                className=" border border-solid border-[#D0D5DD] bg-[#F9FAFB] rounded-[8px]  pl-4 py-3 w-[100%] "
              />
              {/* submit status */}
              <p
                ref={submitStatus}
                className="submit-status ml-auto mr-auto text-lg"
              >
               {alertMsg}
              </p>
            </div>
            {/* send reset button */}
            <button className=" bg-main-1 text-white w-[100%] h-[2.75rem] rounded-lg block border border-solid border-main-1 ml-[auto] mr-[auto] mb-4">
              Reset Password
            </button>
          </form>
        </article>
        <article className="hidden lg:grid place-items-center w-[50%]">
          <img
            src="/images/questionMark.png"
            alt="question mark"
            className="mr-[auto] ml-[auto] w-full"
          />
        </article>
      </section>
    </>
  );
};

export default AdminCreateNewPassword;
