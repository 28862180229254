import React from 'react';
import FooterTwo from '../components/FooterTwo';
import Navbar from '../components/Navbar/Navbar';
import style from '../styles/HowItWorks.module.css';
import survey from '../assets/HIW/Group.svg';

const HowItWorks = () => {
  return (
    <div>
      <Navbar />
      <div className={style.how_it_works}>
        <div className={style.hiw_head}>
          <h2>How It Works</h2>
          <div className={style.hiw_underline} />
          <p>These are the few steps to take in generating a love letter</p>
        </div>
        <div className={style.hiw_body}>
          <div className={style.hiw_control}>
            <p>01</p>
            <img src={survey} alt="" />
            <h4>Login To Love Me Web App</h4>
            <p>
              After sign-in, navigate to the generate icon to start generating
              the letters
            </p>
          </div>
          <div className={style.hiw_control}>
            <p>02</p>
            <img src={survey} alt="" />
            <h4>Take A Short Quiz</h4>
            <p>
              Answer a few questions about your special someone and your
              relationship. We will generate dozens of letters or messages based
              on your input.
            </p>
          </div>
          <div className={style.hiw_control}>
            <p>03</p>
            <img src={survey} alt="" />
            <h4>Add An Image</h4>
            <p>
              You can add some photos of your loved ones on the letter, or you
              can select from our choice of image based on the survey
            </p>
          </div>
          <div className={style.hiw_control}>
            <p>04</p>
            <img src={survey} alt="" />
            <h4>Get Ready To Steal Your Love One Heart</h4>
            <p>
              After the images is added, your love letter is ready,as unique as
              your relationship to melt the hearts your loved ones away
            </p>
          </div>
        </div>
      </div>
      <FooterTwo />
    </div>
  );
};

export default HowItWorks;
