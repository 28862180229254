import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import trash from "../../assets/trash.png"
import { AdminCheckbox } from './admincomponents/checkbox';
//import { refreshToken } from './adminutils';
import moment from 'moment/moment';
import { toast } from 'react-toastify';

function UserRow({firstName, lastName, email, id,inx, lastActive, userList, setUserList,users, setUsers}) {
    

    const handleDelete = () => {

        const token = localStorage.getItem('admintoken');
        const options = {
          url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/user/${id}`,
          method: 'DELETE',
          header: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': '*'
          }
        };
        axios(options)
          .then((res) => {
            if(res.data === undefined){
              throw  Error("Can't delete this user")
            }
            const users = userList.filter(user => user.id !== id)
            setUserList(users)
            toast.success(`Successfully deleted`, {
                position: toast.POSITION.TOP_CENTER
            })
          })
          .catch((err) => {
            console.log(err) 
            toast.error(`${err}`, {
              position: toast.POSITION.TOP_CENTER
          })(err)
            
          });
      }


    var offset = moment().utcOffset();
    var last_active = moment.utc(lastActive).utcOffset(offset).format("L LT");
    

    return (
        <div key={id} className="table font-avenir font-[500] text-base">
            <div className='flex items-center'><AdminCheckbox id={id} inx={inx} setUsers={setUsers} users={users} /></div>
            <div>{firstName}</div>
            <div>{lastName}</div>
            <div>{email}</div>
            <div>{last_active}</div>
            <div onClick={handleDelete} className="cursor-pointer">
                <img src={trash}  alt="trash" width='24px' height="24px" />
            </div>
        </div>
        
        
    )
}

UserRow.propTypes = {
    firstName: PropTypes.string,
    lastActive: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    userList: PropTypes.array,
    setUserList: PropTypes.func,
    users: PropTypes.array,
    setUsers: PropTypes.func,
    inx: PropTypes.number,

}



export default UserRow;