import { useEffect, useState } from 'react';
import axios from 'axios';
import Button from '../../components/Button/Button';
//import InputField from '../../components/InputField/InputField';
import PropTypes from 'prop-types';

const CardPayment = ({ planInfo, loading, handleLoading }) => {
  const [url, setUrl] = useState('');
  const Loader = (
    <div className="w-7 h-7 animate-spin border-white border-t-main-1 border-4 border-solid rounded-full "></div>
  );
  console.log(loading);
  console.log(planInfo.id);
  const token = localStorage.getItem('token');
  console.log(token);

  function handlePayment() {
    handleLoading(true);
    console.log('active');
    const options = {
      url: `https://api.dev.love-me.app/api/v1/subscription/checkout/${planInfo.id}`,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
      },
    };
    axios(options)
      .then((res) => {
        console.log(res);
        setUrl(res.data.url);
        handleLoading(false);
      })
      .catch((err) => {
        console.log(err);
        handleLoading(false);
      });
  }
  useEffect(() => {
    handlePayment();
  }, []);
  return (
    <form>
      <div className="text-gray-700 mt-6">
        {/* <label htmlFor="card-number">Card Number</label>
        <InputField
          name={'card-number'}
          type={'text'}
          placeholder={'0000 0000 0000 0000'}
        />
      </div>

      <div className="mt-6 mb-8 flex gap-6 flex-col lg:flex-row items-stretch justify-between">
        <div className="text-gray-700 ">
          <label htmlFor="expiry-date">Valid Till</label>
          <InputField
            name={'expiry-date'}
            type={'text'}
            placeholder={'MM/YY'}
          />
        </div>

        <div className="text-gray-700 ">
          <label htmlFor="cvv">CVV</label>
          <InputField name={'cvv'} type={'text'} placeholder={'CVV'} />
        </div>*/}
        <div>
          <h2 className="text-lg font-avenir_light">Subscription Features</h2>
          <p className="font-bold text-xl py-2">{planInfo.heading}</p>
          <p className="pb-2">Amount Payable: {planInfo.price}</p>
          <p className="pb-2">
            {planInfo.id == 1
              ? '1 Month'
              : planInfo.id == 2
              ? '3 Months'
              : '1 Year'}
          </p>
          <ul className="flex flex-col justify-center gap-2 my-4 pb-4">
            {planInfo.desc.map((val, id) => {
              return (
                <li className=" list-inside px-2" key={id}>
                  {val}
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <a href={url}>
        <Button name={loading ? Loader : 'Make Payment'} height={'40px'} />
      </a>
    </form>
  );
};

export default CardPayment;

CardPayment.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  handleLoading: PropTypes.func.isRequired,
  planInfo: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
