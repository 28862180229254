import React, { useRef, useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import FooterTwo from '../components/FooterTwo';
import axios from 'axios';

const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/contact_us/`;

const ContactPage = () => {
  const submitStatus = useRef(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    messages: '',
  });

  const handleChange = (e) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post(url, formData);
      if (resp.status === 200) {
        e.target.reset();
        submitStatus.current.style.display = 'block';
        setTimeout(() => {
          submitStatus.current.style.display = 'none';
        }, 3000);
      }
    } catch (error) {
      console.log(error.resp);

      
    }
  };

  return (
    <>
      <main>
        <Navbar />
        <section
          id="form"
          className="h-full w-full pb-[50px] md:pb-[100px]  flex flex-col  gap-y-8  pt-[5rem] lg:bg-[#F5F5F5]"
        >
          {/* banner */}
          <div className="bg-banner bg-cover bg-center  h-[7.5rem] md:h-[10.5rem] grid place-items-center">
            <p className=" text-white md:text-2xl">Contact us</p>
          </div>

          {/*contact page  content */}
          <div className="text-center text-[#04172A]">
            <p className="md:text-3xl text-2xl font-bold  mb-2 ">
              Get in touch with us!
            </p>
            <p className="text-sm  md:tracking-wide text-[#464646] ">
              24/7 We offer our support services to ease your experiences
            </p>
          </div>

          <div className="flex flex-col lg:flex-row justify-between px-[4vw] lg:w-[80%] lg:mr-auto lg:ml-auto  bg-white  lg:p-6 ">
            {/* header */}
            <div className=" bg-contactMobile bg-cover bg-center object-cover  rounded-md text-white flex flex-col items-center justify-center lg:pl-10 lg:items-start lg:text-left text-center  pt-10  relative lg:w-[50%] lg:h-[35rem]">
              <div className=" mb-[1.8rem] ">
                <h3 className=" font-bold text-[1.3rem] mb-[0.5rem] md:text-[1.5rem] ">
                  Contact Information
                </h3>
                <p className="text-[0.8rem] text-[#C9C9C9] md:text-base">
                  Say something to start a live chat!
                </p>
              </div>

              {/* header content */}
              <div className=" flex flex-col gap-y-20 pb-20">
                {/* Location icon */}
                <div
                  id="address"
                  className="flex flex-col gap-y-4 items-center lg:items-start "
                >
                  <img
                    src="images/IconlocationIcon.svg"
                    alt="address"
                    className="w-[22px]"
                  />
                  <a
                    href="https://goo.gl/maps/ErrdrHKFGjUppqW29"
                    className="pt-1 text-[#C9C9C9] text-sm  md:text-base "
                  >
                    Lagos, Nigeria
                  </a>
                </div>

                {/* Email Icon */}
                <div
                  id="phone"
                  className="flex flex-col gap-y-4 items-center lg:items-start"
                >
                  <img
                    src="images/IconmailIcon.svg"
                    alt="email"
                    className="w-[22px]"
                  />
                  <a
                    href="mailto:contact@love-me.app"
                    target="_blank"
                    rel="noreferrer"
                    className=" tracking-wide pt-2 text-[#C9C9C9] text-sm md:text-base "
                  >
                    contact@love-me.app
                  </a>
                </div>

                {/* Phone Icon */}
                <div
                  id="phone"
                  className="flex flex-col gap-y-4 items-center lg:items-start"
                >
                  <img
                    src="images/IconphoneIcon.svg"
                    alt="phone"
                    className="w-[22px]"
                  />
                  <p className="text-sm tracking-wide pt-[6px] text-[#C9C9C9] md:text-[105%] ">
                    +2349065745693
                  </p>
                </div>
              </div>
              {/* ellipse */}
              <div className="absolute bottom-[-0.4rem] lg:bottom-0 right-0  ">
                <div className=" relative">
                  <img
                    src="images/ellipse1.png"
                    alt="ellipse"
                    className=" absolute top-4 left-[-1rem] lg:w-[8rem] lg:-top-3 lg:-left-8"
                  />
                  <img
                    src="images/ellipse2.png"
                    alt="ellipse"
                    className="lg:hidden"
                  />
                  <img
                    src="images/Ellipse3.png"
                    alt="ellipse"
                    className="hidden lg:block"
                  />
                </div>
              </div>
            </div>

            <form
              className="flex flex-col gap-y-6 text-[#344054] text-sm lg:w-[40%]   mt-auto mb-auto pt-4 "
              onSubmit={handleSubmit}
            >
              {/* Name input field */}
              <div className="flex flex-col gap-1 ">
                <label htmlFor="name">Full Name</label>
                <input
                  required
                  type="text"
                  name="name"
                  className="p-3 border border-solid rounded-lg border-[#D0D5DD] placeholder-[#AFAFAF] text-[#1D2939] 
                  focus:outline-none focus:border-2 hover:border-[#344054] focus:border-[#344054] hover:ring-[#344054]"
                  placeholder="John Doe"
                  onChange={handleChange}
                />
              </div>

              {/* Email input field */}
              <div className="flex flex-col gap-1  w-full">
                <label htmlFor="email">E-mail address</label>
                <input
                  required
                  type="email"
                  name="email"
                  className="p-3 border border-solid rounded-lg border-[#D0D5DD] placeholder-[#AFAFAF] text-[#1D2939] 
                  focus:outline-none focus:border-2 hover:border-[#344054] focus:border-[#344054] hover:ring-[#344054]"
                  placeholder="user@gmail.com"
                  onChange={handleChange}
                />
              </div>

              {/* Message input field */}
              <div className="flex flex-col gap-1  w-full">
                <label htmlFor="message">Write us a message</label>
                <textarea
                  required
                  id="message"
                  name="messages"
                  className="marker:h-[120px] p-3 border border-solid rounded-lg border-[#D0D5DD] placeholder-[#AFAFAF] text-[#1D2939] 
                  focus:outline-none focus:border-2 hover:border-[#344054] focus:border-[#344054] hover:ring-[#344054] resize-none"
                  placeholder="Type a message"
                  onChange={handleChange}
                ></textarea>
                {/* submit status */}
                <p
                  ref={submitStatus}
                  className="submit-status ml-auto mr-auto text-xl text-green-600"
                >
                  Message sent successfully
                </p>
              </div>

              {/* Send Button */}
              <button className=" bg-main-1 text-white w-[11.25rem] h-[2.75rem] rounded-xl block border border-solid border-main-1 mb-4 text-[1.25rem] md:w-full">
                Send message
              </button>
            </form>
          </div>
        </section>
      </main>
      <FooterTwo />
    </>
  );
};

export default ContactPage;
