import React from 'react';
import '../styles/PrivacyPolicy.css';
import Navbar from '../components/Navbar/Navbar';
import FooterTwo from '../components/FooterTwo';

function PrivacyPolicy() {
  return (
    <>
      <div>
      <Navbar />
        <section className="mb-[56px] w-full mt-5">
        </section>
        <section className="policy h-[150px] md:h-[232px] flex justify-center items-center">
            <div className="">
            <h1 className="text-3xl md:text-[64px] text-center leading-tight">
              Privacy policy
            </h1>
            <p className="text-lg md:text-2xl mt-2 text-center leading-tight">
              Last Updated: 15/11/2022
            </p>
            </div>
          </section>
        <div className="p-6 sm:p-10 md:p-12 lg:p-20 xl:p-24 2xl:px-48">

          <div className="space-y-10 sm:text-justify ">
            <div className="text_div">
              <p>
                Protecting your private information is our priority. This
                Statement of Privacy applies to{' '}
                <a href="/" className="underline">
                  loveme.hng.tech
                </a>{' '}
                and HNG Internship, LLC and governs data collection and usage.
                For the purposes of this Privacy Policy, unless otherwise noted,
                all references to HNG Internship, LLC include{' '}
                <a href="/" className="underline">
                  loveme.hng.tech
                </a>{' '}
                and LoveMe. The LoveMe website is an e-commerce site. By using
                the LoveMe website, you consent to the data practices described
                in this statement.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Collection of your Personal Information:</h2>
              <p>
                In order to better provide you with products and services
                offered, LoveMe may collect personally identifiable information,
                such as your:
              </p>
              <p>First and Last Name</p>
              <p className="info__">Mailing Address</p>
              <p className="info__">E-mail Address</p>
              <p>
                If you purchase LoveMe&apos;s products and services, we collect
                billing and credit card information. This information is used to
                complete the purchase-transaction. We do not collect any
                personal information about you unless you voluntarily provide it
                to us. However, you may be required to provide certain personal
                information to us when you elect to use certain products or
                services. These may include: (a) registering for an account; (b)
                entering a sweepstakes or contest sponsored by us or one of our
                partners; (c) signing up for special offers from selected third
                parties; (d) sending us an email message; (e) submitting your
                credit card or other payment information when ordering and
                purchasing products and services. To wit, we will use your
                information for, but not limited to, communicating with you in
                relation to services and/or products you have requested from us.
                We also may gather additional personal or non-personal
                information in the future.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Use of your Personal Information:</h2>
              <p>
                LoveMe collects and uses your personal information to operate
                and deliver the services you have requested.
              </p>
              <p>
                LoveMe may also use your personally identifiable information to
                inform you of other products or services available from LoveMe
                and its affiliates.
              </p>
              <p>
                We may store photographs, images and other content uploaded or
                submitted to the Site - and information about any such
                photographs and images - in order to provide products and
                services, improve our products and services, garner insights and
                information about our users, and to fulfil orders. Our printing
                and assembly process requires human involvement, so LoveMe
                employees and vendors will see your photographs, images and
                other content that you submit to the Site. We do not otherwise
                use the photographs, images or content in a manner that would
                identify you personally.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Sharing Information with Third Parties:</h2>
              <p>
                LoveMe does not sell, rent or lease its customer lists to third
                parties.
              </p>
              <p>
                LoveMe may share data with trusted partners to help perform
                statistical analysis, send you email or postal mail, provide
                customer support, or arrange for deliveries. All such third
                parties are prohibited from using your personal information
                except to provide these services to LoveMe, and they are
                required to maintain the confidentiality of your information.
              </p>
              <p>
                LoveMe may disclose your personal information, without notice,
                if required to do so by law or in the good faith belief that
                such action is necessary to: (a) conform to the edicts of the
                law or comply with legal process served on LoveMe or the site;
                (b) protect and defend the rights or property of LoveMe; and/or
                (c) act under exigent circumstances to protect the personal
                safety of users of LoveMe, or the public.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2> Tracking User Behaviour: </h2>
              <p>
                LoveMe may keep track of the websites and pages our users visit
                within LoveMe, in order to determine what LoveMe services are
                the most popular. This data is used to deliver customised
                content and advertising within LoveMe to customers whose
                behaviour indicates that they are interested in a particular
                subject area.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Automatically Collected Information: </h2>
              <p>
                Information about your computer hardware and software may be
                automatically collected by LoveMe. This information can include:
                your IP address, browser type, domain names, access times and
                referring website addresses. This information is used for the
                operation of the service, to maintain quality of the service,
                and to provide general statistics regarding use of the LoveMe
                website.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Use of Cookies: </h2>
              <p>
                The LoveMe website may use &ldquo;cookies&rdquo; to help you
                personalise your online experience. A cookie is a text file that
                is placed on your hard disk by a web page server. Cookies cannot
                be used to run programs or deliver viruses to your computer.
                Cookies are uniquely assigned to you, and can only be read by a
                web server in the domain that issued the cookie to you.
              </p>
              <p>
                One of the primary purposes of cookies is to provide a
                convenience feature to save you time. The purpose of a cookie is
                to tell the Web server that you have returned to a specific
                page. For example, if you personalise LoveMe pages, or register
                with LoveMe site or services, a cookie helps LoveMe to recall
                your specific information on subsequent visits. This simplifies
                the process of recording your personal information, such as
                billing addresses, shipping addresses, and so on. When you
                return to the same LoveMe website, the information you
                previously provided can be retrieved, so you can easily use the
                LoveMe features that you customised.
              </p>
              <p>
                You have the ability to accept or decline cookies. Most Web
                browsers automatically accept cookies, but you can usually
                modify your browser setting to decline cookies if you prefer. If
                you choose to decline cookies, you may not be able to fully
                experience the interactive features of the LoveMe services or
                websites you visit.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Security of your Personal Information:</h2>
              <p>
                LoveMe secures your personal information from unauthorised
                access, use, or disclosure. LoveMe uses the following methods
                for this purpose:
              </p>
              <p>SSL Protocol</p>
              <p>
                When personal information (such as a credit card number) is
                transmitted to other websites, it is protected through the use
                of encryption, such as the Secure Sockets Layer (SSL) protocol.
              </p>
              <p>
                We strive to take appropriate security measures to protect
                against unauthorised access to or alteration of your personal
                information. Unfortunately, no data transmission over the
                Internet or any wireless network can be guaranteed to be 100%
                secure. As a result, while we strive to protect your personal
                information, you acknowledge that: (a) there are security and
                privacy limitations inherent to the Internet which are beyond
                our control; and (b) security, integrity, and privacy of any and
                all information and data exchanged between you and us through
                this Site cannot be guaranteed.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Right to Deletion: </h2>
              <p>
                You can request us to erase any personal data we hold about you
                by emailing us at info@loveme.com. Please include the email
                address used when you registered on the site.
              </p>
              <p>
                Subject to certain exceptions set out below, on receipt of a
                verifiable request from you, we will:
              </p>
              <p>Delete your personal information from our records; and</p>
              <p>
                Direct any service providers to delete your personal information
                from their records.
              </p>
              <p>
                Please note that we may not be able to comply with requests to
                delete your personal information if it is necessary to:
              </p>
              <p>
                Complete the transaction for which the personal information was
                collected, fulfil the terms of a written warranty or product
                recall conducted in accordance with federal law, provide a good
                or service requested by you, or reasonably anticipated within
                the context of our ongoing business relationship with you, or
                otherwise perform a contract between you and us;
              </p>
              <p>
                Detect security incidents, protect against malicious, deceptive,
                fraudulent, or illegal activity; or prosecute those responsible
                for that activity;
              </p>
              <p>
                Debug to identify and repair errors that impair existing
                intended functionality;
              </p>
              <p>
                Exercise free speech, ensure the right of another consumer to
                exercise his or her right of free speech, or exercise another
                right provided for by law;
              </p>
              <p>
                Comply with the California Electronic Communications Privacy
                Act;
              </p>
              <p>
                Engage in public or peer-reviewed scientific, historical, or
                statistical research in the public interest that adheres to all
                other applicable ethics and privacy laws, when our deletion of
                the information is likely to render impossible or seriously
                impair the achievement of such research, provided we have
                obtained your informed consent;
              </p>
              <p>
                Enable solely internal uses that are reasonably aligned with
                your expectations based on your relationship with us;
              </p>
              <p>Comply with an existing legal obligation; or</p>
              <p>
                Otherwise use your personal information, internally, in a lawful
                manner that is compatible with the context in which you provided
                the information.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Children Under Thirteen: </h2>
              <p>
                LoveMe does not knowingly collect personally identifiable
                information from children under the age of thirteen. If you are
                under the age of thirteen, you must ask your parent or guardian
                for permission to use this website.
              </p>
              <p>
                Disconnecting your LoveMe Account from Third Party Websites:
              </p>
              <p>
                You will be able to connect your LoveMe account to third party
                accounts. BY CONNECTING YOUR LOVEME ACCOUNT TO YOUR THIRD PARTY
                ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO
                THE CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN
                ACCORDANCE WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY
                SITES). IF YOU DO NOT WANT INFORMATION ABOUT YOU, INCLUDING
                PERSONALLY IDENTIFYING INFORMATION, TO BE SHARED IN THIS MANNER,
                DO NOT USE THIS FEATURE. You may disconnect your account from a
                third party account at any time. Users&apos; connections to
                third party site (e.g. Facebook, Instagram) are disconnected
                when the user ends their session (i.e. navigates away from the
                website).
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>E-mail Communications: </h2>
              <p>
                From time to time, LoveMe may contact you via email for the
                purpose of providing announcements, promotional offers, alerts,
                confirmations, surveys, and/or other general communication. In
                order to improve our Services, we may receive a notification
                when you open an email from LoveMe or click on a link therein.
              </p>
              <p>
                If you would like to stop receiving marketing or promotional
                communications via email from LoveMe, you may opt out of such
                communications by replying ``STOP &ldquo;or&rdquo; clicking on
                the UNSUBSCRIBE button.
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>External Data Storage Sites:</h2>
              <p>
                We may store your data on servers provided by third party
                hosting vendors with whom we have contracted.{' '}
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Changes to this Statement:</h2>
              <p>
                LoveMe reserves the right to change this Privacy Policy from
                time to time. We will notify you about significant changes in
                the way we treat personal information by sending a notice to the
                primary email address specified in your account, by placing a
                prominent notice on our website, and/or by updating any privacy
                information. Your continued use of the website and/or Services
                available after such modifications will constitute your: (a)
                acknowledgment of the modified Privacy Policy; and (b) agreement
                to abide and be bound by that Policy.{' '}
              </p>
            </div>

            <div className="text_div space-y-7">
              <h2>Contact Information:</h2>
              <p>
                {' '}
                LoveMe welcomes your questions or comments regarding this
                Statement of Privacy. If you believe that LoveMe has not adhered
                to this Statement, please contact LoveMe at:
              </p>
              <p>
                HNG Internship, LLC9925 Haynes Bridge Rd. Suite 103 Alpharetta,
                Georgia 30022{' '}
              </p>
              <p> Email Address:info@loveme.com </p>
              <p>Telephone number:7703351855</p>
            </div>
          </div>
        </div>
        <FooterTwo />
      </div>
    </>
  );
}

export default PrivacyPolicy;
