
import React from "react"
import PropTypes from 'prop-types';
import upgrade from "../assets/upgrade.png"
import Button from "./Button/Button";
import { Link } from "react-router-dom";


export const ModalContent = ({open, setOpen}) => {

    console.log(open, setOpen)
    return (
      <div className="max-w-[607px] w-[90%] py-[48px] px-[56px] bg-white rounded-[16px]" >
        <div className="md:w-[479px] md:h-[357px] w-[100%] h-auto">
            <img src={upgrade} alt="upgrade" className="block w-[100%]" />
        </div>
        <div className="mt-[64px] flex flex-col items-center gap-[32px] text-center font-avenir ">
            <h4 className="font-black text-[32px] font-avenir text-[#344054]">Your free trial has expired</h4>
            <p className="text-[16px] font-avenir text-[#344054] leading-[20px]">But it’s not too late to take the next move. Upgrade to a paid plan to keep using our product.</p>
            <Link to="/pricing">
                <Button width="274px" height="48px" name="Upgrade Now" />
            </Link>
        </div>
      </div>
    );
  };
  
  ModalContent.propTypes = {
    setOpen: PropTypes.func,
    open: PropTypes.bool,
  };