import React from 'react';
import '../../styles/SiteMap.css';
import { Link } from 'react-router-dom';
import FooterTwo from '../../components/FooterTwo';
import Navbar from '../../components/Navbar/Navbar';

export default function SiteMap() {
  
  return (
    <>
      <Navbar />
      <div className="sitemaptitlebg text-center md:py-[71px] py-[30px] md:h-[232px] md:mb-[109px]  mt-[74px]">
        <p className="font-[850] md:text-[50px] text-[2rem] text-white leading-[90px]">Site Map</p>
      </div>
      <div className="sitemap">
        <section>
          <div className="hero--img">
            <img src="/sitemap-small.svg" alt="sitemap" />
          </div>
        </section>
        <section className="links-section">
          <div className="links--container">
            <p className="links--leading">Company</p>
            <Link className="site-link" to="/about">
              About us
            </Link>
            <Link className="site-link" to="/testimonials">
              Testimonials
            </Link>
            <Link className="site-link" to="/career">
              Careers
            </Link>
            <Link className="site-link" to="/contactus">
              Contact us
            </Link>
          </div>
          <div className="links--container">
            <p className="links--leading">Product</p>
            <Link className="site-link" to="/signin">
              Login
            </Link>
            <Link className="site-link" to="/survey">
              LoveMe Letter Generator
            </Link>
            <Link className="site-link" to="/pricing">
              Pricing
            </Link>
          </div>
          <div className="links--container">
            <p className="links--leading">Resources</p>
            <Link className="site-link" to="/apidocs">
              Documentation
            </Link>
            <Link className="site-link" to="/apidocs">
              API reference
            </Link>
            <Link className="site-link" to="/sitemap">
              Sitemap
            </Link>
            <Link className="site-link" to="/Uptime">
              SDKs
            </Link>
          </div>
          <div className="links--container">
            <p className="links--leading">Support</p>
            <Link className="site-link" to="/help-center">
              Help Center
            </Link>
            <Link className="site-link" to="/contactus">
              Contact Us
            </Link>
            <Link className="site-link" to="/incidents">
              Report Link bug
            </Link>
            <Link className="site-link" to="/faq">
              FAQs
            </Link>
          </div>
          <div className="links--container">
            <p className="links--leading">Legals</p>
            <Link className="site-link" to="/privacypolicy">
              Privacy Policy
            </Link>
            <Link className="site-link" to="/cookie-policy">
              Developer Policy
            </Link>
            <Link className="site-link" to="/termsofservice">
              Terms & Conditions
            </Link>
            <Link className="site-link" to="/cookie-policy">
              Cookie Policy
            </Link>
          </div>
          <div className="links--container">
            <p className="links--leading">Social Media</p>
            <Link className="site-link" to="/contactus">
              LoveMe Instagram
            </Link>
            <Link className="site-link" to="/contactus">
              LoveMe Twitter
            </Link>
            <Link className="site-link" to="/contactus">
              LoveMe Facebook
            </Link>
          </div>
        </section>
      </div>
      <FooterTwo />
    </>
  );
}
