import { useState, useEffect } from 'react';
import '../styles/Dashboard1.css';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import Pagination from '../components/Pagination';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from '../components/loader/loader';
import 'react-toastify/dist/ReactToastify.css';

import dash_img from '../../src/assets/dashboard-new.svg'; 

export default function Dashboard() {
  const navigate = useNavigate();
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [sent, setSent] = useState(false);
  const [onSent, setOnSent] = useState(true);
  const [sentNum, setSentNum] = useState(0);
  // const [scheduled] = useState(false);
  const [scheduled, setScheduled] = useState(false);
  const [onScheduled, setOnScheduled] = useState(false);
  // const [scheduledNum] = useState(0);
  const [scheduledNum, setScheduledNum] = useState(0);
  const [block, setBlock] = useState('block');
  const [currentPage, setCurrentPage] = useState(1);
  const [scurrentPage] = useState(1);
  // const [scurrentPage, setSCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  //   console.log(user);
  //   console.log(sent);
  //   console.log(sentNum);
  const token = localStorage.getItem('token');
  const success = () => {
    toast.success('Deleted!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  const errors = () => {
    toast.error('Error Occured', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  function sView(sid) {
    localStorage.setItem('scheduled', sid);
    localStorage.setItem('letterId', sid);
    navigate('/pastScheduled');
  }
  function cView(cid) {
    localStorage.setItem('completed', cid);
    localStorage.setItem('letterId', cid);
    navigate('/pastCompleted');
  }
  useEffect(() => {
    axios
      .get(`user/me/get-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        setIsLoading(false);
        setUser(res.data.firstname);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, []);
  function handleCompleted() {
    setOnScheduled(false);
    setOnSent(true);
  }
  function handleScheduled() {
    setOnSent(false);
    setOnScheduled(true);
  }
  function handleGrid() {
    setBlock('grid');
  }
  function handleBlock() {
    setBlock('block');
  }

  function sentLetters() {
    axios
      .get('dashboard', {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        // console.log(res)
        setSentNum(res.data.sent.length);
        setSent(res.data.sent);
      })
      .catch((err) => {
        errors();
        console.log(err);
      });
  }
  function scheduledLetters() {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/schedule`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        setScheduled(res.data);
        setScheduledNum(res.data.length);
      })
      .catch((err) => console.log(err));
  }
  useEffect(() => {
    sentLetters();
    scheduledLetters();
  }, []);
  function deleteCompleted(id){
    axios
    .delete(`letter/delete-letter/${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then((res) => {
      console.log(res);
      sentLetters();
      success();
    })
    .catch((err) => {
      errors();
      console.log(err);
    });

  }
  function deleteScheduled(id) {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/schedule/${id}/`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        console.log(res);
        scheduledLetters();
        success();
      })
      .catch((err) => {
        errors();
        console.log(err);
      });
  }
  let sentArray;
  let scheduledArray;
  if (sent) {
    sentArray = sent.map((index) => {
      const letter = index.letter.substr(0, 145);
      const id = index.id;
      let date = index.date_created.substr(0, 13);
      const year = date.substr(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);
      //  const datem = `${day}/${month}/${year}`
      return (
        <div key={id} className="dashboard--s__letters">
          <div className="dashboard--s__letters-header">
            <div className="dashboard--s__letters-header-title">
              {index.title}
            </div>
            <div>
              {day}/{month}/{year}
            </div>
          </div>

          <div>{letter}...</div>
          <div className="dashboard--view_btn-container">
            <button
              className="dasboard--delete__btn"
              onClick={() => deleteCompleted(id)}
            >
              <svg
                className="dashboard-trash"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.8504 9.13965L18.2004 19.2096C18.0904 20.7796 18.0004 21.9996 15.2104 21.9996H8.79039C6.00039 21.9996 5.91039 20.7796 5.80039 19.2096L5.15039 9.13965"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 12.5H14.5"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button onClick={() => cView(id)} className="dashboard--view_btn">
              View
            </button>
          </div>
        </div>
      );
    });
  }
  if (scheduled) {
    scheduledArray = scheduled.map((index) => {
      let letter = index.letter.letter.substr(0, 145);
      const id = index.id;
      let date = index.schedule_time.substr(0, 13);
      const year = date.substr(0, 4);
      const month = date.slice(5, 7);
      const day = date.slice(8, 10);

      return (
        <div key={id} className="dashboard--s__letters">
          <div className="dashboard--s__letters-header">
            <div className="dashboard--s__letters-header-title">
              {index.letter.title}
            </div>
            <div>
              {day}/{month}/{year}
            </div>
          </div>
          <div>{letter}...</div>
          <div className="dashboard--view_btn-container">
            <button
              className="dasboard--delete__btn"
              onClick={() => deleteScheduled(id)}
            >
              <svg
                className="dashboard-trash"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 5.98047C17.67 5.65047 14.32 5.48047 10.98 5.48047C9 5.48047 7.02 5.58047 5.04 5.78047L3 5.98047"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.8504 9.13965L18.2004 19.2096C18.0904 20.7796 18.0004 21.9996 15.2104 21.9996H8.79039C6.00039 21.9996 5.91039 20.7796 5.80039 19.2096L5.15039 9.13965"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.3301 16.5H13.6601"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.5 12.5H14.5"
                  stroke="#292D32"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
            <button onClick={() => sView(id)} className="dashboard--view_btn">
              View
            </button>
          </div>
        </div>
      );
    });
  }

  sentNum ? sentArray.reverse() : null;
  scheduledArray ? scheduledArray.reverse() : null;
  //   console.log(sentArray);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const sindexOfLastPost = scurrentPage * postsPerPage;
  const sindexOfFirstPost = sindexOfLastPost - postsPerPage;
  let currentPosts;
  let currentScheduled;

  const error = () => {
    return (
      <div className={`dashboard-error-${block}`}>
        <div className="dashboard-img">
          <img src={dash_img} />
        </div>
        <p className="dashboard-error-header">You haven&apos;t scheduled letters yet</p>
        <p className="dashboard-error-leading">
        Kindly generate letters and come back here!
        </p>
        <Link to="/survey">
          <button className="dashboard-error-btn">Generate Letter</button>
        </Link>
      </div>
    );
  };
  if (sentNum) {
    currentPosts = sentArray.slice(indexOfFirstPost, indexOfLastPost);
    // console.log(sent);
  } else if (!sentNum) {
    currentPosts = error();
  }

  if (scheduledNum) {
    currentScheduled = scheduledArray.slice(
      sindexOfFirstPost,
      sindexOfLastPost,
    );
  } else if (!scheduledNum) {
    currentScheduled = error();
  }

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      <Navbar />
      <div className="dashboard">
        <div className="dashboard--header">
          <div className="dashboard--header_1">
            <p className="dashboard--header_name">Hello {user},</p>
            <p className="dashboard--header_description">
              Letters that have made your loved one smile
            </p>
          </div>
          <div className="dashboard--header_2">
            {sent && (
              <Link to="/survey">
                <button className="dashboard--header__generate">
                  <img src="/add.svg" />
                  <span>Generate Letter</span>
                </button>
              </Link>
            )}
          </div>
        </div>
        {isLoading ? (
          <div className="h-[50vh] flex justify-center items-center">
            <Loader />
          </div>
        ) : (
          <div>
            <div className="dashboard-sc">
              <button
                className={`dashboard-sc__btn ${
                  onSent ? 'sc__btn--active' : ''
                }`}
                onClick={handleCompleted}
              >
                <p>Completed</p> <p className="dashboard-sc__span">{sentNum}</p>
              </button>
              <button
                className={`dashboard-sc__btn ${
                  onScheduled ? 'sc__btn--active' : ''
                }`}
                onClick={handleScheduled}
              >
                <p>Scheduled</p>{' '}
                <p className="dashboard-sc__span">{scheduledNum}</p>
              </button>
            </div>
            <section className={`dashboard--img-view-${block}`}>
              <div className="dashboard-sent__lead">
                <p>{onSent ? 'Sent Letter(s)' : 'Scheduled Letter(s)'}</p>
                <div className="dashboard--img-view">
                  <button
                    onClick={handleBlock}
                    className="dashboard--list-view"
                  >
                    {block == 'block' ? (
                      <img src="/red-list-view.svg" />
                    ) : (
                      <img src="/list-view.svg" />
                    )}
                  </button>
                  <button onClick={handleGrid} className="dashboard--grid-view">
                    {block == 'grid' ? (
                      <img src="/red-grid-view.svg" />
                    ) : (
                      <img src="/grid-view.svg" />
                    )}
                  </button>
                </div>
              </div>
              {onSent && (
                <div className={`dashboard--${block}`}>{currentPosts}</div>
              )}
              {onScheduled && (
                <div className={`dashboard--${block}`}>{currentScheduled}</div>
              )}
              {onSent && (
                <div>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={sent.length}
                    paginate={paginate}
                  />
                </div>
              )}
              {onScheduled && (
                <div>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts={scheduled.length}
                    paginate={paginate}
                  />
                </div>
              )}
            </section>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
}
