import React from 'react';
import { Link } from 'react-router-dom';
import FooterTwo from '../../components/FooterTwo';
import Navbar from '../../components/Navbar/Navbar';
import Web from '../../assets/world-help.png';
import FixProblem from '../../assets/fix-problem.png';
import FixPayment from '../../assets/fix-pay.png';
import LoginIsh from '../../assets/login-ish.png';
import Search from '../../assets/search-icon.png';
import '../../styles/help-center.css';

const HelpCenter = () => {
  return (
    <>
      <div>
        <Navbar />
        <section style={{ minHeight: '75vh' }} className="pb-12">
          <section className="help-center-header flex items-center">
            <div className="container mx-auto">
              <div className="font-avenir text-center w-11/12 sm:w-10/12 md:w-8/12 mx-auto">
                <h1 className="text-4xl md:text-5xl text-main-1 mb-3">
                  Help Center
                </h1>
                <div className="flex help-center-header-search mx-auto w-full md:w-4/5">
                  <input
                    type="text"
                    className="p-3 outline-none w-10/12 border-0 text-lg text-[#464646]"
                  />
                  <button className="bg-main-1 w-2/12">
                    <img src={Search} alt="" />
                  </button>
                </div>
              </div>
            </div>
          </section>
          <section className="help-center-body py-12 min-h-[50vh]">
            <div className="container mx-auto">
              <div className="help-center-body-title text-center py-5 pb-8 px-3 w-11/12">
                <h2 className="text-4xl md:text-5xl font-avenir_bold">
                  Getting Started
                </h2>
                <p className="text-xl font-avenir tracking-tight">
                  Learn how to navigate and use the app easier with our FAQs.
                </p>
              </div>

              <div className="help-center-body-content grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 font-avenir sm:w-4/5 md:w-full mx-auto w-11/12">
                <div className="min-h-[200px] p-4">
                  <img src={Web} className="mb-3" alt="" />

                  <h5 className="text-xl py-2 text-[#464646]">
                    Website Downtime
                  </h5>
                  <p className="text-base text-[#374957] py-2">
                    &quot;We understand it&apos;s frustrating LoveMe is down and
                    that it&apos;s hampering your internal communications.&quot;
                  </p>

                  <Link to="/help/website-downtime" className="text-main-1 underline">
                    Keep Reading
                  </Link>
                </div>

                <div className="min-h-[200px] p-4">
                  <img src={FixProblem} className="mb-3" alt="" />

                  <h5 className="text-xl py-2 text-[#464646]">
                    Fix Problem Connecting to LoveMe
                  </h5>
                  <p className="text-base text-[#374957] py-2">
                    If you can&apos;t send or receive Love letters or have
                    trouble connecting to LoveMe
                  </p>

                  <Link
                    to="/help/fix-problem-connecting-to-loveme"
                    className="text-main-1 underline"
                  >
                    Keep Reading
                  </Link>
                </div>

                <div className="min-h-[200px] p-4">
                  <img src={FixPayment} className="mb-3" alt="" />

                  <h5 className="text-xl py-2 text-[#464646]">
                    Fix Payment and Pending Transaction Issues
                  </h5>
                  <p className="text-base text-[#374957] py-2">
                    We understand that sometimes transactions can be delayed due
                    to network issues, timezone
                  </p>

                  <Link
                    to="/help/fixing-payment-and-pending-transaction-issues"
                    className="text-main-1 underline"
                  >
                    Keep Reading
                  </Link>
                </div>

                <div className="min-h-[200px] p-4">
                  <img src={LoginIsh} className="mb-3" alt="" />

                  <h5 className="text-xl py-2 text-[#464646]">
                    Unable to Login/Signup
                  </h5>
                  <p className="text-base text-[#374957] py-2">
                    Failing login can have several causes. Maybe you have a
                    typo, changed your password recently
                  </p>

                  <Link
                    to="/help/unable-to-login-or-signup"
                    className="text-main-1 underline"
                  >
                    Keep Reading
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </section>
        <FooterTwo />
      </div>
    </>
  );
};

export default HelpCenter;
