import React from 'react';
import InputField from '../../components/InputField/InputField';
import Button from '../../components/Button/Button';
import PropTypes from 'prop-types';

// FlutterWave Payment Component
const FlutterWave = ({ handlePayment }) => {
  return (
    <form onSubmit={handlePayment}>
      <div className="mt-6 mb-6 flex items-stretch flex-col lg:flex-row gap-6 justify-between">
        <div className="text-gray-700 ">
          <label htmlFor="first-name">First Name</label>
          <InputField
            name={'first-name'}
            type={'text'}
            placeholder={'First Name'}
          />
        </div>

        <div className="text-gray-700 ">
          <label htmlFor="last-name">Last Name</label>
          <InputField
            name={'last-name'}
            type={'text'}
            placeholder={'Last Name'}
          />
        </div>
      </div>

      <div className="text-gray-700 mt-6">
        <label htmlFor="email">Email Address</label>
        <InputField
          name={'email'}
          type={'email'}
          placeholder={'Email Address'}
        />
      </div>

      <div className="text-gray-700 mt-6 mb-6">
        <label htmlFor="amount">Amount</label>
        <InputField name={'amount'} type={'text'} value={'NGN 5000.00'} />
        <p className="text-sm font-avenir_light text-main-1 mt-2">
          This is a fixed amount and real debit
        </p>
      </div>

      <Button name={'Make Payment'} height={'40px'} />
    </form>
  );
};

FlutterWave.propTypes = {
  handlePayment: PropTypes.func.isRequired,
};

export default FlutterWave;
