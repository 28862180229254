import axios from 'axios';
const apiURL = process.env.REACT_APP_API_BASE_URL
axios.defaults.baseURL = `${apiURL}/api/v1/`;

let refresh = false;

axios.interceptors.response.use(
  (resp) => resp,
  async (error) => {
    if (error.response?.status === 401 && !refresh) {
      refresh = true;
      const response = await axios.post('auth/refresh-token', {
        refresh_token: localStorage.getItem('refresh_token'),
      });

      if (response.status === 200) {
        localStorage.setItem('token', response.data.access_token);
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${localStorage.getItem('token')}`;

        return axios(error.config);
      }
    }
    refresh = false;
    return error;
  },
);
