import React from 'react';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import '../../styles/careerpage.css';
import Data from '../../data/careerpage';
import Navbar from '../../components/Navbar/Navbar';
import FooterTwo from '../../components/FooterTwo';

const PositionDetails = () => {
  const { id } = useParams();

  const jobDesc = Data.filter((item) => item.id === Number(id));
  const job = jobDesc[0];

  return (
    <>
      <Navbar />
      <section className="bg-sec-1 text-white w-full py-4 mt-24">
        <div className="max-w-6xl m-auto">
          <Link to="/career" className="flex gap-2 items-center text-white">
            <MdOutlineKeyboardArrowLeft className="h-6 w-6" />
            See all positions
          </Link>
        </div>
      </section>

      <section className="max-w-6xl m-auto p-5">
        <h1 className="text-xl md:text-3xl text-sec-1 mt-10 mb-2">
          {job.title}
        </h1>

        <div className="flex gap-5 mt-3">
          <p className="text-lg">fulltime</p>
          <p className="text-lg">remote</p>
        </div>
        <h1 className="text-xl md:text-3xl text-sec-1 my-6">
          We are interested in meeting you
        </h1>
        <p className="text-base md:text-xl">
          We appreciate you showing interest in LoveMe. Please complete the
          short form below. Please email us at jobs@loveme.com if you experience
          any problems uploading your data.
        </p>

        <div className="p-5 mt-5">
          <p className="mb-3 text-lg md:text-2xl">
            To achieve this, you&apos;ll be expected to:{' '}
          </p>
          <ol className="list-style text-[#464646] text-base md:text-xl">
            {job.description.map((item, index) => {
              return <li className="" key={index}>{item}</li>
            })}
          </ol>
        </div>

        <div className="p-5 mt-5">
          <p className="mb-3 text-xl md:text-2xl">
            You Might Be A Good Fit If You Have:
          </p>
          <ol className="list-style text-[#464646] text-base md:text-xl">
            {job.requirements.map((item, index) => {
              return <li className="" key={index}>{item}</li>
            })}
          </ol>
        </div>

        <div className="mt-5 p-5">
          <p className="mb-3 text-xl md:text-2xl">Benefit: </p>
          <ul className="list-none text-[#464646] text-base md:text-xl">
            {job.benefits.map((item, index) => {
              return <li className="" key={index}>{item}</li>
            })}
          </ul>
        </div>
      </section>

      <Link to="/role-application">
        <button
          type="submit"
          className="bg-main-1 border-none text-white flex justify-center m-auto px-6 py-3 rounded-lg mt-16 mb-16"
        >
          Apply for the Position
        </button>
      </Link>
      <FooterTwo />
    </>
  );
};

export default PositionDetails;
