let careerpage = [
  {
    id: 1,
    title: 'Human Resources Manager',
    description: [
      'Listening to and finding resolutions for employee conflicts',
      'Creating and implementing training sessions for employee conduct',
      'Overseeing the employee recruitment and selection process Creating the onboarding process for new hires',
    ],
    requirements: [
      ' 3 years experience as a human resource manager',
      'Leadership skills',
      'Proficiency in office software and other software suites related to human resources',
      'such as Microsoft Office and Human Resources Information Systems (HRIS) including PeopleSoft',
      'Knowledge of compensation strategy, performance management, employee relations, safety practices and talent acquisition — and the ability to apply this information so that it’s compliant with the relevant employment laws',
    ],
    benefits: ['Competitive salary', 'MacBook Pro'],
    is_available: 'true',
  },
  {
    id: 2,
    title: 'Social media manager',
    description: [
      'Develop social media strategies with the aim of increasing brand awareness and customer engagement',
      "Analysing the long-term needs of the company's social media strategy and offering quarterly reports to the management and executive teams",
      'outlining any necessary changes to the overall digital marketing plan',
      'Developing monthly reports on emerging social media trends that will be submitted to the management and executive team',
      'Developing and overseeing competitions and campaigns to draw attention to a brand',
    ],
    requirements: [
      '2+ years of experience as a social media manager across various social media platforms',
      'Great communication skills in order to liaise with multiple stakeholders, creatives and departments',
      'Good networking skills to form relationships with influencers and other key players',
      'An ability to multitask and perform under pressure',
      'A sound understanding of SEO, web traffic metrics and keyword search',
    ],
    benefits: ['Competitive salary', 'MacBook Pro'],
    is_available: 'true',
  },
  {
    id: 3,
    title: 'Graphic designer',
    description: [
      'Create and design various materials for print and digital collateral',
      'Ensure projects are completed with high quality and on schedule',
      'Establish creative direction for Love Me as well as brand guidelines',
      'Prioritize and manage multiple projects within design specifications and budget restrictions',
      'Perform retouching and manipulation of images',
    ],
    requirements: [
      '3 to 5+ years of experience as a graphics designer',
      'Ability to work as part of a team and independently',
      'Artistic skills',
      'Proficiency in graphic illustration software',
      'Maintain a portfolio of prior work and maintain a client archive',
    ],
    benefits: ['Competitive salary', 'MacBook Pro'],
    is_available: 'true',
  },
  {
    id: 4,
    title: 'Customer service rep',
    description: [
      'Interact with customers to resolve issues and questions about products, services, and policies',
      'Maintain a positive and friendly tone with customers at all times',
      'Recruit potential customers by recommending goods or services and demonstrating how they benefit the customer personally',
      'Establish new customer accounts and record account information, like phone numbers or addresses, on your digital platform',
      'Listen to customer complaints and try to identify the cause of their problem to your best ability',
      'Ensure customer satisfaction and maintain professional customer support',
      'Place the customer at the center of the customer service experience',
    ],
    requirements: [
      '2 - 3  years experience working as a customer service rep',
      'Proven call center or customer service experience preferred',
      'Strong soft skills and communication skills',
      'Flexibility to work multiple shifts, including during the weekends or on holidays',
      'Must be willing to take part in required initial training',
      'Willingness to take and ability to pass background check and drug screening',
    ],
    benefits: ['Competitive salary', 'MacBook Pro'],
    is_available: 'true',
  },
];

export default careerpage;
