import '../../styles/Freetrial.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function FreetrialModal({ toggleModal }) {
  return (
    <div className="survey-modal">
      <div className="survey-overlay" onClick={toggleModal}></div>
      <div className="free-trial">
        <img className="freetrial-img" src="/rafiki.svg" />
        <div>
          <p className="free--leading">Your free trial has expired</p>
          <p className="free--subtext">
            But it’s not too late to take the next move. Upgrade to a paid plan
            to keep using our product.
          </p>
          <Link to="/pricing" className="upgrade-freetrial">
            <button>Upgrade Now</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

FreetrialModal.propTypes = {
  toggleModal: PropTypes.func,
};
