import axios from 'axios';

const handleFetch = async ({
  endpoint = '',
  method = 'GET',
  body = null,
  pQuery = null,
  param = null,
  multipart = false,
} = {}) => {
  const headers = {
    'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
  };
  let url = endpoint;
  if (param) {
    url += `/${param}`;
  }
  if (pQuery) {
    let paramsArray = Object.keys(pQuery).map(
      (key) =>
        pQuery[key] &&
        `${encodeURIComponent(key)}=${encodeURIComponent(pQuery[key])}`,
    );
    paramsArray = paramsArray.filter((item) => item);
    url += `?${paramsArray.join('&')}`;
  }
  const options = {
    url,
    method,
    headers,
  };
  if (body) {
    options.data = body;
  }
  return axios(options)
    .then((response) => response)
    .catch((error) => error);
};
export default handleFetch;
