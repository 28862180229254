import React from 'react';
import Button from './Button/Button';
import styles from './css/PriceComponent.module.css';
import { useNavigate } from 'react-router-dom';

const PriceComponent = (props) => {
  const navigate = useNavigate();
  const obj = props;
  const { heading, price, duration, desc, id, i } = obj;
  return (
    <div className={styles.ParentContainer}>
      {
        i === 1 ? <div id={`banner${i}`}className={styles.banner}>
        <p>Recommended</p>
      </div> : null
      }
      <div className={styles.Container}>
        <h2 className={styles.heading}>{heading}</h2>
        <p>
          <b>{price}</b>
          <span>{duration}</span>
        </p>
        <ul>
          {desc.map((list, i) => (
            <li key={`key-${i}`}>{list}</li>
          ))}
        </ul>
        <Button
          name={'Subscribe'}
          height={'40px'}
          width={'75%'}
          type={'button'}
          onClick={() => navigate(`/checkout?id=${id}`)}
        />
      </div>
    </div>
  );
};

export default PriceComponent;
