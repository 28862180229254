//import errorIcon from '../assets/error.svg';
export default function SuccessPopUp(prop) {
  const { success, successMsg } = prop;


  return (
    <div
      className={`fixed top-[5%] z-[100] ${
        success ? 'left-0' : 'left-[-100%]'
      } flex   w-full justify-center transition-all duration-300`}
    >
      <div className="flex gap-[10px] bg-white p-[15px] shadow-xl rounded-[8px]">
        <p className="text-[18px] capitalize text-[green] text-center">{successMsg}</p>
      </div>
    </div>
  );
}
