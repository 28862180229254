import React from 'react';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessful = () => {
  const navigate = useNavigate();
  return (
    <main className="flex justify-center items-center h-screen w-full">
      <div className="shadow-lg rounded-xl text-center px-4 max-w-5xl mx-auto flex flex-col justify-center items-center gap-10 pb-14">
        <img alt="payment succesful" src="./checkout-img/done_all.svg" />
        <h2 className="text-lg font-avenir_bold">Payment Successful</h2>
        <p>Your payment is successful! You can now continue using LoveMe</p>
        <Button
          onClick={() => navigate('/survey')}
          name={'Proceed to Generator'}
          height={'40px'}
          width={'300px'}
        />
      </div>
    </main>
  );
};

export default PaymentSuccessful;
