



export const refreshToken = () => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/refresh-token`, {
        method: "POST",
        body: {
            refresh_token : localStorage.getItem("adminrefresh_token")
        }
        }).then(res => res.json())
        .then(data => {
            if(data.detail === "invalid refresh token or token has expired"){
                localStorage.removeItem("admin")
                localStorage.removeItem("adminrefresh_token")
                localStorage.removeItem("admintoken")
                return window.location.pathname = "/adminsignin"
            }
        })
}

