export const background = [
  'These terms and conditions (“Terms”) govern your use of the Website.',
  'By using the Website or clicking on an “Accept” (or similar) button, you agree to be legally bound by these Terms.',
  'If you do not accept these Terms, you are not permitted to and must not use the Website.',
  'Any new features or tools which are added to the Website shall also be subject to these Terms.',
];

export const definitions = [
  'Unless expressly provided otherwise, in these Terms:',
  '“Account” means an account created on the Website where the User subscribes to a LoveMe membership.',
  '“Account Details” means a username, password and any other details provided by LoveMe to:',
  '(a) a User for the purpose of managing their Account; or',
  '(b) a Site Administrator for the purpose of administering a Site.',
  '“Content” means the content or materials including (but not limited to) still or moving images, videos, sound recordings or other audiovisual materials, artistic works, written works and personal information created or provided by a User for the purpose of a Letter or Public Letter.',
  '“Fee” means any one-off fee that may be charged by LoveMe from time to time.',
  '“LoveMe”, “us”, or “we” means LoveMe its subsidiaries and its subsidiaries’ directors, officers, employees, agents and advisors.',
  '“Intellectual Property Rights” means patents, business method patents, rights in inventions, trade secrets, copyrights, mask work rights, moral rights, trade names (including derivatives thereof), trademarks, logos, service marks, images, photographs, slogans, trade styles, trade dress, and graphics, whether or not registered or otherwise legally determined to be owned, rights in trade dress, technical know-how, software, specifications, processes, designs, trade secrets, publicity rights, privacy rights, and all other intellectual property rights and proprietary rights worldwide, whether arising under the laws of the United States or any other state, country or jurisdiction, including all rights or causes of action for infringement, violation or misappropriation of any of the foregoing. For clarity, our Intellectual Property Rights include all rights, titles, and interests in and to the Website, the Services, and all derivatives thereof; the templates, tools, processes, data, data analytics, methods, and other proprietary means we use to create, develop or deliver the Website and/or the Services; and all names and marks that reference or include “LoveMe” and any name of our products or services.',
  '“Letter” means the Content that a User sends via the Website to an email address specified by the User or Site Administrator after some time or at a date specified by the User or Site Administrator.',
  '“Payment Method” means the method by which you choose to pay the relevant Fee.',
  '“Platform” means the App is a page that at you have access to that allows you to carry out activities on the website.',
  '“Privacy Policy” means the privacy policy of LoveMe(as amended from time to time), the current version of which may be accessed at the following link:',
  '“Purpose” means the creation of an Account or use of the Service, as permitted under these Terms.',
  '“Service” means the service provided by us which allows:',
  '(a) a User that is not a Guest User to generate Letters and send Letters;',
  '(b) a User to create and share a Site as a Site Administrator; or',
  '(c) a Guest User to access a Site and send Letters.',
  '“Site” means the customizable and shareable page created by a Site Administrator.',
  '“Site Administrator” means a User that has signed up on the LoveMe website.',
  '“Subscription Fee” is the money charged for the letter generated for a user.',
  '“Terms” means these terms and conditions of service.',
  '“User” means a user of the Service, being either:',
  '(a) a holder of an Account;',
  '(b) a Site Administrator that has created a Site; or',
  '(d) a Guest User.',
  '“Website” means LoveMe and includes any version of the Service (including any “app” or a similar version) that can be viewed or accessed on any type of device including but not limited to tablet devices, and unless the context requires otherwise, includes all related services.',
  '“Website Material” refers to materials such as audiovisual, logos, images, photos, videos, etc as may be found on the website.',
  '“you” and “your” means the person reading these Terms or such other terms or policies adopted by these Terms.',
];

export const accounts = [
  'To use the Service, a User must create an Account and subscribe to a LoveMe Membership. To o create an Account, you must provide your:',
  '(a) email address; and',
  '(b) a password.',
  'You may also create an Account and log in to the Website using single sign-on services such as Google. These services will allow the third party to authenticate your identity and provide you the option to share certain personal information with us such as your name and email address to pre-populate our sign-up form which may be collected, stored, and used by LoveMe by our Privacy Policy.',
  'You agree to provide current, complete, and accurate details relating to your Account, subscription, and Payment Method (as applicable), and to promptly update such information, including your email address and credit card numbers, and expiration dates, so that we may complete your transactions and contact you as needed.',
  'You may choose to delete the Site at any time. LoveMe may permit two or more individuals to act as Site Administrator on each Site, with each having equivalent rights in respect of providing access to Guest Users and approval and removal of Content.',
  'A Site Administrator cannot be transferred to another person other than by these Terms.',
  'The Site Administrator must:',
  '(a) be at least 18 years of age;',
  '(b) not be a person that LoveMe has prohibited from using the Service; and',
  '(c) if the Site is to be shared with a User who is under the age of 13, be a teacher, parent, or guardian of that User.',
];

export const subscription = [
  'You may subscribe to LoveMe via the Website by signing up for a subscription plan displayed on the Website (“Subscription Plan”).',
  'When you sign up for a Subscription Plan, you will be asked to provide details relating to your Payment Method that is accepted by us.',
  'You acknowledge and agree that, in respect of a paid Subscription Plan, an annual subscription fee applies (“Annual Subscription Fee”). The applicable Annual Subscription Fee will become payable on the date that you sign up for the Subscription Plan (“Initial Subscription Fee”) and will be payable annually until the termination of your Subscription Plan (“Subscription Fee Period”).',
  'Before you may share your Site with Users you must have paid the Initial Subscription Fee.',
  'By signing up for a paid Subscription Plan and providing us with your Payment Method, you authorize us to charge your Payment Method the Subscription Fee for your chosen Subscription Plan for each Subscription Fee Period by these Terms.',
  'You agree to pay and will be responsible for all charges associated with your Payment Method, and any taxes and other surcharges or third-party fees that may be applicable.',
  'If you choose to pay via a third-party biller, your Subscription Fee will be charged by that third party and may be subject to additional third-party billing terms which will be notified to you by the third-party biller.',
  'We may increase your Annual Subscription Fee at any time by giving at least 21 days’ notice to you. We will provide this notice to you by email to the email address linked to your account. Any increase in the Annual Subscription Fee will be effective on your next Subscription Fee Period payment date. If you do not agree to any increase in your Annual Subscription Fee, you may cancel your Subscription Plan through your account.',
  'We may also increase your Annual Subscription Fee without notice if required by law, or if any regulatory authority requests or requires a change to any part of our pricing which directly affects your Annual Subscription Fee or our pricing structure. If this happens, we will use our best efforts to give you reasonable notice. If you do not agree to that increase in your Annual Subscription Fee, you may cancel your Subscription Plan through your Account.',
  'You may cancel your Subscription Plan through your Account at any time. The cancellation of your Subscription Plan will be effective at the end of that Subscription Fee Period, and your Subscription Plan will be discontinued from this date.',
  'You will only receive a pro-rata refund of any fees or other amounts already paid to us if:',
  '(d) you cancel as a result of us changing these Terms or our Privacy Policy in a way that you do not agree with, in which case, you must notify us within 14 days of our notice to you about that change, and your cancellation will be effective as of the date of the change to these Terms or our Privacy Policy; or',
  '(e) you cancel as a result of an increased Subscription underarms, in which case, you will be entitled to a pro-rata refund of any amounts paid by you of the higher Subscription Fee, and your cancellation will be effective immediately.',
];
