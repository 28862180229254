import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import SurveyPage from './pages/SurveyPage/SurveyPage';
import PricingPage from './pages/PricingPage';
import Patnership from './pages/Patnership/Patnership';
import TermsOfService from './pages/TermsOfService';
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
import LandingPage from './pages/LandingPage/LandingPage';
import PreviewLetter from './pages/PreviewLetter/PreviewLetter';
import CookiePolicy from './pages/CookiePolicy';
import CareerPage from './pages/CareerScreens/CareerPage';
import PositionDetails from './pages/CareerScreens/PositionDetails';
import RoleApplication from './pages/CareerScreens/RoleApplication';
import ApiDocs from './pages/ApiDocs';
import Uptime from './pages/Uptime';
import Incidents from './pages/Incidents';
import ContactPage from './pages/ContactPage';
import ErrorPage from './pages/ErrorPage';
import Sitemap from './pages/SiteMap/SiteMap';
import Faqpage from './pages/Faqpage';
import AboutPage from './pages/AboutPage';
import HowItWorks from './pages/HowItWorks';
import ProfilePage from './pages/ProfilePage';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiesPopup from './components/CookiesPopup';
import { ModalContent } from './components/newsleeter/newslettercontent';
import { NewsLetterModal } from './components/newsleeter/newslettermodal';
import ResetPasswordPage from './pages/ResetPasswordPage';
import Checkout from './pages/Payment/Checkout';
import PaymentSuccessful from './pages/Payment/PaymentSuccessful';
import PaymentCancelled from './pages/Payment/PaymentFailure';
import Dashboard from './pages/Dashboard';
import { useAuthContext } from './hooks/useAuthContext';
import useLocalStorage from 'use-local-storage';
// import Receiver from './pages/Receiver';
import { BsToggles2 } from 'react-icons/bs';
import '../src/styles/LandingPage.css';
import Testimonials from './pages/Testimonials/Testimonials';
import FreeTrialPage from './components/FreeTrialPage/FreeTrialPage';
import Scheduled from './pages/PreviewLetter/Scheduled';
import Completed from './pages/PreviewLetter/Completed';
import HelpCenter from './pages/HelpCenter/HelpCenter';
import WebsiteDowntime from './pages/HelpCenter/WebsiteDowntime';
import FixProblems from './pages/HelpCenter/FixProblems';
import FixPayment from './pages/HelpCenter/FixPayment';
import AuthIssue from './pages/HelpCenter/AuthIssue';
import { CreateNewPassword } from './pages/CreateNewPassword';
import AddAdminPage from './pages/AddAdminPage';
import AdminSignUp from './pages/AdminPage/AdminSignUp';
import AdminSignIn from './pages/AdminPage/AdminSignIn';
import AdminDashboard from './pages/admindashboard/AdminDashboard';
import AdminResetPassword from './pages/AdminPage/AdminResetPassword';
import AdminCreateNewPassword from './pages/AdminPage/AdminCreateNewPassword';
import { AdminRouter } from './pages/admindashboard/admincomponents/adminrouter';
import VerifyEmail from './pages/VerifyEmail';
import VerifyEmailToken from './pages/VerifyEmailToken';

function App() {
  const { user } = useAuthContext();

  const [open, isOpen] = useState(false);
  const [theme, setTheme] = useLocalStorage('theme', 'light');

  const switchTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
  };

  return (
    <div className="App relative" data-theme={theme}>
      <div className="p-3  justify-end absolute right-0 hidden">
        <div className="theme-toggle rounded-xl p-2 ">
          <BsToggles2 className="icon" onClick={switchTheme} />
        </div>
      </div>

      <>
        <NewsLetterModal isOpen={isOpen} open={open}>
          <ModalContent isOpen={isOpen} open={open} />
        </NewsLetterModal>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/contactus" element={<ContactPage />} />
          {
            <Route
              exact
              path="/survey"
              element={user ? <SurveyPage /> : <SignIn />}
            />
          }
          <Route exact path="/pricing" element={<PricingPage />} />
          <Route
            exact
            path="/pricing"
            element={user ? <PricingPage /> : <SignIn />}
          />
          {/* <Route exact path="/survey" element={<Receiver />} /> */}
          <Route exact path="/partnerships" element={<Patnership />} />
          <Route exact path="/termsofservice" element={<TermsOfService />} />
          <Route
            exact
            path="/signin"
            element={!user ? <SignIn /> : <Navigate to="/dashboard" />}
          />
          <Route exact path="/signup" element={<SignUp />} />
          <Route
            exact
            path="/previewletter"
            element={user ? <PreviewLetter /> : <SignIn />}
          />
          <Route path="/career" exact element={<CareerPage />} />
          <Route exact path="/cookie-policy" element={<CookiePolicy />} />
          <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route exact path="/how-it-works" element={<HowItWorks />} />
          <Route
            path="position-details/:id"
            exact
            element={<PositionDetails />}
          />
          <Route path="/role-application" exact element={<RoleApplication />} />
          <Route path="/apidocs" exact element={<ApiDocs />} />
          <Route path="/uptime" exact element={<Uptime />} />
          <Route path="/incidents" exact element={<Incidents />} />
          <Route exact path="*" element={<ErrorPage />} />
          <Route exact path="/sitemap" element={<Sitemap />} />
          <Route exact path="/faq" element={<Faqpage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route
            exact
            path="/profile"
            element={user ? <ProfilePage /> : <Navigate to="/" />}
          />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            exact
            path="/create-new-password"
            element={<CreateNewPassword />}
          />
          <Route
            exact
            path="/dashboard"
            element={user ? <Dashboard /> : <SignIn />}
          />
          <Route path="/checkout" element={user ? <Checkout /> : <SignIn />} />
          <Route
            path="/payment-successful"
            element={user ? <PaymentSuccessful /> : <SignIn />}
          />
          <Route
            path="/payment-cancelled"
            element={user ? <PaymentCancelled /> : <SignIn />}
          />
          <Route
            path="/pastScheduled"
            element={user ? <Scheduled /> : <SignIn />}
          />
          <Route
            path="/pastCompleted"
            element={user ? <Completed /> : <SignIn />}
          />
          <Route path="/free_trial" element={<FreeTrialPage />} />
          <Route exact path="/testimonials" element={<Testimonials />} />
          <Route path="/help-center" element={<HelpCenter />} />
          <Route path="/help/website-downtime" element={<WebsiteDowntime />} />
          <Route
            path="/help/fix-problem-connecting-to-loveme"
            element={<FixProblems />}
          />
          <Route
            path="/help/fixing-payment-and-pending-transaction-issues"
            element={<FixPayment />}
          />
          <Route
            path="/help/unable-to-login-or-signup"
            element={<AuthIssue />}
          />
          <Route
            path="/add-admin"
            element={
              <AdminRouter>
                <AddAdminPage />
              </AdminRouter>
            }
          />
          <Route
            exact
            path="/admindashboard"
            element={
              <AdminRouter>
                <AdminDashboard />
              </AdminRouter>
            }
          />
          <Route
            exact
            path="/adminsignup"
            element={
              <AdminRouter>
                <AdminSignUp />
              </AdminRouter>
            }
          />
          <Route
            exact
            path="/adminsignin"
            element={
              <AdminRouter>
                <AdminSignIn />
              </AdminRouter>
            }
          />
          <Route
            exact
            path="/admin-reset-password"
            element={<AdminResetPassword />}
          />
          <Route
            exact
            path="/admin-create-new-password"
            element={<AdminCreateNewPassword />}
          />
          <Route exact path="/verify" element={<VerifyEmail />} />
          <Route path="/verify-email" element={<VerifyEmailToken />} />
        </Routes>

        <CookiesPopup />
      </>
    </div>
  );
}
export default App;
