import React from 'react';
import root from '../assets/root.jpg';
import raga from '../assets/raga.jpg';
import jade from '../assets/jade.jpg';
import felix from '../assets/felix.jpg';
import eti from '../assets/eti.jpg';
import devopslead from '../assets/devopslead.jpg';
import '../styles/About.css';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button/Button';
import Navbar from '../components/Navbar/Navbar';
import FooterTwo from '../components/FooterTwo';
import { SubscribeButton } from '../components/subscribebtn';
import SuccessPopUp from '../components/successpopup';
import ErrorPopUp from '../components/ErrorPopUp';

const data = [
  {
    id: 1,
    img: root,
    name: 'Root Wranju',
    role: 'Team Lead(PM)',
  },
  {
    id: 2,
    img: felix,
    name: 'Peter Felix',
    role: 'Lead Design',
  },

  {
    id: 3,
    img: raga,
    name: 'Raga Williams',
    role: 'Lead FrontEnd Development',
  },

  {
    id: 4,
    img: eti,
    name: 'Eti Umoh',
    role: 'Lead BackEnd Development',
  },
  {
    id: 5,
    img: devopslead,
    name: 'Idimmachukwu Okoro',
    role: 'Lead DevOps',
  },
  {
    id: 6,
    img: jade,
    name: 'Soyele Olamide Jadesola',
    role: 'Lead Sales and Marketing',
  },

];
const AboutPage = () => {

  const [ email, setEmail] = React.useState("")
  const [ success, setSuccess] = React.useState(false)
  const [ error, setError] = React.useState(false)
  const [ successMsg, setSuccessMsg] = React.useState("")
  const [ errrormsg, setErrorMsg] = React.useState("")
  const navigate = useNavigate();

  console.log(error, success, errrormsg, successMsg)

 

  React.useEffect(() => {

    const timer = setTimeout(() => {
      if(error){
        setError(false)
        setErrorMsg("")
      }
      if(success){
        setSuccess(false)
        setSuccessMsg("")
      }
        
    }, 2000)

    return () => {
      clearTimeout(timer)
    }

  }, [error, success])


  return (
    <div>
      <Navbar />
      <SuccessPopUp success={success} successMsg={successMsg}  />
      <ErrorPopUp error={error} message={errrormsg}  />
      <section className="about-section-one flex flex-col py-16 px-10 lg:px-[90px] justify-center item-center md:items-start gap-7" >
        <h1 className="mt-10 text-3xl  md:text-4xl md:leading-10 " >
          About Us
        </h1>
        <p className="text-base md:text-xl md:leading-7">
          In order to fulfill our purpose of spreading love and joy to millions
          of people, we will need to develop not only incredible experiences but
          also the people, groups, and environments necessary to make them
          happen. We are very aware of our people, values, and culture.
        </p>

        <div className="about-button md:block ">
          <a href="#team" className='text-white h-[40px] w-[150px] bg-[var(--main-1)] block text-center rounded-[5px] py-2 font-bold hover:bg-transparent border-[var(--main-1)] border-[1px] border-solid hover:text-[var(--main-1)]'>Learn More</a>
        </div>
      </section>

      <section className="about-section-two py-10 px-10   h-fit lg:flex lg:justify-between lg:items-center lg:gap-10 lg:py-28 lg:px-[90px]">
        <div className="">
          <h1 className="font-bold text-4xl mb-3 text-[#D2120F]">
            Core Values
          </h1>
          <img src="/underline.svg" alt="" />
        </div>

        <div className="core-values-section flex flex-col justify-center gap-10 py-8  md:gap-6">
          <div>
            <div className="flex gap-10 items-center lg:-mt-20">
              <h1 className="">Assume the best</h1>
              <img src="/Sketch-annotation--stroke-icon--pin.svg" alt="" />
            </div>
            <p className="py-3 font-normal text-base leading-6 md:text-xl md:leading-7">
              We always have the tendency to think the best of the people we
              deal with. That holds true for all client and team interactions,
              no matter how small the piece of feedback or how large the
              request. We communicate instead of making assumptions.
            </p>
          </div>
          <div>
            <div className="flex gap-10 items-center">
              <h1>Excellence</h1>
              <img src="/Sketch-annotation--stroke-icon--pin.svg" alt="" />
            </div>
            <p className="py-3 font-normal text-base leading-6 md:text-xl md:leading-7">
              We are passionate about what we do, so we always give it our all,
              stopping only when we are certain that it is flawless.
            </p>
          </div>
        </div>
        <div>
          <div>
            <div className="flex gap-10 items-center">
              <h1>Empathy</h1>
              <img src="/Sketch-annotation--stroke-icon--pin.svg" alt="" />
            </div>
            <p className="py-3 font-normal text-base leading-6 md:text-xl md:leading-7">
              Not only do we value different viewpoints, but we also make an
              effort to comprehend them. And we do it from a place of openness
              and humility, with a sincere desire to meet them where they are.
            </p>
          </div>
          <div className="core-values-section flex flex-col justify-center gap-10 py-8 md:gap-0">
            <div className="flex gap-10 items-center">
              <h1>Team Spirit</h1>
              <img src="/Sketch-annotation--stroke-icon--pin.svg" alt="" />
            </div>
            <p className="py-3 font-normal text-base leading-6 md:text-xl md:leading-7">
              Our work is the result of both individual effort and meaningful
              teamwork, driven by our deep admiration for one another and our
              business. We are open-minded. We work for the teams welfare rather
              than our own personal gain.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section-three  md:flex flex-col py-10 px-[5%] lg:px-[90px]">
        <div className="md:flex md:flex-row-reverse md:justify-around md:items-center md:gap-10">
          <div className="">
            <img
              src="/storyimage.svg"
              alt=""
              className="w-80 md:w-96 xl:w-auto mx-auto flex lg:mt-20 object-contain "
            />
          </div>
          <div>
            <div className="py-7">
              <h1 className="font-bold mt-6 text-3xl md:text-4xl mb-3  text-[#fff]">
                Our Story
              </h1>
              <img src="/underlinewhite.svg" alt="" />
            </div>
            <p className="py- text-[#fff] font-normal text-base md:text-xl leading-7 md:leading-8 gap-2 lg:w-10/12">
              LoveMe is an organization under <a href="https://workshopapps.xyz">WorkshopApps</a>, with an interesting
              history and the ability to meet people’s needs for last longing
              love. It all started in the year 2022 when a group of friends
              decided to create a website where people could generate love
              letters. The idea was to use artificial intelligence to generate
              letters that sounded like they were written by a real person. Our
              company has come a long way since then.
            </p>
          </div>
        </div>

        <div className="py-16 lg:py-20 md:flex  md:justify-between md:items-center md:gap-16 lg:gap-32 ">
          <div className="couple-image ">
            <img src="/coupleimage1.svg" alt="" className=" mx-auto flex" />
            <img src="/coupleimage2.png" alt="" className=" mx-auto flex" />
            <img src="/coupleimage3.png" alt="" className=" mx-auto flex" />
            <img src="/coupleimage4.png" alt="" className=" mx-auto flex" />
          </div>
          <div>
            <div className="py-7">
              <h1 className="font-bold text-3xl md:text-4xl mb-3 text-[#fff]">
                Where we Are
              </h1>
              <img src="/underlinewhite.svg" alt="" />
            </div>
            <p className="py- text-[#fff] font-normal text-lg md:text-xl leading-7 lg:w-10/12 md:leading-8">
              Today, LoveMe is one of the most popular websites in the world for
              generating love lettters. We have helped millions of people
              express their love for each other. we are located in Nigeria, but
              our team are from all over the world. we are a team of people who
              are passionate about their work and we love helping people express
              their love for each other in a unique way that will always trigger
              your love for your lover.
            </p>
          </div>
        </div>
      </section>

      <section className="about-section-four flex flex-col justify-center items-center py-20 px-10 lg:flex-row md:justify-between lg:px-[90px]" id='team'>
        <div className="lg:w-1/2">
          <h1 className="text-4xl leading-10 font-extrabold md:text-5xl py-4" id="team">
            Our Team
          </h1>
          <p className="w-[95%] font-normal text-xl leading-7 py-4 lg:w-[80%]">
            Our creative and multicultural staff collaborates for a single goal
            to help those around us reach their full potential.
          </p>
          <div className="about-button hidden md:block rounded-lg">
            <Button
              height="40px"
              width="150px"
              name="Join Us"
              onClick={() => {
                navigate('/career');
              }}
            />
          </div>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mt-[45px] mb-[30px]  w-full lg:w-[55%]">
          {data &&
            data.map((d) => (
              <div key={d.id} className="relative">
                <img
                  className="w-full rounded-xl h-[250px]"
                  src={d.img}
                />
                <div className="bg-black w-full bg-opacity-30 absolute bottom-0 left-0 backdrop-blur-[6px] p-[10px] border-[1px] text-[#fff] text-left text-sm mb-[6px] rounded-xl">
                  <div className="z-60">
                    <h3 className="text-[16px] ">{d.name}</h3>
                    <p className="text-[12px] ">{d.role}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="about-button md:hidden rounded-lg">
          <Button
            height="40px"
            width="100px"
            name="Join Us"
            onClick={() => {
              navigate('/career');
            }}
          />
        </div>
      </section>

      <section className="about-section-five flex flex-col items-center justify-center gap-9 text-center p-20 relative">
        <div>
        <h2 className=" text-3xl md:text-4xl text-main-7  w-screen">
          Subscribe to Our NewsLetter
        </h2>
        <p className="font-thin text-main-7 w-screen p-3 text-xl md:text-2xl -mt-3">
          Receive news, tips and special offers
        </p>
        </div>
        <div className=" flex flex-col gap-9 md:flex md:flex-row md:items-center md:justify-center md:gap-2 relative">
          <label
            htmlFor="email"
            className="flex justify-center items-center relative rounded-lg"
          >
            <input
              className="border border-gray-500 p-3 w-72 rounded-lg"
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Email address"
            />
          </label>
          <SubscribeButton 
            email={email}
            setSuccessMsg={setSuccessMsg}
            setSuccess={setSuccess}
            setError={setError}
            setErrorMsg={setErrorMsg}
            setEmail={setEmail}  />
        </div>
      </section>
      <FooterTwo />
    </div>
  );
};

export default AboutPage;
