import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../components/Logo';
import '../../styles/AdminSignUp.css';
import { eyeIcon, eyeCancel } from '../../assets';
import ErrorPopUp from '../../components/ErrorPopUp';
import SignupSuccessful from '../../components/AdminSignupSuccess';
// import { useAuthContext } from '../../hooks/useAdminAuthContext';
import Select from 'react-select';

export default function AdminSignUp() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorFname, setErrorFname] = useState(false);
  const [errorLname, setErrorLname] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [signupSuccess, setSignupSuccess] = useState(false);
  // const { dispatch } = useAuthContext();
  const [userDetails, setUserDetails] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const onLoginToDashboard = () => {
    console.log('worked!');
    setShowModal(!showModal);
  };
  // validation
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };
  setTimeout(() => {
    if (error) {
      setError(false);
      setErrorMsg('');
    }
  }, [1000]);
  useEffect(() => {
    if (fname) {
      setErrorFname(false);
    }
    if (lname) {
      setErrorLname(false);
    }
    if (email) {
      setErrorEmail(false);
      setEmailValidError(false);
    }
    if (validateEmail(email)) {
      setEmailValidError(false);
    }
    if (!password || password.length >= 6) {
      setErrorPassword(false);
    }
  }, [fname, lname, email, password]);

  const passwordToggle = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };
  // for the role dropdown
  const role = [
    { name: 'role', value: 'admin', label: 'Admin' },
    { name: 'role', value: 'editor', label: 'Editor' },
    { name: 'role', value: 'contributor', label: 'Contributor' },
  ];
  const [formData, setFormData] = useState({
    role: '',
  });
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: '1px solid #EAECF0',
      backgroundColor: state.isSelected ? 'grey' : '#FCFCFD',
      fontWeight: '600',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      boxShadow: state.isFocused ? null : null,
      border: state.isFocused ? '1.5px solid #D0D5DD' : '1.5px solid #D0D5DD',
      '&:hover': {
        // Overwrittes the different states of border
        border: state.isFocused ? '1.5px solid #D0D5DD' : '1.5px solid #D0D5DD',
      },
    }),
  };

  function handleChange2(event) {
    const { name, value } = event;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("working")
    if (!fname) {
      setErrorFname(true);
    } else if (!lname) {
      setErrorLname(true);
    } else if (!email) {
      setErrorEmail(true);
      setEmailValidError(false);
    } else if (!validateEmail(email)) {
      setEmailValidError(true);
    } else if (!password || password.length < 6) {
      setErrorPassword(true);
    } else if (fname && lname && email && password.length >= 6) {
      // successful form submission
      const headers = {
        'Content-Type': 'application/json',
        // 'x-access-token': 'token-value',
        Authorization: `Bearer ${localStorage.getItem('admintoken')} `,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/signup`,
        {
          first_name: fname,
          last_name: lname,
          email,
          role: formData.role,
          password,
        },
        { headers: headers },
      );
      // console.log(res);
      if (res.status == 201 || res.status == 200) {
        setSignupSuccess(true);
        setUserDetails(res.data);
        setFname('');
        setLname('');
        setEmail('');
        setPassword('');
        setFormData({ role: '' });
        setShowModal(true);
      } else {
        setErrorMsg(res.response.data.detail);
        setError(true);
      }
      setErrorFname(false);
      setErrorLname(false);
      setErrorEmail(false);
      setErrorPassword(false);
      setEmailValidError(false);
    }
  };
  return (
    <div className="relative flex justify-center items-center w-full font-avenir">
      {/* Sign up successful popup */}
      {showModal && (
        <SignupSuccessful
          userName={`${userDetails?.first_name} ${userDetails?.last_name}`}
          signupSuccess={signupSuccess}
          handleClick={onLoginToDashboard}
        />
      )}

      <div className="bg-[#fbfbfb] w-full  md:py-[56px] py-[32px]">
        <header className="px-7 w-full flex justify-center items-center">
          <Logo />
        </header>
        <div className="font-[400] mt-[40px] mb-7">
          <h1 className="text-[25px] text-center">Signup to Admin Dashboard</h1>
          <p className="text-center sm:text-[17px] text-sm mt-3">
            Please enter your details to continue
          </p>
        </div>
        <div className="admin_form">
          <form
            className=" mt-[30px] mx-auto w-8/12 max-w-lg flex flex-col gap-[24px]"
            onSubmit={handleSubmit}
          >
            <label className="flex flex-col gap-[8px] w-full">
              <span className="text-gray-700 text-[14px] font-[500]">
                First name
              </span>
              <input
                className={`input border ${
                  errorFname
                    ? 'border-[#F83F23] input-error-border'
                    : 'border-[#D0D5DD] admin_input_'
                } rounded-[8px] md:h-12 py-[7px] px-[11px] outline-[#475467]`}
                type="text"
                placeholder="Enter your first name"
                onChange={(e) => setFname(e.target.value)}
              />
              {errorFname && (
                <span className="text-[#F83F23] text-[14px]">
                  Please enter your first name
                </span>
              )}
            </label>
            <label className="flex flex-col gap-[8px] w-full">
              <span className="text-gray-700 text-[14px] font-[500]">
                Last name
              </span>
              <input
                className={`border ${
                  errorLname
                    ? 'border-[#F83F23] input-error-border'
                    : 'border-[#D0D5DD] admin_input_'
                } rounded-[8px] md:h-12 py-[7px] px-[11px] outline-[#475467] input`}
                type="text"
                placeholder="Enter your last name"
                onChange={(e) => setLname(e.target.value)}
              />
              {errorLname && (
                <span className="text-[#F83F23] text-[14px]">
                  Please enter your last name
                </span>
              )}
            </label>
            <label className="flex flex-col gap-[8px] w-full">
              <span className="text-gray-700 text-[14px] font-[500]">
                Email
              </span>
              <input
                className={`border ${
                  errorEmail || emailValidError
                    ? 'border-[#F83F23] input-error-border'
                    : 'border-[#D0D5DD] admin_input_'
                } rounded-[8px] md:h-12 py-[7px] px-[11px] outline-[#475467] input`}
                type="text"
                placeholder="name@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
              {errorEmail && (
                <span className="text-[#F83F23] text-[14px]">
                  Please enter your email address
                </span>
              )}
              {emailValidError && (
                <span className="text-[#F83F23] text-[14px]">
                  Email is incorrect
                </span>
              )}
            </label>
            <label className="flex flex-col gap-[8px] w-full">
              <span className="text-gray-700 text-[14px] font-[500]">
                Password
              </span>
              <div className="flex w-full relative">
                <input
                  className={`w-full border ${
                    errorPassword
                      ? 'border-[#F83F23] input-error-border'
                      : 'border-[#D0D5DD] admin_input_'
                  } rounded-[8px] md:h-12 py-[7px] px-[11px] outline-[#475467] input`}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                {showPassword && (
                  <img
                    className="absolute top-[14px] right-[14px] cursor-pointer"
                    src={eyeIcon}
                    alt="eyeicon"
                    onClick={() => passwordToggle()}
                  />
                )}
                {!showPassword && (
                  <img
                    className="absolute top-[14px] right-[14px] cursor-pointer"
                    src={eyeCancel}
                    alt="eyeicon"
                    onClick={() => passwordToggle()}
                  />
                )}
              </div>
              {errorPassword && (
                <span className="text-[#F83F23] text-[14px]">
                  Password must contain at least 6 characters
                </span>
              )}
            </label>
            {/* <div className="writer__input-container"> */}
            <label htmlFor="role" className="flex flex-col gap-[8px] w-full">
              <span className="text-gray-700 text-[14px] font-[500]">
                Select a Role
              </span>
              <Select
                options={role}
                styles={customStyles}
                id="role"
                placeholder="Admin"
                onChange={handleChange2}
                required
                className="rounded-[8px]"
              />
            </label>
            {/* </div> */}
            <div className="text-[14px] sm:text-[16px] text-left">
              <span>By Registering you are automatically accepting our </span>
              <div className="inline justify-center">
                <Link
                  to="/termsofservice"
                  className="text-[#D2120F] font-[850]"
                >
                  Terms{' '}
                </Link>{' '}
                and
                <Link
                  to="/termsofservice"
                  className="text-[#D2120F] font-[850]"
                >
                  {' '}
                  Conditions{' '}
                </Link>
              </div>
            </div>
            <label className="flex flex-col gap-[6px] w-full mt-[8px]">
              <input
                className="cursor-pointer border border-[#D2120F] rounded-[8px] py-[12px] px-[14px] bg-[#D2120F] text-white hover:bg-white hover:text-[#D2120F] transition-all"
                id="btn__submit"
                type="submit"
                value="Create Account"
              />
            </label>
            <div className="mb-14 flex gap-[5px] justify-center">
              <p className="dont_have text-[16px]">Already have an account? </p>
              <Link
                to="/adminsignin"
                className="dont_have text-[#D2120F] font-[850] text-[16px]"
              >
                Login
              </Link>{' '}
            </div>
          </form>
        </div>
      </div>

      <ErrorPopUp error={error} message={errorMsg} />
    </div>
  );
}
