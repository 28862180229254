import React from 'react';
import GeneratorForm from '../../components/GenerateForm';
import Navbar from '../../components/Navbar/Navbar';
import FooterTwo from './../../components/FooterTwo';

export default function SurveyPage() {
  return (
    <>
      <Navbar />
      <GeneratorForm />
      <FooterTwo />
    </>
  );
}
