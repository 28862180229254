import React, { useState,  useEffect } from "react";
import "./styles/admindashboard.css"
//import axios from "axios";
import UserRow from "./userRow";
import AdminRow from "./adminRow"
import { AdminTotalSend } from "./admincomponents/admintotalsend";
import total from "../../assets/total.png"
import sent from "../../assets/sent.png"
import search from "../../assets/search.png"
import add from "../../assets/add.png"
import caret from "../../assets/caret.png"
import { Link } from "react-router-dom";
import AdminNavbar from "./admincomponents/adminavbar";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { refreshToken } from "./adminutils";
import axios from "axios"
import { toast, ToastContainer } from 'react-toastify';

function Admindashboard() {
    const [userClick, setUserClick] = useState(true);
    const [checked, setChecked] = useState(true);
    const [userList, setUserList] = useState([])
    const [adminList, setAdminList] = useState([])
    const [ users, setUsers ] = useState([])
    const [subscribersTotal, setTotalSubscribers] = useState(0)
    const [TotalAdmin, setTotalAdmin] = useState(0)
    const [ page, changePage ] = useState(1)
    const [ adminPage, changeAdminPage] = useState(1)
    const [sentletter, changeSetLetter] = useState(0)
    const [deletebtn, showDeletebtn] = useState(false)
    const [searchUser, changeSearchUser] = useState("")
    const [ searchAdmin,changeSearchAdmin ] = useState("")

    

    const handleSearch = e => {
        let name = e.target.value.toLowerCase()
        if(userClick){
            changeSearchUser(name)
            return
        }
        changeSearchAdmin(name)
    }
    const searchedUser = searchUser === '' ? userList : 
    userList.filter(user => user.first_name.toLowerCase().includes(`${searchUser}`))

    const searchedAdmin = searchAdmin === '' ? adminList : 
    adminList.filter(admin => admin.first_name.toLowerCase().includes(`${searchAdmin}`))

    const sunscribepageCount = subscribersTotal ? Math.ceil(subscribersTotal/11) : 0
    const adminpageCount = TotalAdmin ? Math.ceil(TotalAdmin/5) : 0
    
    const handleChange = e => {
        
        const pageNumber = parseInt(e.target.innerText)

        if(pageNumber){
            changePage(pageNumber)
            return
        }
        if(page < sunscribepageCount){
            changePage(page + 1)
        }else{
            changePage(page - 1)
        }

    }

    const handleAdminPageChange = e => {

        const pageNumber = parseInt(e.target.innerText)
        if(pageNumber){
            changeAdminPage(pageNumber)
            return
        }
        if(adminPage < adminpageCount){
            changeAdminPage(adminPage + 1)
        }else{
            changeAdminPage(adminPage - 1)
        }
    }
    

    const handleUserClick = () => {
        setUserClick(true)
    }
    const handleAdminClick = () => {
        setUserClick(false)
    }
    const handleCheckboxClick = () => {
        setChecked(!checked)
    }

    function getSubscribers() {
        const token = localStorage.getItem('admintoken');
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/subscribers/all?page=${page}&size=11`,{
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
                
              },
            })
            .then((res) => res.json())
            .then(data => {
                if(data.detail === 'invalid access token or access token has expired'){
                    refreshToken()
                    return
                }
                setUserList(data.items)
                setTotalSubscribers(data.total)
            })
            .catch((err) => console.log(err));
    }
    
    useEffect(() => {
        getSubscribers()
    },[page]);

    const handleDelete = async (e) => {
        e.stopPropagation()
        const checkBoxes = document.querySelectorAll('.usercheck')

        
        
        const  checkedTotal = () =>  {
            let checked = []
            let checkindex = []
            checkBoxes.forEach(checkbox => {
                if(checkbox.checked){
                    checked = [...checked,checkbox.dataset.id]  
                    checkindex = [...checkindex,checkbox.dataset.index]  
                }
            })
            return {checked, checkindex}
        }


        if(checkedTotal().checked.length === 0) {
            toast.error(`Check users`, {
                position: toast.POSITION.TOP_CENTER
            })
            return
        }

        const totalchecked = await checkedTotal().checked
        
        console.log(checkedTotal())
        const token = localStorage.getItem('admintoken');
        const options = {
            url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/multiple/users/`,
            method: 'POST',
            data: totalchecked,
            header: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
              'Access-Control-Allow-Credentials': true,
              'Access-Control-Allow-Origin': '*'
            }
          };
        
        
        axios(options)
        .then(res => {
            console.log(res)
            if(res?.data?.entries == 0){
                throw Error("Can't delete checked users")
            }
            if(res?.response?.status === 401){
                throw res.response.status
            }
            if(res?.data?.entries > 1){
                toast.success(`${res.data.message}`, {
                    position: toast.POSITION.TOP_CENTER
                })
                getSubscribers()
            }
            if(res?.data?.entries === 1){
                toast.success(`Deleted a single user`, {
                    position: toast.POSITION.TOP_CENTER
                })
                getSubscribers()
            }
           
        }).catch(err => {
            if(err === 401) {
                refreshToken()
                return
            }
            toast.error(`${err}`, {
                position: toast.POSITION.TOP_CENTER
            })
        })

    }

    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/statistics`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
              },
        }).then(res => {
            if(res.status === 401){
                throw Error
            }
            return res.json()
        })
        .then(data => {
            changeSetLetter(data.letters)
        }).catch(err => {
            if(err){
                changeSetLetter(0)
            }
        })
    }, [])

   
    
    
    useEffect(() => {
        const token = localStorage.getItem('admintoken');
        fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/admins/all?page=${adminPage}&size=5`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
              },
            })
            .then((res) => res.json())
            .then(data => {
            
                if(data.detail === 'invalid access token or access token has expired'){
                    refreshToken()
                    return
                }
                setAdminList(data.items)
                setTotalAdmin(data.total)
            })
            .catch((err) => console.log(err.message));
    }, [adminPage]);
    
  
    const hanldeClick = () => {
        if(deletebtn) showDeletebtn(!deletebtn)
    }

    return (
        <div className="admin" onClick={hanldeClick}>
            <AdminNavbar />
            <ToastContainer />
            <main className="admin-main">
                <h1 className="font-avenir font-normal ">Admin Dashboard</h1>
                <div className="flex gap-[42px] w-[90%] mx-auto mt-[47px] md:flex-row flex-col">
                    <AdminTotalSend image={total} title="Total Subscribers" number={`${subscribersTotal}`} borderLeft="2px" color="#0F46D2" />
                    <AdminTotalSend image={sent} title="Sent Letters" number={`${sentletter}`} borderLeft="2px" color="#D2120F"  />
                </div>
                <div className="main-bottom relative">
                    <div className={userClick ? 'user-admin' : 'user-admin'}>
                        <span onClick={handleUserClick} className={userClick ? 'user-active' : 'user-inactive'}>User</span>
                        <span onClick={handleAdminClick} className={userClick ? 'admin-inactive' : 'admin-active'}>Admin</span>
                        {userClick ? '' : (
                            <Link to="/add-admin">
                                <div className="absolute right-0 top-[-42px] flex items-center gap-[8px] p-[16px] bg-[#D2120F] rounded-[4px]">
                                    <img src={add} alt="addimage" className="w-[20px] h-[20px]" />
                                    <span className="text-base font-avenir font-normal leading-[20px] text-white">Add New</span>
                                </div>
                            </Link>
                        )}
                    </div>

                    <div className="table-container">
                        <div className="table-container-top">

                            <div className="table-container-top-left">
                                <input id="tabletopcheck" className='table-top-checkbox' type='checkbox' onChange={handleCheckboxClick} checked={checked} />
                                <label className="tabletopchecklabel" htmlFor="tabletopcheck">Select</label>
                            </div>

                            <div className="table-container-top-right flex items-center gap-[16px]">
                                <div className='w-[215.4px] flex gap-[8px] bg-white items-center border-solid border-[1px] border-[#E0E0E0] rounded-[8px] overflow-hidden'>
                                    <img src={search} alt="search" className="w-[17.4px] h-[17.4px] ml-[8px]" />
                                    <input type='text' placeholder="Search..." className="grow-[1px] p-[8px] font-avenir  border-0 outline-0 text-base font-[500] leading-[20px] placeholder-[#BBBCBC] " onChange={handleSearch} />
                                </div>
                                <div className="flex items-center p-[8px] font-avenir  text-base justify-between rounded-[8px] gap-[8px] cursor-pointer border-solid border-[1px] bg-[#FFFFFF] border-[#E0E0E0] relative"  onClick={() => showDeletebtn(!deletebtn)}>
                                    <span>Bulk Actions</span>
                                    <img src={caret} alt="caret" className="w-[12px] h-[8px]" />
                                    {deletebtn && <button onClick={handleDelete} className="absolute bottom-[-32px] right-0 left-0 py-[2px] bg-[red] text-white border-0 rounded-[8px]">Delete checked</button>}
                                </div>
                                <button className="py-[8px] px-[16px] outline-0 font-avenir text-base border-solid border-[1px] bg-[#FFFFFF] border-[#E0E0E0] rounded-[8px]">Apply</button>
                            </div>

                        </div>

                        <div className="table-container-main flex flex-col gap-[13px]">
                            <div className="table font-avenir font-[500] text-base">
                                    <div >All</div>
                                    <div>First Name</div>
                                    <div>Last Name</div>
                                    <div>Email</div>
                                    <div>{userClick ? 'Last Access' : 'Admin Role'}</div>
                                    <div className={userClick ? 'lastuser' : 'lastadmin'}>{userClick ? 'Action' : 'Status'}</div>
                            </div>
                            {
                                userClick ?
                                searchedUser.map((user, index) => <UserRow key={user.id} inx={index} id={user.id} myindex={index} userList={userList} setUserList={setUserList} lastActive={user.last_active} firstName={user.first_name} lastName={user.last_name} email={user.email}   users={users} setUsers={setUsers} />) :
                                searchedAdmin.map((admin, index) => <AdminRow key={admin.id} inx={index} id={admin.id}  userList={adminList} setUserList={setAdminList}  firstName={admin.first_name} lastName={admin.last_name} email={admin.email} />)
                                }
                        </div>
                        
                    </div>
                    <div className="pagination_container pt-[43px] pb-[119px] flex justify-center items-center font-avenir">
                        {userClick ? (
                            <Stack spacing={2}>
                                <Pagination count={sunscribepageCount} onChange={handleChange}  />
                            </Stack>
                        ) : (
                            <Stack spacing={2}>
                                <Pagination count={adminpageCount} onChange={handleAdminPageChange}  />
                            </Stack>
                        )}
                    </div>
                </div>
            </main>
        </div>
    )
}



export default Admindashboard;
