import Navbar from '../components/Navbar/Navbar';
import FooterTwo from '../components/FooterTwo';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/reset/send_reset_email`;
const tokenUrl = `${process.env.REACT_APP_API_BASE_URL}/api/v1/reset/validate_token`;

const ResetPasswordPage = () => {
  // Token request
  const submitStatus = useRef(null);
  const [email, setEmail] = useState('');
  const [alertMsg, setAlertMsg] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      submitStatus.current.style.visibility = 'visible';
      setTimeout(() => {
        submitStatus.current.style.visibility = 'hidden';
      }, 5000);

      const resp = await axios.post(url, { email });
      if (resp.status === 200) {
        e.target.reset();
        submitStatus.current.classList.add('success');
        submitStatus.current.classList.remove('danger');
        setAlertMsg('A token has been sent to your email address');
      }
      if (resp.response.status === 404) {
        submitStatus.current.classList.remove('success');
        submitStatus.current.classList.add('danger');
        setAlertMsg('There is no user with this email');
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  // Token validation
  const tokenStatus = useRef(null);
  const createNewPassword = useRef(null);

  const [token, setToken] = useState([]);
  useEffect(() => {
    localStorage.setItem('savedToken', JSON.stringify(token));
  }, [token]);

  const handleToken = (e) => {
    setToken(e.target.value);
  };

  const verifyToken = async (e) => {
    e.preventDefault();
    try {
      const resp = await axios.post(tokenUrl, { token });
      e.target.reset();

      tokenStatus.current.style.visibility = 'visible';
      setTimeout(() => {
        tokenStatus.current.style.visibility = 'hidden';
      }, 5000);

      if (resp.status === 200) {
        e.target.reset();
        tokenStatus.current.classList.add('success');
        tokenStatus.current.classList.remove('danger');
        createNewPassword.current.style.visibility = 'visible';
        setAlertMsg('Token validated successfully, click create new password');
      } else {
        tokenStatus.current.classList.remove('success');
        tokenStatus.current.classList.add('danger');
        setAlertMsg(
          'Invalid token, please enter correct token or restart the process',
        );
      }
    } catch (error) {
      console.log(error.response);
    }
  };

  return (
    <>
      <Navbar />
      <section className=" px-[4vw] text-[#383838] mt-[5rem] lg:flex flex-row justify-between ">
        <article className="  lg:px-8 lg:w-[50%] ">
          {/* logo */}
          <div className=" flex justify-center items-center gap-x-1 pb-12 pt-4 md:justify-start">
            <img
              src="/images/logos_vivaldi-icon.svg"
              alt="logo_vivaldi"
              className=" w-[1rem]"
            />
            <h3 className=" font-[600] text-[#464646] md:text-[1.5rem] ">
              LoveMe
            </h3>
          </div>
          {/* content */}
          <div className=" mb-4">
            <h2 className=" mb-4 text-[1.5rem] md:text-[2rem] font-[500] text-[#04172A] ">
              Hey there, don’t worry!
            </h2>
            <p className=" text-[1rem] md:text-lg leading-7">
              We are here to help you recover your password. Enter the E-mail
              address you used when you joined us and we’ll send you
              instructions on how to change your password.
            </p>
          </div>

          {/* forms */}
          <div>
            {/* email form */}
            <form onSubmit={handleSubmit}>
              {/* email */}
              <div className=" mb-8">
                <label htmlFor="email" className=" block  mb-2">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Email"
                  required
                  onChange={handleChange}
                  className=" border border-solid border-[#D0D5DD] bg-[#F9FAFB] rounded-[8px]  pl-4 py-3 w-[100%] "
                />
                {/* submit status */}
                <p
                  ref={submitStatus}
                  className="submit-status ml-auto mr-auto text-lg "
                >
                  {alertMsg}
                </p>
              </div>
              {/* send reset button */}
              <button className=" bg-main-1 text-white w-[100%] h-[2.75rem] rounded-lg block border border-solid border-main-1 ml-[auto] mr-[auto] mb-4  text-[1.25rem] ">
                Send reset password token
              </button>
            </form>

            {/* token form */}
            <form className=" mt-16" onSubmit={verifyToken}>
              <div className=" mb-8">
                <label htmlFor="email" className=" block  mb-2">
                  Token
                </label>
                <input
                  type="text"
                  placeholder="Enter token sent to your email"
                  required
                  onChange={handleToken}
                  className=" border border-solid border-[#D0D5DD] bg-[#F9FAFB] rounded-[8px]  pl-4 py-3 w-[100%] "
                />
                {/* submit status */}
                <p
                  ref={tokenStatus}
                  className="token-status ml-auto mr-auto text-lg "
                >
                  {alertMsg}
                </p>
              </div>

              {/* validate token */}
              <button className=" bg-main-1 text-white w-[100%] h-[2.75rem] rounded-lg block border border-solid border-main-1 ml-[auto] mr-[auto] mb-8 text-[1.25rem]">
                Validate token
              </button>
            </form>
          </div>

          {/* create new password */}
          <Link
            ref={createNewPassword}
            to="/create-new-password"
            className="create-new-password   bg-main-1 text-white w-[100%] h-[2.75rem] rounded-lg border border-solid border-main-1 ml-[auto] mr-[auto] mb-[5rem] grid place-items-center text-[1.25rem] "
          >
            Create new password
          </Link>

          {/* back to login */}
          <Link
            to="/signin"
            className="  w-[100%] h-[2.75rem] rounded-lg border border-solid border-[#d2120f] ml-[auto] mr-[auto] mb-[5rem] grid place-items-center text-[1.25rem] text-black "
          >
            Back to Login
          </Link>
        </article>
        <article className="hidden lg:grid place-items-center  w-[50%]">
          <img
            src="/images/questionMark.png"
            alt="question mark"
            className="mr-[auto] ml-[auto] w-full"
          />
        </article>
      </section>
      <FooterTwo />
    </>
  );
};

export default ResetPasswordPage;
