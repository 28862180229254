import React from "react"
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

export const SUbscirbeModal = ({ children, open }) => {
  if (!open) return null;

  return createPortal(
    <div className="modal fixed bg-black z-10 bg-opacity-80 top-0 left-0 right-0 bottom-0 w-[100%] h-[100vh] flex justify-center items-center">{children}</div>,
    document.getElementById('modal'),
  );
};

SUbscirbeModal.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool,
};