import React from 'react'
import { AdminCheckbox } from './admincomponents/checkbox';
import PropTypes from 'prop-types';
//import axios from 'axios';

function AdminRow({firstName, lastName, email, id, /*adminList, setAdminList*/}) {
    
    /*const handleDelete = () => {




        const token = localStorage.getItem('token');
        axios.delete(`admin/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-type': 'application/json',
            },
        })
        .then(res => {
            console.log(res?.response?.data?.detail)
            if(res?.response?.data?.detail){
                console.log(res.response.data.detail)
                return
            }
            console.log(res)
            const admins = adminList.filter(user => user.id !== id)
            setAdminList(admins)
        })
    }
    */

    return (
        
        <div key={id} className="table admin font-avenir font-[500] text-base">
            <div className='flex items-center'><AdminCheckbox /></div>
            <div>{firstName}</div>
            <div>{lastName}</div>
            <div>{email}</div>
            <div>Contributor</div>
            <div>Approved</div>
        </div>
        
    )
}

AdminRow.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
    adminList:PropTypes.array,
    setAdminList:PropTypes.func
}


export default AdminRow;