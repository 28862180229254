import GenerateForm from '../../components/GenerateForm';
import './FormLanding.css';

export const FormLanding = () => {
  return (
    <>
      <GenerateForm classStyle="form_divlp" />
    </>
  );
};
