import React, { useState, useEffect } from 'react';
//import InputField from '../InputField/InputField';
import axios from 'axios';
import '../Profile/Profile.css';
//import useProfileData from '../../hooks/useProfileData';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/me/update-user`;

export function PersonalInfo(props) {
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emaill, setEmail] = useState('');
  const [accessToken, setAccessToken] = useState('');
  // console.log(firstName)
  const error = () => {
    toast.error('Error Occured', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  const success = () => {
    toast.success('Saved!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    setAccessToken(access_token);
    const token = localStorage.getItem('token');

    axios
      .get(`user/me/get-user`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        setFirstName(res.data.firstname);
        setLastName(res.data.lastname);
        setEmail(res.data.email);
      })
      .catch((err) => {
        error();
        console.log(err);
      });
  }, []);

  //const [phoneNumber, setPhoneNumber] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      first_name: firstName,
      last_name: lastName,
      email: emaill,
    };
    axios
      .patch(url, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(() => {
        props.resetData();
        success();
        setEditMode(false);
      })
      .catch((err) => {
        console.log(err);
        error();
        setEditMode(false);
      });
    //     console.log(error.response);
    //   })
  };
  //   const data = {
  //     first_name: firstName,
  //     last_name: lastName,
  //     email,
  //   };
  //   try {
  //     const res = await axios.patch(url, data, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });
  //     if (res.status === 200) {
  //       axios
  //         .get('/user/me/get-user', {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         })
  //         .then((res) => {
  //           console.log(res.data);

  //           props.resetData();
  //         });
  //     }
  //     console.log(res);
  //     alert(res.data[0]);
  //     setFirstName('');
  //     setLastName('');
  //     setEmail('');
  //   } catch (error) {
  //     setFirstName('');
  //     setLastName('');
  //     setEmail('');
  //     alert('an error occured retry');
  //     console.log(error.response);
  //   }
  // };

  //  useRef(()=>{
  //     const userInfo = localStorage.getItem('user')
  //     console.log(userInfo)
  //  }, [])

  //if (user) {
  // return <div>{user.name} is
  // logged in</div>
  //}

  return (
    <div>
      <div className="flex items-center gap-4 mb-6">
        <h2 className="text-xl">Personal Information</h2>

        {!editMode && (
          <img
            src={'/edit-alt.svg'}
            alt="edit"
            className="cursor-pointer"
            onClick={() => {
              setEditMode(true);
            }}
          />
        )}
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
          <div>
            <label htmlFor="" className="profilelabel">
              First Name
            </label>
            <input
              className="profilename block pl-2"
              type="text"
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="" className="profilelabel">
              Last Name
            </label>
            <input
              className="profilename block pl-2"
              type="text"
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
          <div>
            <label className="profilelabel">Email</label>
            <input
              className="profilename block pl-2"
              type="email"
              placeholder="Email Address"
              value={emaill}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {/* <InputField name="phone_number" labelText="Phone Number" onChange={(e) => setPhoneNumber(e.target.value)}/> */}
        </div>

        {editMode && (
          <div className="flex items-center gap-4">
            <button
              type="submit"
              className="bg-main-1 hover:bg-main-2 transition-all px-8 h-[45px] rounded-md text-white"
            >
              Save changes
            </button>
            <button
              type="button"
              className="h-[45px] px-8 rounded-md border"
              style={{
                border: '0.3px solid #d0d5dd',
              }}
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
}

PersonalInfo.propTypes = {
  resetData: PropTypes.func.isRequired,
};
