import React from 'react';
import Button from '../Button/Button';
import styling from './freetrialmodal.module.css';
import LoveMe from '../../assets/background.png'




/* 
This is a reusable componennent
To use first import useModal Hooks from Hook
Inside the useModal hooks are ModalState,hideModalHandler,showModalHandler
The modalState: value true or false
if value is true this modal is rendered
if value is false this modal is hidden
The function showmodalHandler sets the state to true
The function hidemodalHandler sets the state to false 
A demo is shown in the app.jsx component it is false by default
To show the modal set value of modalState to true in useModal Hooks under hooks Folder
This documentation is for non-technical leads
*/
const FreeTrialPage = () => {
  const { modal_Container, modal_content } = styling;

  return (
    <section className={modal_Container}>
      <div className={modal_content}>
        <article>
          <h2>LoveMe App generates love notes for your loved ones.</h2>
        </article>
        <ul>
          <li>It curates special love notes.</li>
          <li>It auto-generates love notes to your loved ones.</li>
          <li>It allows you to add a picture of your lover to the letter.</li>
          <li>It sparks up your love life.</li>
          <li>It professes your love to your lover without you writing.</li>
        </ul>
        <Button
          height="48px"
          name= 'Enjoy a one-time free trial'
          
        ></Button>
      </div>
      <article>
        <img src={LoveMe} alt="logo" />
      </article>
    </section>
  );
};


export default FreeTrialPage;
