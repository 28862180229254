import React from "react"
import PropTypes from 'prop-types';
import axios from "axios"

export const SubscribeButton = ({email,setSuccessMsg, setSuccess, setError, setErrorMsg, setEmail}) => {

    

    
    const handleSubscribe = () => {
        if(email === ""){
        setErrorMsg("Please enter a valid email")
        setError(true)
        return 
        }
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/v1/subscriber/`,{ email }
        ).then(res => {
            if(res?.response?.data?.detail){
                setErrorMsg(res.response.data.detail)
                setError(true)
                return 
            }
            setSuccessMsg(res.data)
            setSuccess(true)
        })
        setEmail("")

    }


    return(
        <button
            className="w-40 mx-auto p-2 bg-main-1 no-underline rounded-lg text-white text-xl ouline-0 border-0"
            onClick={handleSubscribe}
          >
            Subscribe
          </button>
    )
     
}

SubscribeButton.propTypes = {
    email: PropTypes.string,
    setSuccessMsg: PropTypes.func,
    setError: PropTypes.func,
    setErrorMsg: PropTypes.func,
    setSuccess:PropTypes.func,
    setEmail:PropTypes.func,
  };


