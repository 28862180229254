/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import '../../styles/PreviewLetter.css';
import FooterTwo from '../../components/FooterTwo';
import Navbar from '../../components/Navbar/Navbar';
import Modal from '../../components/Form/Modal';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ShareButton from '../../components/ShareButton/ShareButton';
// import { exportAsImage, exportAsPdf } from '../../utils/download';
// import DownLoadDropDown from '../../components/DownloadDropDown/DownLoadDropDown';
import PropTypes from 'prop-types';
import useDropoff from '../../hooks/useDropoff';
import axios from 'axios';
import { Loader } from '../../components/loader/loader';
import { SUbscirbeModal } from '../../components/modal';
import { ModalContent } from '../../components/upgrademodalcontent';

export default function LetterPage({
  editFeat,
  getLetterPage,
  scheduledLetterPage,
  completedLetterPage,
}) {
  const [isOpen, setIsOpen] = useState(false);
  // const exportRef = useRef();
  const [letter, setLetter] = useState(
    'Kindly fill the survey form to Generate Letter',
  );
  const [share, setShare] = useState(false);
  const [dshare, setDShare] = useState(false);
  const [copy, setCopy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [edited, setEdited] = useState(false);
  const [letterId, setLetterId] = useState('');
  const [letterTitle, setLetterTitle] = useState('');
  const inputElement = useRef();
  const letterRef = useRef(null);
  const token = localStorage.getItem('token');

  const allowEdit = () => {
    //console.log('edit');
    setEdited(true);
    if (letterRef.current){
    letterRef.current.disabled = false;
    letterRef.current.focus();
    }
    //setSave(true);
  };

  const disableEdit = () => {
    if (letterRef.current) {
    letterRef.current.disabled = true;
    }
  };

  // const [showContent, setShowContent] = useState(true);

  // const handleDownloadImage = async () => {
  //   await setShowContent(false);
  //   exportAsImage(exportRef.current, 'Love Letter');
  //   await setShowContent(true);

  // };
  // const handleDownloadPdf = async () => {
  //   await setShowContent(false);
  //   exportAsPdf(exportRef.current, 'Love Letter');
  //   await setShowContent(true);

  // };

  function haveCopied(e) {
    e.preventDefault();
    setTimeout(() => {
      setCopy(true);
      navigator.clipboard.writeText(letter);
    }, 2000);
    setTimeout(() => {
      setCopy(false);
    }, 5000);
  }

  function isCopied() {
    setCopy(false);
  }
  function handleSchedule() {
    console.log(token);
    if (edited) {
      const options = {
        url: `${process.env.REACT_APP_API_BASE_URL}/api/v1/letter/api/v1/EditLetter/${letterId}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: { id: letterId, letter: letter, title: letterTitle },
      };
      axios(options).then(() => {
        //console.log(res);
        setIsOpen(true);
      });
    } else {
      setIsOpen(true);
    }
  }
  // function getLetter() {
  //   const letter_id = localStorage.getItem('letterId');
  //   axios
  //     .get(`letter/${letter_id}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         'Content-type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //       },
  //     })
  //     .then((res) => {
  //      // console.log(res);
  //       const loveLetter = res.data.letter;
  //       setLetter(loveLetter);
  //       setLetterId(res.data.id);
  //       setLetterTitle(res.data.title);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setIsLoading(false);
  //     });
  // }

  async function getLetter() {
    const letter_id = localStorage.getItem('letterId');
    const completed_letter_id = localStorage.getItem('completed');
    const scheduled_letter_id = localStorage.getItem('scheduled');
    const ks = [];
    if (getLetterPage) {
      ks.push(`letter/${letter_id}`);
    } else if (completedLetterPage) {
      ks.push(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/dashboard/sent/${completed_letter_id}`,
      );
    } else if (scheduledLetterPage) {
      ks.push(`${process.env.REACT_APP_API_BASE_URL}/schedule/${scheduled_letter_id}`);
    }
    await axios
      .get(`${ks[0]}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((res) => {
        console.log(res)
        const loveLetter = scheduledLetterPage? res.data.letter.letter: res.data.letter;
        setLetter(loveLetter);
        setLetterId(res.data.id);
        setLetterTitle(res.data.title);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }

  const ref2 = useDropoff(isCopied);
  const formRef = useDropoff(disableEdit);
  const handlePrint = useReactToPrint({
    content: () => inputElement.current,
  });

  isOpen
    ? (document.querySelector('body').style.overflowY = 'hidden')
    : (document.querySelector('body').style.overflowY = 'scroll');

  useEffect(() => {
    getLetter();
    // } else if (completedLetterPage) {
    //   completedLetter();
    // } else if (scheduledLetterPage) {
    //   scheduledLetter();
    // }
  }, []);

  return (
    <>
      <>
        <Navbar />
        <SUbscirbeModal open={open}>
          <ModalContent setOpen={setOpen}></ModalContent>
        </SUbscirbeModal>
        <div className="mt-20 md:mt-28 flex justify-end px-10 md:px-16">
          <Link to="/survey" className="generate-btn gap-2 items-center">
            <span> Generate</span>
            <img src="/reload.png" />
          </Link>
        </div>
        <div className="previewletter--container max-w-5xl m-auto">
          <div className="previewletter--body flex flex-col lg:flex-row gap-10">
            <div className="previewletter-mobilescreenlocation-button mb-8 lg:mb-0">
              <Link to={getLetterPage? "/survey" : "/dashboard"}>
                <button>
                  <img src="/prev.png" />
                  <span>Prev</span>
                </button>
              </Link>
            </div>

            <div className="edit_form-container" ref={formRef}>
              {editFeat && (
                <div className="px-7 md:px-10 mb-4 flex justify-end">
                  <button onClick={(e) => allowEdit(e)} className="edit">
                    <span>Edit</span>
                    <img src="/edit.svg" />
                  </button>
                </div>
              )}
              <div
                ref={inputElement}
                className="previewletter-form--container pt-24 px-5 md:px-16"
              >
                <form>
                  {isLoading ? (
                    <div className="h-[50vh] flex justify-center items-center">
                      <Loader />
                    </div>
                  ) : (
                    <textarea
                      ref={letterRef}
                      value={letter}
                      className="previewletter--input"
                      onChange={(e) => setLetter(e.target.value)}
                      name=""
                      disabled
                    />
                  )}
                  {dshare && (
                    <div className="preview--sociallinks_clipdesktop">
                      <ShareButton name="whatsapp" size={36} text={letter} />
                      <ShareButton name="facebook" size={36} text={letter} />
                      <ShareButton name="twitter" size={36} text={letter} />
                      <button
                        ref={ref2}
                        className="copied"
                        onClick={(e) => haveCopied(e)}
                      >
                        <img src="/paste-solid.png" />
                        Copy
                      </button>
                    </div>
                  )}
                </form>
                <img
                  src="/heart2.png"
                  className="hidden md:block absolute right-0 bottom-0 object-contain h-auto w-[100px]"
                />
                <img src="/heart.svg" className="previewletter-love" />
                {copy && <span className="prev--copied">Copied!</span>}
              </div>
            </div>
            <div className="previewletter-mobilescreenlocation-button mb-8 lg:mb-0">
              <Link to="">
                <button>
                  <span>Next</span>
                  <img src="/next.png" />
                </button>
              </Link>
            </div>
          </div>

          <div className="previewletter--share_downloadmobile">
            {/* <Link to="/survey" className="generate-btn">
            <span> Generate</span>
            <img src="/reload.png" />
          </Link> */}
            <button
              type="button"
              className="prevbtn-schedule hidden"
              onClick={handleSchedule}
            >
              <img className="prev-schedule" src="/calendar.png" />
              <span>Schedule</span>
            </button>
            <button
              type="button"
              className="prevbtn-schedule"
              onClick={handlePrint}
            >
              <span>Download</span>
            </button>
            <button className="prevshare-btn" onClick={() => setShare(!share)}>
              <span> Send</span>
              <img src="/send.png" />
            </button>
            {isOpen && <Modal setIsOpen={setIsOpen} />}
          </div>

          {share && (
            <div className="preview--sociallinks_clip">
              <ShareButton
                name="whatsapp"
                size={26}
                text={letter}
                className=""
              />
              <ShareButton name="facebook" size={26} text={letter} />
              <ShareButton name="twitter" size={26} text={letter} />
              <button className="copied" onClick={(e) => haveCopied(e)}>
                <img className="prev--paste-mobile" src="/paste-solid.png" />
                Copy
              </button>
            </div>
          )}
          <div className="previewletter--share_downloaddesktop max-w-3xl px-8 pb-16 pt-7">
            <div className="flex gap-5">
              <button
                type="button"
                className="prevbtn-schedule"
                onClick={handleSchedule}
              >
                <span>Schedule</span>
                <img className="prev-schedule" src="/calendar.png" />
              </button>
              <button
                type="button"
                className="prevbtn-schedule"
                onClick={handlePrint}
              >
                <span>Download</span>
              </button>
            </div>
            <button
              className="prevshare-btn"
              onClick={() => setDShare(!dshare)}
            >
              <span> Send</span>
              <img src="/send.png" />
            </button>
            {isOpen && <Modal setIsOpen={setIsOpen} />}
          </div>
        </div>
      </>
      <FooterTwo />
    </>
  );
}

LetterPage.propTypes = {
  editFeat: PropTypes.bool,
  scheduledLetterPage: PropTypes.bool,
  completedLetterPage: PropTypes.bool,
  getLetterPage: PropTypes.bool,
};
