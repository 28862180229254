import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import Logo from '../components/Logo';

const VerifyEmailToken = () => {
  const [searchParams] = useSearchParams();
  const tkn = searchParams.get('tkn');
  const [navigateTo, setNavigateTo] = useState(false);

  useEffect(() => {
    async function verifyEmail() {
      try {
        const response = await axios.post('auth/verify-email/', {
          tkn,
        });
        console.log(response);
        setNavigateTo(true);
      } catch (err) {
        console.log(err);
        setNavigateTo(false);
      }
    }

    setTimeout(() => {
      console.log('verifying');
      verifyEmail();
    }, 3000);
  }, [tkn]);

  if (navigateTo === true) {
    return <Navigate to="/signin" />;
  }
  return (
    <div className="relative flex flex-row items-stretch w-full font-avenir">
      <div className="w-full max-w-lg mx-auto  px-[16px] md:py-[56px] py-[32px]">
        <div className="flex justify-center w-full">
          <Logo />
        </div>
        <div className="font-[400] mt-[48px]">
          <h1 className="text-[40px] text-center">Verifying Email</h1>
          <div>
            <span className="spinner-box">
              <span className="spinner"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailToken;
