import React from 'react';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import ErrorIcon from '../../assets/error.svg';

const PaymentCancelled = () => {
  const navigate = useNavigate();
  return (
    <main className="flex justify-center items-center h-screen w-full">
      <div className="shadow-lg rounded-xl text-center px-4 max-w-5xl mx-auto flex flex-col justify-center items-center gap-10 pb-14">
        <img
          className="w-16 block mt-4"
          alt="payment succesful"
          src={ErrorIcon}
        />
        <h2 className="text-lg font-avenir_bold">Payment Unsuccessful</h2>
        <p className="px-4">
          We encountered some issues while trying to process your payment! You
          can try again or to your dashboard
        </p>
        <Button
          onClick={() => navigate('/pricing')}
          name={'Try Again!'}
          height={'40px'}
          width={'300px'}
        />
        <button
          onClick={() => navigate('/dashboard')}
          className="h-10 w-40 bg-main-2 border-none rounded-md text-white"
        >
          {' '}
          Go to Dashboard
        </button>
      </div>
    </main>
  );
};

export default PaymentCancelled;
