/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Paystack from './Paystack';
import CardPayment from './CardPayment';
import FlutterWave from './FlutterPayment';
import { allPrices } from '../../components/Prices';
import Navbar from '../../components/Navbar/Navbar';

export default function Checkout() {
  const [paymentMethod, setPaymentMethod] = React.useState('paystack');
  const [loading, setLoading] = React.useState(false);
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const subscriptionPlan =
    allPrices.find((element) => element.id === searchParams[0].get('id')) ||
    allPrices[0];

  React.useEffect(() => {}, []);

  const handlePaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  // function handlePayment(e) {
  //   e.preventDefault();
  //   setLoading(true);
  //   console.log('Payment Initiated');

  //   axios
  //     .post(
  //       'transaction/create-customer/',
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           access_token: token,
  //           'Access-Control-Allow-Origin': '*',
  //         },
  //       },
  //     )
  //     .then((res) => {
  //       console.log(res);
  //       axios
  //         .post(
  //           `/subscription/checkout/${subscriptionPlan.id}`,
  //           {
  //             //No data to send
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               access_token: token,
  //               'Access-Control-Allow-Origin': '*',
  //             },
  //           },
  //         )
  //         .then((res) => {
  //           console.log(res.data);
  //           window.location.href = res.data.url;
  //         })
  //         .catch(() => {
  //           console.log('An error occured!');
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  return (
    <div className="">
      <Navbar />
      <main className="flex items-center justify-center flex-col shadow-lg rounded-xl w-11/12 max-w-6xl mx-auto my-20 lg:flex-row lg:justify-between lg:items-start">
        <section
          className={`lg:w-[486px] w-full rounded-t-xl lg:rounded-br-xl lg:rounded-tl-none bg-[#FAFAFA] lg:rounded-tr-xl  lg:px-10 py-12 text-gray-900`}
        >
          <div className="flex items-start justify-between border-b-2 pb-3 px-5 mb-8 border-[#AFAFAF]">
            <div className="flex flex-col gap-1">
              <h2 className="text-[#334252] text-lg font-extrabold mb-2">
                {subscriptionPlan.heading}
              </h2>
              <p className="font-medium pb-px ">
                Duration:{' '}
                {subscriptionPlan.id == 1
                  ? '1 Month'
                  : subscriptionPlan.id == 2
                  ? '3 Months'
                  : '1 Year'}
              </p>
              <p
                className="font-medium underline text-sm cursor-pointer"
                onClick={() => navigate('/dashboard')}
              >
                Cancel
              </p>
            </div>
            <p
              className="text-main-1 flex justify-center items-center cursor-pointer text-sm font-extrabold gap-4 pt-2"
              onClick={() => navigate('/pricing')}
            >
              <img className="w-[14px]" src="checkout-img/edit.svg" />
              Edit Plan
            </p>
          </div>

          <table className="w-full gap-4 font-medium border-b-2 border-[#AFAFAF] text-sm">
            <tbody>
              <tr className="">
                <td className="pl-5 py-3">Subtotal</td>
                <td className="text-right pr-5 py-3">
                  {subscriptionPlan.price + '.00'}
                </td>
              </tr>
              <tr>
                <td className="pl-5 pb-3">VAT</td>
                <td className="text-right pr-5 pb-3">{'$0.00'}</td>
              </tr>
              <tr>
                <td className="pl-5 pb-8">Total Amount</td>
                <td className="text-right pr-5 pb-8  text-base">
                  {subscriptionPlan.price + '.00'}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="px-5 mt-6">
            <h2 className="text-[#334252] uppercase">Payment Method</h2>
            <div className="mt-6 flex flex-col gap-3">
              <div className="flex items-center gap-4">
                <input
                  defaultChecked
                  name={'payment-method'}
                  type={'radio'}
                  id={'paystack'}
                  className="w-4 h-4"
                  onClick={handlePaymentMethod}
                  value={'paystack'}
                />
                <label
                  className="flex items-center justify-center gap-4 font-bold"
                  htmlFor={'paystack'}
                >
                  <img width={100} src="/paystack-2.svg" alt="bank transfer" />
                </label>
              </div>

              <div className="flex items-center gap-4">
                <input
                  name={'payment-method'}
                  type={'radio'}
                  className="w-4 h-4"
                  onClick={handlePaymentMethod}
                  defaultChecked
                  id={'paypal'}
                  value={'paypal'}
                />
                <label
                  className="flex items-center justify-center gap-4 font-bold"
                  htmlFor={'paypal'}
                >
                  <img
                    width={100}
                    height={23}
                    src="/paypal-3.svg"
                    alt="PayPal"
                  />
                </label>
              </div>

              <div className="flex items-center gap-4">
                <input
                  name={'payment-method'}
                  type={'radio'}
                  disabled
                  id={'stripe'}
                  className="w-4 h-4"
                  onClick={handlePaymentMethod}
                  value={'stripe'}
                />
                <label
                  className="flex items-center justify-center gap-4 font-bold"
                  htmlFor={'stripe'}
                >
                  <img width={60} height={23} src="stripe-4.svg" alt="stripe" />
                </label>
              </div>
            </div>
            <p className="text-xs mt-10 text-center font-Poppins ">
              Don&apos;t checkout until you&apos;re through editing your payment
              option.
            </p>
            {/* <p className="text-center text-xs mb-6">
              Edits are not allowed after checkout.
            </p> */}
          </div>
        </section>

        <section
          className={`lg:mt-16 mx-auto sm:w-3/5 lg:w-2/5  w-11/12 pb-6 mb-16 lg:mb-0`}
        >
          <h1 className="text-xl text-[#334252] py-4 border-b-2 border-[#E5E5E5] text-center">
            Checkout
          </h1>
          {paymentMethod === 'paystack' && (
            <Paystack
              loading={loading}
              handleLoading={(arg) => setLoading(arg)}
              planInfo={subscriptionPlan}
            />
          )}
          {paymentMethod === 'paypal' && (
            <CardPayment
              loading={loading}
              handleLoading={(arg) => setLoading(arg)}
              planInfo={subscriptionPlan}
            />
          )}

          {paymentMethod === 'flutterwave' && (
            <FlutterWave handlePayment={handlePayment} />
          )}
        </section>
      </main>
    </div>
  );
}
