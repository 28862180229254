import React from 'react';
// import FooterTwo from '../components/FooterTwo';
import Prices from '../components/Prices';

const PricingPage = () => {
  return (
    <>
      <Prices />
      {/* <FooterTwo /> */}
    </>
  );
};

export default PricingPage;
