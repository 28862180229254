import React, { useEffect, useState } from 'react';
import FooterTwo from '../components/FooterTwo';
import Navbar from '../components/Navbar/Navbar';
import Back from '../assets/back-arrow.png';
import axios from 'axios';
import { Link } from 'react-router-dom';

const AddAdminPage = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const access_token = localStorage.getItem('token');
    setAccessToken(access_token);
  }, []);

  console.log(accessToken, 'token');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      role,
    };

    if (
      firstName === '' ||
      lastName === '' ||
      email === '' ||
      password === '' ||
      role === ''
    ) {
      alert('All field are required');
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/admin/signup`,
        data,
      );

      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setRole('');

      console.log(response);
      if (response.status === 200) {
        alert('Admin added successfully');
      } else {
        alert('An error occured, retry');
      }

      setIsSubmitting(false);
    } catch (err) {
      console.log(err);
      setIsSubmitting(false);
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setRole('');

      alert('An error occured retry');
    }
  };
  return (
    <div>
      <Navbar />
      <section style={{ minHeight: '75vh' }} className="help-center-subpage">
        <div className=" container mx-auto font-avenir px-5 py-24">
          <div className="mt-3">
            <Link
              to="/admindashboard"
              className="flex items-center gap-3 no-underline font-semibold"
            >
              <span>
                <img src={Back} alt="" width="11" />
              </span>
              <span className="text-[#464646] text-lg">Go Back</span>
            </Link>
          </div>
          <div className="w-full md:w-4/5 lg:w-2/5">
            <div className="mt-12 w-full">
              <h2 className="text-4xl md:text-4xl text-[#464646] mb-5">
                Add a New Admin
              </h2>
            </div>

            <div className="bg-[#fafafa] px-5 py-10">
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <label htmlFor="">First Name</label>
                  <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    className="block w-full p-2 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                    placeholder="Enter Name"
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="">Last Name</label>
                  <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    className="block w-full p-2 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                    placeholder="Enter Name"
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full p-2 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                    placeholder="Enter Email"
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="">Password</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full p-2 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                    placeholder="Enter Name"
                  />
                </div>

                <div className="mb-5">
                  <label htmlFor="">Select a role</label>
                  <select
                    name=""
                    id=""
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                    className="block w-full p-2 font-avenir text-lg text-[#464646] border border-[#464646] border-solid outline-none"
                  >
                    <option value="" disabled selected>
                      Choose
                    </option>
                    <option value="admin">Admin</option>
                    <option value="contributor">Contributor</option>
                    <option value="editor">Editor</option>
                  </select>
                </div>

                <div>
                  <button
                    className="w-full block bg-[#D2120F] text-[#fff] p-4 outline-none border-2 text-lg border-[#D2120F]"
                    style={{ position: 'relative' }}
                  >
                    Add Admin{' '}
                    {isSubmitting ? (
                      <div className="loader-box">
                        <span className="loader mx-auto"></span>
                      </div>
                    ) : null}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <FooterTwo />
    </div>
  );
};

export default AddAdminPage;
