import { AdminAuthContext } from '../context/AdminAuthContext';
import { useContext } from 'react';

export const useAuthContext = () => {
    const context = useContext(AdminAuthContext);

    if (!context) {
        throw new Error(
            'useAuthContext must be inside an AdminAuthContextProvider',
        );
    }

    return context;
};