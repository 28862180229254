import axios from 'axios';

export default function slackError(err) {
  axios
    .post(
      'https://hooks.slack.com/services/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',
      { data: `${err} user tried to generate a letter` },
    )
    .then((res) => console.log(res))
    .catch((err) => console.error(err));
}
