import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import CookieConsent from 'react-cookie-consent';
import styles from '../styles/Cookie.module.css';

const CookiesPopup = () => {
  const [popup, setPopup] = useState(true);


  const togglePopup = () => {
    setPopup(!popup);
  };

  return (
    <>
      <div className={styles.center_modal}>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1.1, duration: 1.5 }}
        >
          {popup && (
            // eslint-disable-next-line react/jsx-no-undef
            <CookieConsent
              disableStyles="true"
              containerClasses={styles.cookie__modal}
              buttonWrapperClasses={styles.cookie__modal__buttons}
              buttonClasses={styles.button_d}
              buttonText="Accept Cookies"
              declineButtonText="Reject All"
              declineButtonClasses={styles.button}
              enableDeclineButton="true"
              onAccept={(acceptedByScrolling) => {
                if (acceptedByScrolling) {
                  <p className={styles.cookie_notice}>
                    You Accepted Our Cookies
                  </p>;
                } else {
                  <p className={styles.cookie_notice}>
                    You Rejected Our Cookies
                  </p>;
                }
              }}
            >
              <span className={styles.close_it} onClick={togglePopup}>
                <FontAwesomeIcon icon={faClose} />
              </span>
              <div className={styles.cookie__modal__text}>
                <h5>Cookie Policies</h5>
                <p>
                  We use cookies to enhance your browsing experience, serve
                  personalized ads or content, and analyze our traffic. By
                  clicking “Accept Cookies”, you consent to our use of cookies.{' '}
                  <Link to="/cookie-policy">Read More</Link>
                </p>
              </div>
            </CookieConsent>
          )}
        </motion.div>
      </div>
    </>
  );
};

export default CookiesPopup;
