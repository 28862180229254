import Button from './Button/Button';
import propTypes from 'prop-types';

export default function SignupSuccessful({
  signupSuccess,
  handleClick,
  userName,
}) {
  const handleLogin = handleClick;
  const welcomeName = userName;
  const handlePopup = signupSuccess;
  return (
    handlePopup && (
      <div className="fixed top-0 w-full h-full flex justify-center items-center bg-black/40 z-50">
        <div className=" w-10/12 max-w-2xl bg-white rounded-2xl flex flex-col justify-center items-center py-6 ">
          <img
            src="./sign-up successful.svg"
            alt="payment successful"
            className="mt-4 w-52 "
          />
          <h1 className="text-[#04172A] mt-4 text-center md:text-2xl text-xl">
            Cheers! Account successfully created
          </h1>
          <h2 className="text-[#464646] text-lg mb-10">
            For <strong className="capitalize">{welcomeName}</strong>!
          </h2>
          <div className=" max-w-xs w-4/5">
            <Button
              name={'Close'}
              width={'100%'}
              height={'40px'}
              onClick={handleLogin}
            />
          </div>
        </div>
      </div>
    )
  );
}

SignupSuccessful.propTypes = {
  signupSuccess: propTypes.bool.isRequired,
  handleClick: propTypes.func.isRequired,
  userName: propTypes.string.isRequired,
};
