import { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import FreetrialModal from '../FreetrialModal';
import slackError from './util';
import '../../styles/survey.css';

export default function GenerateForm({ classStyle }) {
  const navigate = useNavigate();
  //const { setLetterId } = useLetterId();
  // console.log('letterId before api call =', letterId)
  const [modal, setModal] = useState(false);
  // const inscriptRef = React.useRef();

  /*===For new Year===**/
  const relationship = [
    { name: 'relationship', value: 'wife', label: 'Wife' },
    { name: 'relationship', value: 'husband', label: 'Husband' },
    { name: 'relationship', value: 'daughter', label: 'Daughter' },
    { name: 'relationship', value: 'son', label: 'Son' },
    { name: 'relationship', value: 'girlfriend', label: 'Girlfriend' },
    { name: 'relationship', value: 'boyfriend', label: 'Boyfriend' },
    { name: 'relationship', value: 'friend', label: 'Friend' },
    { name: 'relatiinship', value: 'colleague', label: 'Colleague' },
    // { name: 'relationship', value: 'others', label: 'Others' },
  ];
  const occassion = [
    { name: 'occasion', value: 'anniversary', label: 'Anniversary' },
    { name: 'occasion', value: 'birthday', label: 'Birthday' },
    { name: 'occasion', value: 'christmas', label: 'Christmas' },
    { name: 'occasion', value: 'graduation', label: 'Graduation' },
    { name: 'occasion', value: "valentine's day", label: "Valentine's day" },
    { name: 'occasion', value: 'new year', label: 'New Year' },
  ];
  const inscription = [
    {
      name: 'inscription',
      value: 'reason why i love you',
      label: 'Reason why I love you',
    },
    {
      name: 'inscription',
      value: 'why you are my everything',
      label: 'Why you are my everything',
    },
    {
      name: 'inscription',
      value: 'why i love you so much',
      label: 'Why I love you so much',
    },
    {
      name: 'inscription',
      value: 'why i care so much about you',
      label: 'Why I care so much about you',
    },
    {
      name: 'inscription',
      value: "Why I'm thanking you",
      label: 'Thank you',
    },
    // { name: 'inscription', value: 'others', label: 'Others' },
  ];
  /**===End of for new year====**/

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(false);
  const [localForm] = useState(
    JSON.parse(localStorage.getItem('letterForm')) || null,
  );
  const [localDefault, setLocalDefault] = useState({
    relationship:
      relationship.findIndex((x) => x.value == localForm?.relationship) || '',
    occasion: occassion.findIndex((x) => x.value == localForm?.occasion) || '',
    inscription: inscription.findIndex(
      (x) => x.value == localForm?.inscription,
    ),
  });

  const [formData, setFormData] = useState(
    localForm || {
      partner_name: '',
      occasion: '',
      relationship: '',
      inscription: '',
      custom_words: '',
    },
  );
  formData;
  const token = localStorage.getItem('token');

  //console.log('token', token);
  useEffect(() => {
    if (localForm) {
      const rela = relationship.findIndex(
        (x) => x.value == localForm.relationship,
      );
      const insc = inscription.findIndex(
        (x) => x.value == localForm.inscription,
      );

      const occa = occassion.findIndex((x) => x.value == localForm.occa);
      setLocalDefault((localDefault) => ({ ...localDefault, occasion: occa }));
      setLocalDefault((localDefault) => ({
        ...localDefault,
        relationship: rela,
      }));
      setLocalDefault((localDefault) => ({
        ...localDefault,
        inscription: insc,
      }));
    }
  }, [localForm]);

  // console.log(formData);
  const rindex = localDefault.relationship;
  const oindex = localDefault.occasion;
  const iindex = localDefault.inscription;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      border: '1px solid #EAECF0',
      backgroundColor: state.isSelected ? 'grey' : '#FCFCFD',
      fontWeight: '600',
      cursor: 'pointer',
    }),
    control: (provided, state) => ({
      ...provided,
      // color: '#D0D5DD',
      cursor: 'pointer',
      boxShadow: state.isFocused ? null : null,
      border: state.isFocused ? '1.5px solid #D0D5DD' : '1.5px solid #D0D5DD',
      '&:hover': {
        // Overwrittes the different states of border
        border: state.isFocused ? '1.5px solid #D0D5DD' : '1.5px solid #D0D5DD',
      },
    }),
  };
  function toggleModal() {
    setModal((prev) => !prev);
  }
  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  }
  function handleInscription(name) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        inscription: name ? name.value : '',
      };
    });
  }
  function handleOccassion(name) {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        occasion: name ? name.value : '',
      };
    });
  }
  function handleRelationship(name) {
    // console.log('name:', name);
    // console.log('value:', value ? value : null);
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        relationship: name ? name.value : '',
      };
    });
  }
  // useEffect(() => {

  // }, [])

  function handleSubmit(e) {
    e.preventDefault();
    !token ? navigate('/signin') : null;
    setLoading(true);
    const options = {
      url: 'letter',
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: formData,
    };
    axios(options)
      .then((res) => {
        if (
          res.data.message === 'Please subscribe to be able to generate letter'
        ) {
          // console.log("yeah")
          setLoading(false);
          setModal(true);
        } else {
          setErrors(false);
          setLoading(false);
          //   setLetterId(res.data.letter_id);
          localStorage.setItem('letterId', res.data.letter_id);
          localStorage.removeItem('letterForm');
          navigate('/previewletter');
        }
      })
      .catch((err) => {
        localStorage.setItem('letterForm', JSON.stringify(formData));
        console.log(err);
        try {
          slackError(err);
          setLoading(false);
          setErrors(true);
        } catch (err) {
          console.log(err);
          setLoading(false);
          setErrors(true);
        }
      });
  }
  const styling = classStyle ? classStyle : null;
  return (
    <>
      {modal && <FreetrialModal toggleModal={toggleModal} />}

      <div className={`${styling} writer`}>
        <div className={`${styling}-container writer__form-container`}>
          <div className={`writer__form-leading`}>
            Tell us about your loved one
          </div>
          <form onSubmit={handleSubmit}>
            <div className="writer__input-container">
              <label htmlFor="">What is your loved one&apos;s name?</label>
              <input
                type="text"
                name="partner_name"
                id="partner_name"
                onChange={handleChange}
                value={formData.partner_name}
                required={true}
              />
            </div>
            <div className="writer__input-container">
              <label htmlFor="relationship">Who is this for?</label>
              <CreatableSelect
                isClearable
                options={relationship}
                defaultValue={relationship[rindex] || ''}
                styles={customStyles}
                id="relationships"
                placeholder="type or select an option"
                onChange={(name) => handleRelationship(name)}
                name="relationship"
              />
            </div>
            <div className="writer__input-container">
              <label htmlFor="occassion">What is the occassion?</label>
              <CreatableSelect
                isClearable
                defaultValue={occassion[oindex] || ''}
                options={occassion}
                styles={customStyles}
                id="occassion"
                placeholder="type or select an option"
                onChange={(name) => handleOccassion(name)}
                required
              />
            </div>
            <div className="writer__input-container">
              <label htmlFor="inscription">Choose an Inscription</label>
              <CreatableSelect
                isClearable
                // ref={inscriptRef}
                defaultValue={inscription[iindex] || ''}
                options={inscription}
                styles={customStyles}
                id="inscription"
                placeholder="type or select an option"
                onChange={(name) => handleInscription(name)}
                required
                name="inscription"
              />
            </div>

            <div className="writer__input-container">
              <label htmlFor="">Do you have any personal thing to say?</label>
              <textarea
                className="writer__textarea"
                name="custom_words"
                id="custom_words"
                placeholder="Optional"
                onChange={handleChange}
                value={formData.custom_words}
              />
            </div>

            {!errors && (
              <button
                className={`${styling}-submit writer__form-submit`}
                type="submit"
              >
                {loading ? 'generating...' : 'Generate'}
              </button>
            )}
            {errors && (
              <button className="writer__form-retry" type="submit">
                {loading ? 'loading...' : 'Try again'}
              </button>
            )}
            {errors && <p className="writer__form-error">error occurred</p>}
          </form>
        </div>
      </div>
    </>
  );
}

GenerateForm.propTypes = {
  classStyle: PropTypes.string,
};
