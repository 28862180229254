import React, { useState, useEffect} from 'react';
//import InputField from '../InputField/InputField';
// import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Profile/Profile.css'

// const url =  `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/me/update-user`

export function SecurityInfo() {
  const [editMode, setEditMode] = useState(false);
  // const [password, setPassword] = useState('')
  const [accountRecoveryEmail, setAccountRecoveryEmail] = useState('')
  // const [confirmPassword, setConfirmPassword] = useState('')
  // const [accessToken, setAccessToken] = useState('')
  const success = () => {
    toast.success('Saved!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };

  useEffect( () => {
    // const access_token = localStorage.getItem('token')
    // setAccessToken(access_token)
    setAccountRecoveryEmail(localStorage.getItem("recovery_email"))
      
  }, [])

  

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("recovery_email", accountRecoveryEmail)
    setAccountRecoveryEmail(localStorage.getItem("recovery_email"))
     success()
     setEditMode(false)

  };


  //  useRef(()=>{
  //     const userInfo = localStorage.getItem('user')
  //     console.log(userInfo)
  //  }, [])


  return (
    <div>
      <div className="flex items-center gap-4 mb-6">
        <h2 className="text-xl">Security</h2>
        {!editMode && (
          <img
            src="/edit-alt.svg"
            alt="edit"
            onClick={() => setEditMode(true)}
          />
        )}
      </div>

      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">

          {/* <div> 
          <label htmlFor="" className='profilelabel'>Password</label>
          <input type="password" name="password" className='profilename block' value={password} onChange={(e) => setPassword(e.target.value)} />
          </div> */}
            

          <div> 
          <label htmlFor="" className='profilelabel'>Account recovery Email</label>
          <input
            className='profilename block pl-2'
            name="account_recov"
            placeholder="example@email.com"
            value={accountRecoveryEmail}
            onChange={(e) => setAccountRecoveryEmail(e.target.value)}
          />
          </div>
        </div>
        {/* {editMode && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
            <div> 
            <label htmlFor="" className='profilelabel'>PassWord</label>
            <input
            className='profilename block'
              type="password"
              name="confirm_password"
              value={password}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            </div> 
          </div>
        )}*/}

        {editMode && (
          <div className="flex items-center gap-4">
            <button
              type="submit"
              className="bg-main-1 hover:bg-main-2 transition-all px-8 h-[45px] rounded-md text-white"
            >
              Save changes
            </button>
            <button
              type="button"
              className="px-8 h-[45px] rounded-md border-2"
              style={{
                border: '0.3px solid #d0d5dd',
              }}
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <ToastContainer/>
    </div>
  );
}
