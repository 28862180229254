import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../../components/Logo';
import '../../styles/SignUp.css';
import { eyeIcon, eyeCancel } from '../../assets';
import ErrorPopUp from '../../components/ErrorPopUp';
import SignupSuccessful from '../../components/SignupSuccessful';
import { useAuthContext } from '../../hooks/useAuthContext';

export default function SignUp() {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorFname, setErrorFname] = useState(false);
  const [errorLname, setErrorLname] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailValidError, setEmailValidError] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [signupSuccess, setSignupSuccess] = useState(false);
  const { dispatch } = useAuthContext();
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  const [navigateToVerify, setNavigateToVerify] = useState(false);

  const onLoginToDashboard = () => {
    const userInfo = userDetails;
    dispatch({ type: 'SIGNUP', payload: userDetails.user });
    localStorage.setItem('token', userInfo.access_token);
    localStorage.setItem('refresh_token', userInfo.refresh_token);
    localStorage.setItem('user', JSON.stringify(userDetails.user));
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${localStorage.getItem('token')}`;
    navigate('/dashboard');
  };
  // Setting the input errors to false when there's a change in input
  const validateEmail = (e) => {
    return String(e)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  };
  setTimeout(() => {
    if (error) {
      setError(false);
      setErrorMsg('');
    }
  }, [3000]);
  useEffect(() => {
    if (fname) {
      setErrorFname(false);
    }
    if (lname) {
      setErrorLname(false);
    }
    if (email) {
      setErrorEmail(false);
      setEmailValidError(false);
    }
    if (validateEmail(email)) {
      setEmailValidError(false);
    }
    if (!password || password.length >= 6) {
      setErrorPassword(false);
    }
  }, [fname, lname, email, password]);

  const passwordToggle = () => {
    showPassword ? setShowPassword(false) : setShowPassword(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log("working")
    if (!fname) {
      setErrorFname(true);
    } else if (!lname) {
      setErrorLname(true);
    } else if (!email) {
      setErrorEmail(true);
      setEmailValidError(false);
    } else if (!validateEmail(email)) {
      setEmailValidError(true);
    } else if (!password || password.length < 6) {
      setErrorPassword(true);
    } else if (fname && lname && email && password.length >= 6) {
      // IF no error the form can be submitted successfully
      const res = await axios.post('auth/v2/signup/', {
        first_name: fname,
        last_name: lname,
        email,
        password,
      });
      console.log(res.status);
      if (res.status == 201) {
        setSignupSuccess(true);
        setUserDetails(res.data);
        setNavigateToVerify(true);
        localStorage.setItem('signup', email);
      } else {
        setErrorMsg(res.response.data.detail);
        setError(true);
        setNavigateToVerify(false);
      }
      setErrorFname(false);
      setErrorLname(false);
      setErrorEmail(false);
      setErrorPassword(false);
      setEmailValidError(false);
    }
  };

  // Google signup
  const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/google`;
  const handleCallbackResponse = async (response) => {
    const googleResp = response.credential;

    const endpointResp = await axios.post(url, googleResp);
    if (endpointResp.status === 200) {
      setSignupSuccess(true);
      setUserDetails(endpointResp.data);
    } else {
      setErrorMsg(endpointResp.statusText);
      setError(true);
    }
  };
  // initialize google signup
  useEffect(() => {
    /* global google */
    google.accounts.id.initialize({
      client_id:
        '10218577074-h040734jcjiiutsmoucimh0oieid6rc6.apps.googleusercontent.com',
      callback: handleCallbackResponse,
    });
    google.accounts.id.renderButton(document.getElementById('signUpDiv'), {
      theme: 'outline',
      size: 'large',
      text: 'signup_with',
    });
    google.accounts.id.prompt();
  }, []);

  if (navigateToVerify) {
    return <Navigate to="/verify" />;
  }

  return (
    <div className="relative flex justify-center items-center w-full font-avenir flex-col">
      {/* Sign up successful popup */}
      <SignupSuccessful
        userName={`${userDetails?.user.first_name} ${userDetails?.user.last_name}`}
        signupSuccess={signupSuccess}
        handleClick={onLoginToDashboard}
      />

      <div className="w-full   md:py-[56px] py-[32px] flex flex-col items-center">
        <header className="px-10 w-full flex justify-center items-center">
          <Logo />
        </header>
        <div className="font-[400] mt-[40px]">
          <h1 className="text-[40px] text-center">Create an account</h1>
        </div>
        <form
          className=" mt-[30px] mx-auto w-11/12 max-w-lg flex flex-col gap-[24px]"
          onSubmit={handleSubmit}
        >
          <label className="flex flex-col gap-[8px] w-full">
            <span className="text-gray-700 text-[14px] font-[500]">
              First name
            </span>
            <input
              className={`input border ${
                errorFname
                  ? 'border-[#F83F23] input-error-border'
                  : 'border-gray-300 input-border'
              } rounded-[8px] md:h-12 py-[10px] px-[14px] outline-[#475467]`}
              type="text"
              placeholder="Enter your first name"
              onChange={(e) => setFname(e.target.value)}
            />
            {errorFname && (
              <span className="text-[#F83F23] text-[14px]">
                Please enter your first name
              </span>
            )}
          </label>
          <label className="flex flex-col gap-[8px] w-full">
            <span className="text-gray-700 text-[14px] font-[500]">
              Last name
            </span>
            <input
              className={`border ${
                errorLname
                  ? 'border-[#F83F23] input-error-border'
                  : 'border-gray-300 input-border'
              } rounded-[8px] md:h-12 py-[10px] px-[14px] outline-[#475467] input`}
              type="text"
              placeholder="Enter your last name"
              onChange={(e) => setLname(e.target.value)}
            />
            {errorLname && (
              <span className="text-[#F83F23] text-[14px]">
                Please enter your last name
              </span>
            )}
          </label>
          <label className="flex flex-col gap-[8px] w-full">
            <span className="text-gray-700 text-[14px] font-[500]">Email</span>
            <input
              className={`border ${
                errorEmail || emailValidError
                  ? 'border-[#F83F23] input-error-border'
                  : 'border-gray-300 input-border'
              } rounded-[8px] md:h-12 py-[10px] px-[14px] outline-[#475467] input`}
              type="text"
              placeholder="name@gmail.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            {errorEmail && (
              <span className="text-[#F83F23] text-[14px]">
                Please enter your email address
              </span>
            )}
            {emailValidError && (
              <span className="text-[#F83F23] text-[14px]">
                Email is incorrect
              </span>
            )}
          </label>
          <label className="flex flex-col gap-[8px] w-full">
            <span className="text-gray-700 text-[14px] font-[500]">
              Password
            </span>
            <div className="flex w-full relative">
              <input
                className={`w-full border ${
                  errorPassword
                    ? 'border-[#F83F23] input-error-border'
                    : 'border-gray-300 input-border'
                } rounded-[8px] md:h-12 py-[10px] px-[14px] outline-[#475467] input`}
                type={showPassword ? 'text' : 'password'}
                placeholder="Enter password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {showPassword && (
                <img
                  className="absolute top-[13px] right-[16px] cursor-pointer"
                  src={eyeIcon}
                  alt="eyeicon"
                  onClick={() => passwordToggle()}
                />
              )}
              {!showPassword && (
                <img
                  className="absolute top-[11px] right-[16px] cursor-pointer"
                  src={eyeCancel}
                  alt="eyeicon"
                  onClick={() => passwordToggle()}
                />
              )}
            </div>
            {errorPassword && (
              <span className="text-[#F83F23] text-[14px]">
                Password must contain at least 6 characters
              </span>
            )}
          </label>
          <div className="text-[16px] text-center">
            <span>By Registering you are automatically accepting our </span>
            <div className="flex gap-[5px] justify-center">
              <Link to="/termsofservice" className="text-[#D2120F] font-[850]">
                Terms{' '}
              </Link>{' '}
              and
              <Link to="/privacypolicy" className="text-[#D2120F] font-[850]">
                {' '}
                Privacy Policy{' '}
              </Link>
            </div>
          </div>
          <label className="flex flex-col gap-[6px] w-full mt-[8px]">
            <input
              className="cursor-pointer border border-[#D2120F] rounded-[8px] py-[12px] px-[14px] bg-[#D2120F] text-white hover:bg-white hover:text-[#D2120F] transition-all"
              id="btn__submit"
              type="submit"
              value="Create Account"
            />
          </label>
          <div className="mb-14 flex gap-[5px] justify-center">
            <p className="text-[16px]">Have an account? </p>
            <Link
              to="/signin"
              className="text-[#D2120F] font-[850] text-[16px]"
            >
              Login
            </Link>{' '}
          </div>
        </form>
        {/* GOOGLE SIGNUP */}
        <div id="signUpDiv"></div>
      </div>

      <ErrorPopUp error={error} message={errorMsg} />
    </div>
  );
}
