import React from 'react';
import { questions } from '../data.js';
import Navbar from '../components/Navbar/Navbar.jsx';
import FooterTwo from '../components/FooterTwo.jsx';
import { Faq } from '../faq';
import '../index.css';

function Faqpage() {
  return (
    <div className="App">
      <Navbar />
      <div className=""></div>
      <section className="mb-[56px] w-full mt-5">
          </section>
        <div className="ask h-[150px] md:h-[232px] flex justify-center items-center">
          <h1 className="text-3xl md:text-[64px] text-center leading-tight">
            Frequently Asked Questions
          </h1>
        </div>

        <div className="flex flex-col md:gap-[36px] md:mx-[50px] p-5 mb-4 md:mt-3">
          {questions.map((item) => {
            const { id, title, info } = item;
            return <Faq key={id} title={title} info={info} />;
          })}
        </div>
      <FooterTwo />
    </div>
  );
}

export default Faqpage;
