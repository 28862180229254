

import React from 'react'
import PropTypes from 'prop-types';


export const AdminCheckbox = ({id,inx}) => {

   
    return (
        <>
            <input  data-id={`${id}`} data-index={`${inx}`} className='usercheck' type='checkbox' />
        </>
    )

}

AdminCheckbox.propTypes = {
    id: PropTypes.number,
    inx: PropTypes.number,
    setUsers: PropTypes.func,
    users: PropTypes.array,
}
