import React from 'react';
import { background, definitions, accounts, subscription } from './TermsConfig';
import Navbar from '../components/Navbar/Navbar';
import FooterTwo from '../components/FooterTwo';



export default function TermsOfService() {
  return (
    <>
      <Navbar />
      <section>
        <div className="bg-yellow-600 bg-banner bg-no-repeat bg-cover bg-bottom  flex flex-row items-center justify-center h-80 mt-10">
            <h2 className="text-white font-semibold">Terms of Service</h2>{''}
        
        </div>
      <div className="container w-full  p-5 px-10 mb-10  terms">
    
        <div className="text-sec-2 text-xl my-5 sm:pt-5">
          <div>
            <p className="py-3"> Write a Letter to your loved ones. </p>{' '}
            <p className="py-3"> LoveMe Letter writer. </p>{' '}
            <p className="py-3 font-semibold"> Terms of service. </p>{' '}
          </div>{' '}
          <div>
            <h1 className="py-6 mt-5 text-3xl"> 1. Background </h1>{' '}
            {background.map((text, index) => (
              <p className="py-3" key={index}>
                {' '}
                {text}{' '}
              </p>
            ))}{' '}
          </div>{' '}
          <div>
            <h1 className="py-6 mt-5 text-3xl"> 2. Definitions </h1>{' '}
            {definitions.map((text, index) => (
              <p className="py-3" key={index}>
                {' '}
                {text}{' '}
              </p>
            ))}{' '}
          </div>{' '}
          <div>
            <h1 className="py-6 mt-5 text-3xl"> 3. Creating an Account </h1>{' '}
            {accounts.map((text, index) => (
              <p className="py-3" key={index}>
                {' '}
                {text}{' '}
              </p>
            ))}{' '}
          </div>{' '}
          <div>
            <h1 className="py-6 mt-5 text-3xl"> 4. LoveMe Subscription </h1>{' '}
            {subscription.map((text, index) => (
              <p className="py-3" key={index}>
                {' '}
                {text}{' '}
              </p>
            ))}{' '}
          </div>{' '}
        </div>{' '}
      </div>{' '}
      </section>
      <FooterTwo />
    </>
  );
}
