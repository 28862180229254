import React, { useState } from 'react';

export const ChatContext = React.createContext({
  status,
  first_name:"",
  chatMessages: [],
  dataCollectorHandler: () => {},
  statusHandler:()=>{},
  defaultHandler:()=>{},
  firstNameHandler:()=>{}

});
export const ChatContextProvider = (prop) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [status, setStatus] = useState(0);
  const [first_name, setFirstName] = useState("");


  const dataCollectorHandler = (incomingData,first_name) => {
    if(first_name){
      setFirstName(incomingData)
      return
    }
    const newMessage = {
        message: incomingData,
      };
      setChatMessages((prevState) => {
       Object.assign(newMessage,{
          id:prevState.length,
        })
      
        return [...prevState, newMessage];
      });
    
  };
  const statusHandler = (incomingData) => {
    setStatus(incomingData)
  
    
};

  const sharedData = {
    status,
    chatMessages,
    first_name,
    dataCollectorHandler,
    statusHandler,
  
  
    
  };
  return (
    <ChatContext.Provider value={sharedData}>
      {}
      {prop.children}
      {}
    </ChatContext.Provider>
  );
};
