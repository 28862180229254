

import { useLocation, Navigate } from "react-router-dom" 
import PropTypes from 'prop-types';

export const AdminRouter = ({ children }) => {

    const { pathname } = useLocation()


    const Admin = localStorage.getItem("admintoken")

    if(!Admin && (pathname === "/admindashboard")) {
        return  <Navigate to="/adminsignin" />
        
    }

    if(Admin && (pathname === "/adminsignin" || pathname === "/adminsignup" )){ 
        return <Navigate to="/admindashboard" />
    }

    return (
        <>{children}</>
    )

}


AdminRouter.propTypes = {
    children:PropTypes.node
}
