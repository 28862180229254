// import { toast } from 'react-toastify/dist/components';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { logo } from '../assets';

let currentOTPIndex = 0;
const VerifyEmail = () => {
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch } = useAuthContext();

  const inputRef = useRef(null);

  const navigate = useNavigate();
  const email = localStorage.getItem('signup');

  const code = `${otp[0]}${otp[1]}${otp[2]}${otp[3]}${otp[4]}${otp[5]}`;

  function checkComplete() {
    for (let i = 0; i < otp.length; i++) {
      if (otp[i] === '') {
        return true;
      }
    }
  }
  const handleOnChange = (e) => {
    const { value } = e.target;
    // const val = value.substring(value.length - 1);
    const newOTP = [...otp];
    newOTP[currentOTPIndex] = value.substring(value.length - 1);

    if (!value) setActiveOTPIndex(currentOTPIndex - 1);
    else setActiveOTPIndex(currentOTPIndex + 1);
    setOtp(newOTP);
    // checkComplete();
    // console.log(val);
  };

  const handleOnKeyDown = ({ key }, index) => {
    currentOTPIndex = index;
    if (key === 'Backspace') setActiveOTPIndex(currentOTPIndex - 1);
    // checkComplete();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(code);
    setLoading(false);
    const complete = checkComplete();
    console.log('complete', complete);
    if (complete) {
      setOtp(new Array(6).fill(''));
      return setError(true);
    } else {
      setError(false);
    }
    setLoading(true);
//     const options = {
//       url: '/auth/verify-email',
//       method: 'POST',
//       headers: {
//         'Access-Control-Allow-Origin': '*',
//       },
//       data: { token: code },
//     };
 //   console.log(options);
    axios.post('/auth/verify-email', {
    token: code
    })
      .then((res) => {
        console.log(res);
        setLoading(false);
        if (email == res.data.user.email) {
          const userDetails = res.data.user;
          dispatch({ type: 'SIGNUP', payload: userDetails });
          localStorage.setItem('token', res.data.access_token);
          localStorage.setItem('refresh_token', res.data.refresh_token);
          localStorage.setItem('user', JSON.stringify(userDetails));
          navigate('/');
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setError(true);
        setLoading(false);
      });
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);
  return (
    <>
      <div className="flex flex-col h-screen justify-center">
        <div className="">
          <div className="flex items-center justify-center font-Poppins gap-2 mb-12">
            <img src={logo} alt="logo" />
            <span className="font-Poppins text-3xl font-bold">LoveMe</span>
          </div>
          <div className="">
            <h1 className="text-[40px] text-center font-Poppins mt-4">
              Verify Email
            </h1>
            <p className="text-[16px] mt-4 text-center font-Poppins">
              Hello User, Check your email for verification code.
            </p>
            {error && (
              <p className="text-main-1 mt-4 text-center font-Poppins">
                Wrong Code!
              </p>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit} className="text-center">
          <div className="flex w-[80vw] mx-auto mt-8 gap-2 items-center justify-center">
            {otp.map((_, index) => {
              return (
                <div key={index}>
                  <input
                    ref={index === activeOTPIndex ? inputRef : null}
                    type="number"
                    className="w-12 h-12 md:w-20 md:h-20 text-xl appearance-none text-center 
                border-none rounded-md
                focus:outline-main-2 focus:border-none
                spin-button-none"
                    onChange={(e) => handleOnChange(e)}
                    onKeyDown={(e) => handleOnKeyDown(e, index)}
                    value={otp[index]}
                  />
                </div>
              );
            })}
          </div>
          <button className="w-36 py-2 bg-main-2 rounded-md text-white border-none font-Poppins font-bold text-lg mx-auto mt-6">
            {loading ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      </div>
    </>
  );
};

export default VerifyEmail;
