import React, { useEffect, useState } from 'react';
import '../../styles/Navbar.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import useDropoff from '../../hooks/useDropoff';
import axios from 'axios';
import Button from '../Button/Button';

export default function Navbar() {
  const [dropdown, setdropdown] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const { user, dispatch } = useAuthContext();
  const navigate = useNavigate();
  const [image, setImage] = useState('');
  const [logout, setLogout] = useState(false);
  const apiURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    axios
      .get('/user/me/get-user', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        //console.log(res.data.image)
        if (res.data.image !== null) {
          setImage(res.data.image);
        }
      });
  }, []);

  function handleDropdown() {
    setdropdown((prev) => !prev);
  }
  function handleDashboard() {
    setDashboard((prev) => !prev);
  }
  function handleDropOutside() {
    setdropdown(false);
  }
  function handleDashOutside() {
    setDashboard(false);
  }
  function HandleLogout() {
    axios
      .post(
        '/auth/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then(() => {
        // console.log(res)

        dispatch({ type: 'LOGOUT' });

        document.cookie.split(';').forEach(function (c) {
          document.cookie = c
            .replace(/^ +/, '')
            .replace(
              /=.*/,
              '=;expires=' + new Date().toUTCString() + ';path=/',
            );
        });

        setLogout(false);

        navigate('/signin');
      })
      .catch((err) => console.log(err));
  }

  const ref = useDropoff(handleDashOutside);
  const ref2 = useDropoff(handleDropOutside);
  return (
    <header className="navbar shadow">
      {logout && (
        <div className="fixed top-0 w-full h-full flex justify-center items-center bg-black/40 z-50">
          <div className=" w-10/12 max-w-2xl bg-white rounded-2xl flex flex-col justify-center items-center py-6 ">
            <p className="font-bold text-center">
              Are you sure you want to logout?
            </p>
            <div className="flex justify-evenly items-center my-16 mb-8 w-full">
              <Button
                name={<p className="font-bold">No</p>}
                onClick={() => setLogout(false)}
                width={'30%'}
                height={'3rem'}
              />
              <Button
                name={<p className="font-bold">Yes</p>}
                onClick={HandleLogout}
                width={'30%'}
                height={'3rem'}
              />
            </div>
          </div>
        </div>
      )}
      <nav>
        <div className="navbar-container">
          <Link to="/" className="nav-home-link">
            <div className="nav-logo">
              <img
                src="/navbar-icon.svg"
                className="nav-logo--img"
                alt="navbar-icon"
              />
              <p className="nav-logo__text">LoveMe</p>
            </div>
          </Link>
          {/* <div id="google_translate_element" className="nav-links"></div> */}
          <div className="nav-desktop">
            <ul className="nav--links">
              <li>
                <Link to="/survey" className="nav-link">
                  Generator
                </Link>
              </li>
              <li>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="nav-link">
                  Contact Us
                </Link>
              </li>
              {!user && (
                <li>
                  <Link to="/signin" className="nav-link">
                    <button className="nav-login nav-desktop-btn">LogIn</button>
                  </Link>
                </li>
              )}
              {user && (
                <li className="nav-desktop-btn">
                  <div
                    ref={ref}
                    className="nav-link nav-dash"
                    onClick={handleDashboard}
                  >
                    <img src="/nav-arrow.svg" className="nav-dash-arrow" />
                    <img
                      src={`${
                        image?.length > 5 ? apiURL + image : '/user-solid.svg'
                      }`}
                      className="nav-profile"
                    />
                    {dashboard && (
                      <div className="nav-deskdashboard">
                        <Link className="dashboard-dbtn" to="/profile">
                          Profile
                        </Link>
                        <Link className="dashboard-dbtn" to="/dashboard">
                          Dashboard
                        </Link>
                        <button
                          onClick={() => setLogout(true)}
                          className="logout-dbtn"
                        >
                          Logout
                        </button>
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div ref={ref2} className="nav-mobile" onClick={handleDropdown}>
            <img className="nav-dropdown" src="/menu-burger.svg" />
          </div>
        </div>
        <div className="nav-mobile">
          {dropdown && (
            <ul className="nav--links">
              {user && (
                <li>
                  <Link to="/survey" className="nav-link">
                    Generator
                  </Link>
                </li>
              )}
              <li>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="nav-link">
                  Contact Us
                </Link>
              </li>
              <li>
                {!user && (
                  <Link to="/signin" className="nav-link">
                    LogIn
                  </Link>
                )}
                {user && (
                  <Link to="/profile" className="nav-link">
                    Profile
                  </Link>
                )}
              </li>
              {user && (
                <li>
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </li>
              )}
              {user && (
                <li>
                  <Link
                    onClick={() => setLogout(true)}
                    className="nav-link navm-logout"
                  >
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
}
