import React from 'react';
import "../../../styles/Navbar.css"
import { Link, useNavigate } from 'react-router-dom';
import useDropoff from '../../../hooks/useDropoff';
import usersolid from "../../../assets/user-solid.svg"

export default function AdminNavbar() {

  const navigate = useNavigate()
   
  const [dropdown, setdropdown] = React.useState(false);
  const [dashboard, setDashboard] = React.useState(false);
  function handleDropdown() {
    setdropdown((prev) => !prev);
  }
  function handleDashboard() {
    setDashboard((prev) => !prev);
  }
  function handleDropOutside() {
    setdropdown(false);
  }
  function handleDashOutside() {
    setDashboard(false);
  }
  const ref = useDropoff(handleDashOutside);
  const ref2 = useDropoff(handleDropOutside);

  const admin = localStorage.getItem("admin")

  const handleLogout = () => {
    localStorage.removeItem("admin")
    localStorage.removeItem("admintoken")
    localStorage.removeItem("adminrefresh_token")
    return navigate("/")
  }
  return (
    <header className="navbar">
      <nav>
        <div className="navbar-container">
          <Link to="/" className="nav-home-link">
            <div className="nav-logo">
              <img
                src="/navbar-icon.svg"
                className="nav-logo--img"
                alt="navbar-icon"
              />
              <p className="nav-logo__text">LoveMe</p>
            </div>
          </Link>
          {/* <div id="google_translate_element" className="nav-links"></div> */}
          <div className="nav-desktop">
            <ul className="nav--links">
              <li>
                <Link to="/survey" className="nav-link">
                  Generator
                </Link>
              </li>
              <li>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="nav-link">
                  Contact Us
                </Link>
              </li>
              {!admin && (
                <li>
                  <Link to="/signin" className="nav-link">
                    <button className="nav-login nav-desktop-btn">LogIn</button>
                  </Link>
                </li>
              )}
              {admin && (
                <li className="nav-desktop-btn">
                  <div
                    ref={ref}
                    className="nav-link nav-dash"
                    onClick={handleDashboard}
                  >
                    <img src="/nav-arrow.svg" className="nav-dash-arrow" />
                    <img src={usersolid} className="nav-profile" />
                    {dashboard && (
                      <div className="nav-deskdashboard">
                        <Link className="dashboard-dbtn" to="/profile">
                          Profile
                        </Link>
                        <Link className="dashboard-dbtn" to="/dashboard">
                          Dashboard
                        </Link>
                        <Link to="/">
                            <button
                            onClick={handleLogout}
                            className="logout-dbtn"
                            >
                            Logout
                            </button>
                        </Link>
                      </div>
                    )}
                  </div>
                </li>
              )}
            </ul>
          </div>

          <div ref={ref2} className="nav-mobile" onClick={handleDropdown}>
            <img className="nav-dropdown" src="/menu-burger.svg" />
          </div>
        </div>
        <div className="nav-mobile">
          {dropdown && (
            <ul className="nav--links">
              {admin && (
                <li>
                  <Link to="/survey" className="nav-link">
                    Generator
                  </Link>
                </li>
              )}
              <li>
                <Link to="/about" className="nav-link">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactus" className="nav-link">
                  Contact Us
                </Link>
              </li>
              <li>
                {!admin && (
                  <Link to="/signin" className="nav-link">
                    LogIn
                  </Link>
                )}
                {admin && (
                  <Link to="/profile" className="nav-link">
                    Profile
                  </Link>
                )}
              </li>
              {admin && (
                <li>
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </li>
              )}
              {admin && (
                <li>
                  <Link
                    onClick={handleLogout}
                    className="nav-link navm-logout"
                    to="/"
                  >
                    Logout
                  </Link>
                </li>
              )}
            </ul>
          )}
        </div>
      </nav>
    </header>
  );
}
