import React, { useState, useEffect } from 'react';
//import InputField from '../InputField/InputField';
// import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../Profile/Profile.css';

// const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/me/update-user`

export function LinkedAccounts() {
  const [editMode, setEditMode] = useState(false);
  const [twitter, setTwitter] = useState('');
  const [facebook, setFacebook] = useState('');
  // const [accessToken, setAccessToken] = useState('')
  // const url = `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/me/update-user`;
  // const token = localStorage.getItem('token');
  const success = () => {
    toast.success('Saved!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
  };
  // const errors = () => {
  //   toast.error('Error Occured', {
  //     position: 'top-right',
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: 'light',
  //   });
  // };
  // const errors =()=> {
  //   toast.error('Error Occured', {
  //     position: "top-right",
  //     autoClose: 5000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     progress: undefined,
  //     theme: "light",
  //     });
  // }
  useEffect( () => {
    // const access_token = localStorage.getItem('token')
    // setAccessToken(access_token)
    setTwitter(localStorage.getItem("twitter"))
    setFacebook(localStorage.getItem("facebook"))
      
  }, [])

  

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("twitter", twitter)
    setTwitter(localStorage.getItem("twitter"))
    localStorage.setItem("facebook", facebook)
    setFacebook(localStorage.getItem("facebook"))
     success()
     setEditMode(false)

  };

  // const data ={
  //   facebook: facebook,
  //   twitter: twitter
  // }
  //   try {

  //     const res=  axios.patch(url, data,{
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     } );

  //     console.log(res.data);
  //     alert(res.data[0])
  //     setTwitter('')
  //     setFacebook('')
  //     } catch (error) {
  //       setTwitter('')
  //       setFacebook('')
  //       alert('an error occured retry')
  //       console.log(error.response);
  //     }
  //   };

  //   useRef(()=>{
  //     const userInfo = localStorage.getItem('user')
  //     console.log(userInfo)
  //  }, [])

  return (
    <div>
      <div className="flex items-center gap-4 mb-6">
        <h2 className="text-xl">Linked Accounts</h2>
        {!editMode && (
          <img
            src="/edit-alt.svg"
            alt="edit"
            onClick={() => setEditMode(true)}
          />
        )}
      </div>

      <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16">
          <div>
            <label htmlFor="" className="securitylabel">
              Twitter
            </label>
            <input
              name="twitter"
              className="securityname block pl-2"
              placeholder="Twitter"
              value={twitter}
              onChange={(e) => setTwitter(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="" className="securitylabel">
              Facebook
            </label>
            <input
              name="facebook"
              className="securityname block pl-2"
              placeholder="Facebook"
              value={facebook}
              onChange={(e) => setFacebook(e.target.value)}
            />
          </div>
        </div>

        {editMode && (
          <div className="flex items-center gap-4">
            <button
              type="submit"
              className="bg-main-1 hover:bg-main-2 transition-all px-8 h-[45px] rounded-md text-white"
            >
              Save changes
            </button>
            <button
              type="button"
              className="px-8 h-[45px] rounded-md"
              style={{
                border: '0.3px solid #d0d5dd',
              }}
              onClick={() => setEditMode(false)}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
      <ToastContainer />
    </div>
  );
}
